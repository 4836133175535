<div class="ta-r">
  <span
    class="material-icons fullscreen"
    (click)="openChart()"
    style="cursor: pointer"
    title="Vollbildmodus umschalten"></span>
  <ng-container [ngTemplateOutlet]="schedulerDiagram" [ngTemplateOutletContext]="{ data: card }"></ng-container>
</div>

<ng-template #schedulerDiagram let-data="data">
  <dx-scheduler
    *ngIf="data"
    #scheduler
    id="scheduler"
    [(currentView)]="data.schedulerSettings.currentView"
    (onAppointmentFormOpening)="onAppointmentFormOpening($event)"
    [height]="90 + '%'"
    [dataSource]="dataSource"
    [views]="data.schedulerSettings.views"
    [(currentDate)]="currentDate"
    [showAllDayPanel]="true"
    descriptionExpr="description"
    [showCurrentTimeIndicator]="false"
    [shadeUntilCurrentTime]="true"
    appointmentTooltipTemplate="appointmentTooltipTemplate"
    (onAppointmentDblClick)="openAppointment($event)">
    <dxo-editing
      [allowAdding]="false"
      [allowUpdating]="false"
      [allowDeleting]="false"
      [allowResizing]="false"
      [allowDragging]="false">
    </dxo-editing>
    <dxo-scrolling mode="standard"> </dxo-scrolling>
    <dxi-resource fieldExpr="group" label="group" [dataSource]="schedulerResource"></dxi-resource>
    <ng-container *dxTemplate="let data of 'appointmentTooltipTemplate'">
      {{ data | console }}
      <div class="dx-tooltip-appointment-item">
        <div class="dx-tooltip-appointment-item-marker">
          <div
            class="dx-tooltip-appointment-item-marker-body"
            [style.background-color]="data.appointmentData.color"></div>
        </div>

        <div class="dx-tooltip-appointment-item-content">
          <div class="dx-tooltip-appointment-item-content">
            <div class="dx-tooltip-appointment-item-content-subject">
              {{ data.appointmentData.text }}
            </div>
            <div
              *ngIf="data.appointmentData.description"
              class="dx-tooltip-appointment-item-content-details"
              [innerHtml]="data.appointmentData.description"></div>
            <div class="dx-tooltip-appointment-item-content-date">
              {{ data.appointmentData.startDate | date : "d. MMMM yyyy 'um' HH:mm 'Uhr'" }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </dx-scheduler>
</ng-template>
<dx-popup
  id="popupScheduler"
  #popupScheduler
  width="70vw"
  height="90vh"
  shadingColor="rgba(0, 0, 50, 0.5)"
  [hidden]="!showInfoPopup"
  [(visible)]="showInfoPopup"
  [fullScreen]="false"
  [showTitle]="false"
  [showCloseButton]="false"
  [dragEnabled]="true"
  [hideOnOutsideClick]="true"
  [shading]="true"
  [toolbarItems]="infoPopupToolbarItems"
  (onHidden)="closeInfoPopup()">
  <div *dxTemplate="let data of 'content'">
    <ng-container [ngTemplateOutlet]="schedulerDiagram" [ngTemplateOutletContext]="{ data: cardPopup }"></ng-container>
  </div>
</dx-popup>
