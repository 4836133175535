import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';
import { IWorkflow } from '@interfaces/siam';
import * as Factory from '../factories/workflow.factory';
import { LoggerService } from './logger.service';

interface WfsGraphqlData {
  workflows: IWorkflow[];
}

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  private apiBaseUrl: string;

  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private logger: LoggerService,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    this.apiBaseUrl = `${this.baseUrl}api/workflows`;
    this.logger.info('workflow Service started');
  }

  /**
   * Get WorkFlow from api
   */
  getWorkFlows(): Observable<IWorkflow[]> {
    const query = gql`
      {
        workflows {
          id
          name
          description
          effectivePermissions
          version
          autoUpdate
        }
      }
    `;

    return this.apollo
      .query<WfsGraphqlData>({
        query
      })
      .pipe(map(({ data }) => data.workflows.map(workflow => Factory.copy(workflow))));
  }
  /**
   * Get WorkFlow from api
   */
  getWorkFlowsVertices(): Observable<IWorkflow[]> {
    const query = gql`
      {
        workflows {
          id
          vertices {
            name
            label
            clientConfiguration
          }
        }
      }
    `;

    return this.apollo
      .query<WfsGraphqlData>({
        query
      })
      .pipe(map(({ data }) => data.workflows.map(workflow => Factory.copy(workflow))));
  }

  getWorkFlow(guid: string): Observable<IWorkflow> {
    return this.http.get<IWorkflow>(`${this.apiBaseUrl}/${guid}`).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Erhalten des Workflows: {@error}', error);
        }
      }),
      map(workflow => {
        this.logger.info('Get Single Workflow from service = {@template}', workflow);
        return Factory.copy(workflow);
      })
    );
  }

  save(wf: IWorkflow): Observable<IWorkflow> {
    return this.http.post<IWorkflow>(`${this.apiBaseUrl}/`, wf).pipe(
      tap({
        next: workflow => {
          this.logger.debug('Workflow Speichern = {@wf}', workflow.id);
        },
        error: error => {
          this.logger.error('Fehler beim Speichern des Workflows: {@error}', error);
        }
      })
    );
  }

  delete(guid: string): Observable<IWorkflow> {
    return this.http.delete<IWorkflow>(`${this.apiBaseUrl}/${guid}`).pipe(
      tap({
        next: info => {
          this.logger.debug('Workflow Delete info from service = {@info}', info);
        },
        error: error => {
          this.logger.error('Fehler beim Löschen des Workflows: {@error}', error);
        }
      })
    );
  }
}
