import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { LoggerService } from './logger.service';
import { IDecisionStamp } from '@interfaces/default-decision';

@Injectable({
  providedIn: 'root'
})
export class DecisionStampService {
  private readonly apiBaseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string, private logger: LoggerService) {
    this.apiBaseUrl = `${this.baseUrl}api/decision-stamp`;
    this.logger.info('DecisionStamp Service started');
  }

  /**
   * Get DecisionStamps from api
   */
  getDecisionStamps(): Observable<IDecisionStamp[]> {
    return this.http.get<IDecisionStamp[]>(`${this.apiBaseUrl}/`).pipe(
      tap({
        error: error => {
          this.logger.error('Get list of DecisionStamps: {@error}', error);
        }
      })
    );
  }

  /**
   * Get DecisionStamp from api
   */
  getDecisionStamp(id: string): Observable<IDecisionStamp> {
    return this.http.get<IDecisionStamp>(`${this.apiBaseUrl}/${id}`).pipe(
      tap({
        error: error => {
          this.logger.error('Get list of DecisionStamps: {@error}', error);
        }
      })
    );
  }

  /**
   * creating a new DecisionStamp
   *
   * @param decisionStamp type IDecisionStamp
   */
  create(decisionStamp: IDecisionStamp): Observable<IDecisionStamp> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });

    return this.http.post<IDecisionStamp>(`${this.apiBaseUrl}`, decisionStamp, { headers }).pipe(
      tap({
        next: () => {
          this.logger.info('Vertraulich Aktualisieren: {@decisionStamp}', decisionStamp);
        },
        error: error => {
          this.logger.error('Fehler beim Erstellen des DecisionStamp: {@error}', error);
        }
      })
    );
  }

  /**
   * update a DecisionStamp with id
   *
   * @param decisionStamp type IDecisionStamp
   */
  update(decisionStamp: IDecisionStamp): Observable<void> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });

    return this.http.put<void>(`${this.apiBaseUrl}`, decisionStamp, { headers }).pipe(
      tap({
        next: () => {
          this.logger.info('Vertraulich Aktualisieren= {@DecisionStamp}', decisionStamp);
        },
        error: error => {
          this.logger.error('Fehler beim Aktualisieren des Vertrauliches: {@error}', error);
        }
      })
    );
  }

  /**
   * deleting the DecisionStamp from DB
   *
   * @param decisionStamp
   */
  remove(decisionStamp: IDecisionStamp): Observable<IDecisionStamp> {
    return this.http.delete<IDecisionStamp>(`${this.apiBaseUrl}/${decisionStamp.id}`).pipe(
      tap({
        next: deletedDecisionStamp => {
          this.logger.info('Vertraulich Löschen= {@DecisionStamp}', deletedDecisionStamp);
        },
        error: error => {
          this.logger.error('Fehler beim Löschen des Vertrauliches: {@error}', error);
        }
      })
    );
  }
}
