import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ILoadPanelSettings } from '@interfaces/siam';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class LoadPanelService {
  loadingPanel = new BehaviorSubject<ILoadPanelSettings>(null);
  loadPanelState$ = this.loadingPanel.asObservable();
  private loadingQueue: ILoadPanelSettings[] = []; // FIFO queue for loading requests

  hide(): void {
    this.loadingQueue = [];
    this.forceHide();
  }
  show(message?: string, height?: number | string, width?: number | string): void {
    let $message = 'Laden...';
    let $height: number | string = 90;
    let $width: number | string = 220;
    if (message) {
      $message = message;
    }
    if (height) {
      $height = height;
    }
    if (width) {
      $width = width;
    }
    const config = { message: $message, height: $height, width: $width, loading: true };
    this.updateLoadingPanelState(config);
  }

  hidePanel(id: string): void {
    const index = this.loadingQueue.findIndex(item => item.id === id);

    if (index !== -1) {
      this.loadingQueue.splice(index, 1);
    }

    if (this.loadingQueue.length > 0) {
      this.updateLoadingPanelState(this.loadingQueue[0]);
    } else {
      this.forceHide();
    }
  }

  /* The `showPanel` method in the `LoadPanelService` class is used to display a loading panel with a
  specific message, height, and width. It adds a new loading configuration object to the
  `loadingQueue` array, which acts as a FIFO queue for loading requests. If the queue was empty
  before adding the new configuration, it immediately updates the loading panel state with the new
  configuration. */
  showPanel(id: string, message = 'Laden...', height: number | string = 90, width: number | string = 220): void {
    const config = { id, message, height, width, loading: true };
    this.loadingQueue.push(config);

    if (this.loadingQueue.length === 1) {
      this.updateLoadingPanelState(config);
    }
  }

  generateUuid(): string {
    return uuid.v4();
  }
  /* The `updateLoadingPanelState` method in the `LoadPanelService` class is responsible for
  updating the state of the loading panel by emitting a new value through the `loadingPanel`
  BehaviorSubject. It takes a configuration object as a parameter, extracts specific
  properties from that object (such as id, message, height, width), and then emits a new
  value with those properties to notify any subscribers of the changes in the loading panel
  state. */
  private updateLoadingPanelState(config: ILoadPanelSettings): void {
    this.loadingPanel.next({
      loading: true,
      id: config.id,
      message: config.message,
      height: config.height,
      width: config.width
    });
  }

  forceHide(): void {
    this.loadingQueue = [];
    this.loadingPanel.next({ loading: false });
  }
}
