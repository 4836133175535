import { SiamList } from '@interfaces/siamList';
import { ListState } from '../entities/list.entity';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

export const getListState = createFeatureSelector<ListState>('listState');

export const getListByName = (name: string): MemoizedSelector<object, SiamList> =>
  createSelector(getListState, state => state.entities[name]);
export const getListById = (id: string): MemoizedSelector<object, SiamList> =>
  createSelector(getListState, state => state.entities[id]);
