import { ActivatedRouteSnapshot, CanActivateFn, CanDeactivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { DocumentBaseComponent } from '../application/components/forms/document-base/document-base.component';
import { lastValueFrom, Subject } from 'rxjs';
import { ICustomDialog } from '@interfaces/siam';
import { closeSaveDialog } from '@factories/helpers';
import { TemplateService } from '@services/template.service';
import { map } from 'rxjs/operators';
import { inject } from '@angular/core';
import { LoginService } from '@services/login.service';
import { getCurrentUser } from '@factories/user.factory';
import { SharedataService } from '@services/sharedata.service';
import { UserService } from '@services/user.service';
import { RoleService } from '@services/roles.service';

export function canDeactivateDocumentGuard(): CanDeactivateFn<DocumentBaseComponent> {
  return (component) => {
    if (!component?.currentDocument) {
      return Promise.resolve(true);
    }

    const closeSubject$ = new Subject<boolean>();

    const create = (): ICustomDialog<boolean> => {
      return closeSaveDialog(
        'Dokument schließen',
        'Es gibt ungespeicherte Änderungen auf dem Dokument. Was möchten Sie tun?',
        closeSubject$,
        (subject$: Subject<boolean>) => {
          void lastValueFrom(component.saveChanges(subject$));
        });
    }

    return lastValueFrom(component.isDocumentLayoutHasChanges()).then(
      hasChanges => {
        if (hasChanges) {
          const dialog = create();
          dialog.show();
          return lastValueFrom(closeSubject$);
        }
        return true;
      },
      () => {
        const dialog = create();
        dialog.show();
        return lastValueFrom(closeSubject$);
      }
    );
  };
}

export function executeTemplateGuard(): CanActivateFn {
  return (snapshot: ActivatedRouteSnapshot) => {
    const templateService: TemplateService = inject(TemplateService);
    return templateService.templatePermissions(snapshot.params.templateid as string)
      .pipe(map(permissions => !!permissions.find(x => x === 'execute')));
  };
}

export function loginRouteGuard(): CanActivateFn {

  return async (_: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const loginService: LoginService = inject(LoginService);
    const router: Router = inject(Router);
    const url = state.url;

    const user = getCurrentUser();
    if (user) {
      const isPersistent = await lastValueFrom(loginService.info()).then(
        () => true,
        () => false
      );

      if (isPersistent) {
        return true;
      }
    }

    // Store the attempted URL for redirecting
    loginService.redirectUrl = url;

    // Navigate to the login page with extras
    await router.navigate(['/start']);
    return false;
  };
}

export function isPersistanceGuard(): CanActivateFn {
  return async (snapshot: ActivatedRouteSnapshot) => {
    const loginService: LoginService = inject(LoginService);
    const router: Router = inject(Router);
    const sharedData: SharedataService = inject(SharedataService);
    const userService: UserService = inject(UserService);
    const roleService: RoleService = inject(RoleService);

    const checkPersistent = (): Promise<boolean> => {
      return lastValueFrom(loginService.info(false)).then(
        () => true,
        () => false
      );
    }

    let isPersistent = await checkPersistent();
    if (snapshot.queryParamMap.get('logout') === 'true' && isPersistent) {
      await lastValueFrom(loginService.logOut());
      userService.onLogout();
      roleService.onLogout();
      sharedData.setUser(null);
      sharedData.resetStartedOnce();
    }

    isPersistent = await checkPersistent();
    if (isPersistent) {
      await router.navigate(['/application/home']);
      return false;
    }

    return true;
  };
}
