import { Inject, Injectable } from '@angular/core';
import { concatWith, map, mergeMap, reduce, share, switchMap, tap } from 'rxjs/operators';
import { from, lastValueFrom, Observable, of, zip } from 'rxjs';
import { TagsService } from '@services/tags.service';
import { ITemplateServer } from '@interfaces/ITemplateServer';
import { FieldServer } from '@interfaces/fieldServer';
import { ListsService } from './lists.service';
import { siamConst } from '@interfaces/siamConst';
import {
  IAttachment,
  IBrainloopLogin,
  IDocument,
  IDocumentBreadcrumb,
  IDocumentDecision,
  IDocumentField,
  IDocumentFields,
  IDocumentHierarchy,
  IDocumentsSearch,
  ILabel,
  IMultiSaveDocument,
  IPermissionTarget,
  IReference,
  IResponseMultiSaveDocument,
  IRole,
  ISearch,
  ISearchGroupResponse,
  ISearchGroups,
  ISearchResponse,
  IUploadAttachment,
  IWorkflowAction,
  IWorkflowActionSheetItem,
  IWorkflowDocument,
  TEffectivePermission,
  TPermission,
  TTag
} from '@interfaces/siam';
import * as Factory from '@factories/document.factory';
import { DocumentApi } from '../api/document.api';
import { HttpResponse } from '@angular/common/http';
import { IDxResolvedGroupData } from '@interfaces/devextreme';
import { clone, isHasProperty } from '@factories/helpers';
import { DateTimeFormatOptions } from 'luxon';
import { _defaultDecisionColor, _defaultDecisionId, IDecisionStamp } from '@interfaces/default-decision';
import { TemplateService } from './template.service';
import { NotifyService } from './notify.service';
import { FieldDecisionsValue, FieldDecisionValue } from '@interfaces/fieldClient';
import { SiamList } from '@interfaces/siamList';
import { TDecisionActionType } from '@interfaces/edgeServer';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private createDocumentByTemplateIdDecisionCache = new Map<string, IDocument>();
  constructor(
    private tagsService: TagsService,
    private listsService: ListsService,
    private templateService: TemplateService,
    private documentApi: DocumentApi,
    @Inject('BASE_URL') private baseUrl: string
  ) {}

  delete(document: IDocument, recursive?: boolean, force?: boolean, maxDepth?: number): Observable<void> {
    return this.documentApi.delete(document, recursive, force, maxDepth);
  }

  park(document: IDocument, recursive?: boolean, force?: boolean, maxDepth?: number): Observable<void> {
    return this.createDocumentByTemplateId(document.template).pipe(
      tap(doc => from(this.createFromParent(doc, document))),
      switchMap(doc => this.save(doc, null, false)),
      switchMap(() => this.delete(document, recursive, force, maxDepth))
    );
  }

  getBallotDocuments(document: IDocument): Observable<IWorkflowDocument[]> {
    return this.documentApi.getWorkflowHistory(document).pipe(
      map(data => data.workflowDocuments),
      map(workflows => workflows.filter(x => x.tags.find(tag => tag === 'app:document-type:ballot')))
    );
  }

  createDocumentByTemplateId(template: ITemplateServer): Observable<IDocument> {
    return this.documentApi.createDocumentByTemplateId(template.id).pipe(
      map(document => {
        if (document) {
          document.template = template;
          return document;
        }
        return null;
      })
    );
  }

  getDocumentsByTags(tags?: TTag[], fieldNames?: string[]): Observable<IDocument[]> {
    return this.documentApi
      .getDocumentsByTags(tags, fieldNames)
      .pipe(map(documents => documents.map(document => Factory.copy(document))));
  }

  getDocumentsByIds(ids?: string[], fields?: string[]): Observable<IDocument[]> {
    if (!ids.length) {
      return new Observable<IDocument[]>(observer => {
        observer.next([]);
        observer.complete();
      });
    }
    return this.documentApi
      .getDocumentsByIds(ids, fields)
      .pipe(map(documents => documents.map(document => Factory.copy(document))));
  }

  /**
   * Get a document with the document GUID
   *
   * @param documentId
   */
  getDocumentById(documentId: string): Observable<IDocument> {
    return this.documentApi.getDocumentById(documentId).pipe(map(document => Factory.copy(document)));
  }

  /**
   * Get a document workflows edges with the document GUID
   *
   * @param documentId
   * @param workflowId
   * @param body
   */
  getWorkflowEdges(
    documentId: string,
    workflowId: string,
    body?: Record<string, unknown>
  ): Observable<IWorkflowAction[]> {
    return this.documentApi.getDocumentWorkflowEdges(documentId, workflowId, body).pipe(
      map(actions => {
        const newActions: IWorkflowAction[] = [];
        for (const element of actions) {
          const include = element?.userInputTemplate?.fields[0].include;
          if (include) {
            for (let index2 = 0; index2 < include.length; index2++) {
              const element2 = include[index2];
              if (
                !isHasProperty(element2, 'targetId') &&
                isHasProperty(element2, 'type') &&
                isHasProperty(element2, 'role')
              ) {
                include[index2] = {
                  targetId: (element2 as { role: IRole }).role.id,
                  type: (element2 as IPermissionTarget).type
                };
              }
            }
          }
          newActions.push(element);
        }
        return newActions;
      })
    );
  }

  getWorkflowHistory(document: IDocument): Observable<IDocument> {
    return this.documentApi.getWorkflowHistory(document);
  }

  getDocumentPermissions(document: IDocument): Observable<IDocument> {
    return this.documentApi.getDocumentPermissions(document);
  }

  getDocumentTemplatePermissions(document: IDocument): Observable<TPermission[]> {
    return this.documentApi.getDocumentTemplatePermissions(document).pipe(map(d => d.template.documentPermissions));
  }

  getReferencedDocuments(document: IDocument, referenceTag?: TTag): Observable<IDocument[]> {
    if (document.references && document.references.length > 0) {
      let filteredDocuments = document.references;
      if (referenceTag) {
        filteredDocuments = document.references.filter(refDoc => refDoc.tags.some(tag => tag === referenceTag));
      }

      // unterscheidung zwischen RefDocs mit id und RefDocs ohne id (neue, noch nicht gespeicherte Dokumente)
      const documentIds = filteredDocuments
        .filter(rf => (rf.document && rf.document.id) || rf.referencedDocumentId)
        .map(rf => (rf.document ? rf.document.id : rf.referencedDocumentId));

      const documentsWithoutIds = document.references
        .filter(rf => !rf.referencedDocumentId && !rf.document)
        .map(rf => rf.document);

      const newDocuments$ = of(documentsWithoutIds);

      if (documentIds.length !== 0) {
        return this.getDocumentsByIds(documentIds).pipe(
          // beide Observable[] (newDocument$ und Ergebnis des Calls) zusammenführen und als ein Observable[] zurückliefern
          // tslint:disable-next-line: deprecation
          concatWith(newDocuments$),
          reduce((pv, item) => pv.concat(item), [] as IDocument[])
        );
      }
      return newDocuments$;
    }
    return of([] as IDocument[]);
  }

  getParents(document: IDocument): Observable<IDocument[]> {
    return this.getReferencedDocuments(document, siamConst.parentTag);
  }

  /**
   * Trigger the workflow and having a new document status with workflow actions
   *
   * @param action
   * @param variables
   */
  executeWorkflowAction(action: IWorkflowAction, variables: Record<string, unknown> = null): Observable<IDocument> {
    return this.documentApi
      .executeWorkflowAction(action.documentId, action.workflowId, action.name, variables)
      .pipe(switchMap(() => this.getDocumentById(action.documentId)));
  }

  /**
   * Trigger the workflow and having a new document status with workflow actions
   *
   * @param action
   * @param variables
   */
  executeWorkflow(action: IWorkflowAction, variables: Record<string, unknown> = null): Observable<IDocument> {
    return this.documentApi.executeWorkflowAction(action.documentId, action.workflowId, action.name, variables);
  }

  /**
   * Erstellung eines neuen Dokument-Objekts wie es vom Server erwartet wird.
   *
   * @param template Das optionale Template anhand dessen ein leeres Dokument erstellt wird.
   * @returns Ein neues Dokument, das nur die im Template definierten Felder enthält.
   */
  create(template?: ITemplateServer): IDocument {
    return {
      // FIXME: KANN ENTFERNT WERDEN ???
      template,
      templateId: template?.id,
      fields: this.createDefaultDocumentFields(template?.fields),
      tags: template?.tags
    } as IDocument;
  }

  /**
   * Erstellung eines neuen Dokument-Objekts wie es vom Server erwartet wird.
   *
   * @param template Das optionale Template anhand dessen ein leeres Dokument erstellt wird.
   * @param fields
   *
   * @return Ein neues Dokument, das nur die im Template definierten Felder enthält.
   */
  createSubForm(template: ITemplateServer, fields?: IDocumentFields): IDocument {
    return {
      // FIXME: KANN ENTFERNT WERDEN ???
      template,
      templateId: template.id,
      fields: fields || this.createDefaultDocumentFields(template.fields),
      tags: template.tags
    } as IDocument;
  }

  async createFromParent(document?: IDocument, parentDoc?: IDocument): Promise<IDocument> {
    if (!document && !parentDoc) {
      throw new TypeError(`Dokument kopieren ist nich möglich`);
    }
    const fieldAttachmentIds: Record<string, string[]> = {};
    Object.keys(document.fields).map(key => {
      const fieldChildType = document.fields[key]?.valueType || document.fields[key]?.type;
      const fieldParentType = parentDoc.fields[key]?.valueType || parentDoc.fields[key]?.type;
      if (parentDoc.fields[key] && document.fields[key]?.effectivePermissions?.includes('update')) {
        if (fieldChildType !== fieldParentType) {
          if (fieldParentType === 'empty') {
            return;
          } else if (fieldParentType === 'text' && fieldChildType === 'html') {
            document.fields[key].value = parentDoc.fields[key].value as unknown;
          } else {
            throw new TypeError(
              `Konfigurationsfehler: Feldtyp für ${key} nicht gleich: ${fieldChildType as string} und ${
                fieldParentType as string
              }`
            );
          }
        }
        switch (fieldChildType) {
          case 'attachments':
            {
              if ((parentDoc.fields[key].value as IAttachment[])?.length) {
                const idsAttachmentFromParent = (parentDoc.fields[key].value as IAttachment[]).map(v => v.id);
                if (idsAttachmentFromParent?.length) {
                  fieldAttachmentIds[key] = idsAttachmentFromParent;
                }
              }
            }
            break;
          case 'html':
            {
              if ((parentDoc.fields[key].value as string)?.length) {
                const idsHtmlFromParent = this.extractIdsFromHtml(parentDoc.fields[key].value as string);
                if (idsHtmlFromParent?.length) {
                  fieldAttachmentIds[key] = idsHtmlFromParent;
                } else {
                  document.fields[key].value = parentDoc.fields[key].value as unknown;
                }
              }
            }
            break;

          default:
            {
              document.fields[key].value = parentDoc.fields[key].value as unknown;
            }
            break;
        }
      }
    });
    const attachmentIds =
      Object.entries(fieldAttachmentIds)
        .map(([, v]) => v)
        .flat() || [];
    if (attachmentIds.length) {
      const cloneResult = await lastValueFrom(this.cloneAttachments(attachmentIds));
      Object.entries(fieldAttachmentIds).forEach(([fieldName, fieldIds]) => {
        const fieldType = document.fields[fieldName].valueType || document.fields[fieldName].type;
        switch (fieldType) {
          case 'attachments':
            {
              document.fields[fieldName].value = fieldIds.map(id => cloneResult[id]);
            }
            break;
          case 'html':
            {
              let parentValue = parentDoc.fields[fieldName].value as string;
              fieldIds.forEach(id => {
                parentValue = parentValue.replaceAll(id, cloneResult[id]?.id);
              });
              document.fields[fieldName].value = parentValue;
            }
            break;
        }
      });
    }
    // only für TOPs
    Object.keys(parentDoc.fields).map(key => {
      if (
        [
          siamConst.topStartTime,
          siamConst.topEndTime,
          siamConst.topPosition,
          siamConst.topSpeakers,
          siamConst.topType,
          siamConst.topDuration,
          siamConst.topActive,
          siamConst.agendaAllSpeakers
        ].includes(key) &&
        !isHasProperty(document.fields, key)
      ) {
        const fieldParentType = parentDoc.fields[key]?.valueType || parentDoc.fields[key]?.type;
        const fieldParentValue = parentDoc.fields[key]?.value as unknown;
        document.fields[key] = { value: fieldParentValue, valueType: fieldParentType };
      }
    });

    return document;
  }

  extractIdsFromHtml(value: string): string[] {
    const regExp = /api\/attachment\/(.*?)"/gim;
    const array = [...value.matchAll(regExp)];
    return array?.map(m => m[1]) || [];
  }

  checkMatchingFields(document?: IDocument, parentDoc?: IDocument): boolean {
    let result = false;
    Object.keys(document.fields).map(key => {
      if (parentDoc.fields[key]) {
        result = true;
      }
    });
    return result;
  }

  copyToCreate(document?: IDocument): IDocument {
    return {
      // FIXME: KANN ENTFERNT WERDEN ???
      template: document.template,
      templateId: document && document.template && document.template.id,
      fields: document.fields,
      tags: document?.template?.tags
    } as IDocument;
  }

  /**
   * saveDocument
   * Save Document in DB
   *
   * @param document
   * @param parentDocumentId
   * @param loadDocument
   */
  save(document: IDocument, parentDocumentId?: string, loadDocument?: boolean): Observable<IDocument> {
    return this.documentApi
      .save(document, parentDocumentId, loadDocument)
      .pipe(map(savedDocument => Factory.copy(savedDocument)));
  }

  multiSave(documents: IMultiSaveDocument[]): Observable<IResponseMultiSaveDocument[]> {
    return this.documentApi.multiSave(documents);
  }

  searchDocuments(search: ISearch): Observable<ISearchResponse> {
    return this.documentApi.documentSearch(search);
  }

  getCounterDocuments(properties: IDocumentsSearch): Observable<number> {
    return this.documentApi.documentsSearch(properties).pipe(map(data => data?.totalCount));
  }

  searchDocumentsGrouped(search: ISearchGroups): Observable<ISearchGroupResponse> {
    return this.documentApi.searchDocumentsGrouped(search);
  }

  documentsSearch(properties: IDocumentsSearch): Observable<ISearchResponse> {
    return this.documentApi.documentsSearch(properties);
  }

  documentsSearchGroup(properties: IDocumentsSearch): Observable<IDxResolvedGroupData> {
    return this.documentApi.documentsSearchGroup(properties);
  }

  /**
   * "print" the document
   *
   * @param document
   */
  print(document: IDocument): Observable<HttpResponse<Blob>> {
    return this.documentApi.print(document);
  }

  upload(documentId: string, login: IBrainloopLogin, withReferencedDocuments = true): Observable<void> {
    return this.documentApi.upload(documentId, login, withReferencedDocuments);
  }

  setReferences(items: { parentId: string; documentId: string }[]): Observable<unknown> {
    return this.documentApi.setReferences(items);
  }

  removeReferences(items: { parentId: string; documentId: string }[]): Observable<unknown> {
    return this.documentApi.removeReferences(items);
  }

  /**
   * set the document with parentId as the parent document of documentId
   *
   * @param documentId
   * @param parentId
   */
  setReference(documentId: string, parentId: string): Observable<IDocument> {
    return this.documentApi.setReference(documentId, parentId).pipe(switchMap(() => this.getDocumentById(documentId)));
  }

  /**
   * Entfernen einer Referenzbeziehung zwischen zwei Dokumenten
   *
   * @param documentId
   * @param targetId
   */
  removeReference(documentId: string, targetId: string): Observable<IDocument> {
    return this.documentApi.removeReference(documentId, targetId).pipe(switchMap(() => this.getDocumentById(targetId)));
  }

  getPermissions(documentId: string): Observable<TEffectivePermission[]> {
    return this.documentApi.getPermissions(documentId);
  }

  uploadAttachment(data: IUploadAttachment): Observable<IAttachment> {
    return this.documentApi.uploadAttachment(data);
  }

  cloneAttachments(ids: string[]): Observable<Record<string, IAttachment>> {
    return this.documentApi.cloneAttachments(ids);
  }

  getAttachment(attachmentId: string): Observable<BlobPart> {
    return this.documentApi.getAttachment(attachmentId);
  }

  getAttachmentFromField(documentId: string, attachmentId: string): Observable<BlobPart> {
    return this.documentApi.getAttachmentFromDocument(documentId, attachmentId);
  }

  getSelectableNamesFromField(documentId: string, fieldName: string): Observable<IPermissionTarget[]> {
    return this.documentApi.getSelectableNamesFromField(documentId, fieldName);
  }

  getDocumentHierarchy(
    documentId: string,
    fields: string[] = ['subject', 'startdate']
  ): Observable<IDocumentBreadcrumb[]> {
    return this.documentApi
      .getDocumentHierarchy(documentId, fields)
      .pipe(map(result => this.createDocumentBreadcrumb(result)));
  }

  createDocumentBreadcrumb(document: IDocumentHierarchy): IDocumentBreadcrumb[] {
    const breadcrumbs: IDocumentBreadcrumb[] = [];

    const formatDate = (dateString: string): string => {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' } as DateTimeFormatOptions;
      const date = new Date(dateString);
      return date.toLocaleDateString('de-DE', options);
    };
    const traverse = (doc: IDocumentHierarchy, childBreadcrumb: IDocumentBreadcrumb = null): void => {
      const subject = doc.documentFields.subject || null;
      const startdate = doc.documentFields.startdate || null;
      const templateCaption = doc.templateInformation.templateCaption || null;
      const childDocument: IDocument = { id: doc.documentId, tags: doc.documentTags } as unknown as IDocument;
      const isAgenda = this.isAgenda(childDocument);

      let text = subject || '';
      if (templateCaption) {
        text += ` - ${templateCaption}`;
      }
      if (startdate && isAgenda) {
        text += ` (${formatDate(startdate)})`;
      }

      const breadcrumb: IDocumentBreadcrumb = {
        documentId: doc.documentId,
        document: childDocument,
        text,
        isCurrent: doc.documentId === document.documentId,
        child: childBreadcrumb
      };
      if (!doc.parentDocuments?.length) {
        breadcrumbs.push(breadcrumb);
      }

      // If the document has parentDocuments, recursively call the traverse function on the first one (if it exists)
      if (doc.parentDocuments && doc.parentDocuments.length > 0) {
        for (const parent of doc.parentDocuments) {
          traverse(parent, breadcrumb);
        }
      }
    };

    traverse(document);

    const getDepth = (item: IDocumentBreadcrumb): number => {
      let depth = 0;
      while (item && item.child) {
        depth++;
        item = item.child;
      }
      return depth;
    };

    const filterLongestPaths = (arr: IDocumentBreadcrumb[]): IDocumentBreadcrumb[] => {
      const map: Record<string, IDocumentBreadcrumb> = {};

      // Group by documentId, keeping only the one with the longest path
      arr.forEach(item => {
        const docId = item.documentId;
        const depth = getDepth(item);

        // Remove Current Item from map
        if (item.isCurrent) {
          return;
        }
        // If the documentId is not in the map yet, or if the current item has a longer path
        if (!map[docId] || getDepth(map[docId]) < depth) {
          map[docId] = item;
        }
      });

      // Return the filtered list (only items with the longest path for each documentId)
      return Object.values(map);
    };
    // Return the breadcrumbs to match the correct hierarchical structure
    return filterLongestPaths(breadcrumbs);
  }

  getLabelTags(document: IDocument): string[] {
    return document.tags.filter(tag =>
      tag.toLowerCase().startsWith(`app:document-label:${siamConst.globalLabelsListName}:`.toLowerCase())
    );
  }

  getLabels(document: IDocument): Observable<ILabel[]> {
    const labelTags = this.getLabelTags(document);
    return this.listsService
      .getGlobalLabels()
      .pipe(
        map(globalLabels =>
          globalLabels.filter(
            globalLabel =>
              labelTags.findIndex(labelTag => labelTag.toLowerCase() === globalLabel.value.toLowerCase()) > -1
          )
        )
      );
  }

  getTypeLabel(doc: IDocument): string {
    const tmpTag = doc.tags.find(tag => tag.startsWith('app:document-type'));
    return this.tagsService.getTypeLabel(tmpTag);
  }

  getTypeName(doc: IDocument): string {
    const tmpTag = doc.tags.find(tag => tag.startsWith('app:document-type'));
    return this.tagsService.getTypeName(tmpTag);
  }

  getType(document: IDocument | IWorkflowDocument): string {
    let type: string;
    if (document) {
      type = document.tags.find(tag => tag.startsWith('app:document-type'));
    }
    return type || '';
  }

  getDocumentURL(document: IDocument): string[] {
    const docType = this.getTypeName(document);
    return ['application', 'documents', docType, document.id];
  }
  /**
   * check if document has attachment
   *
   * @param document
   */
  hasAttachment(document: IDocument): boolean {
    if (!document?.fields) {
      return false;
    }
    return (
      Object.entries(document.fields)
        .filter(([, value]) => value.valueType === 'attachments' || value.type === 'attachments')
        .map(([, value]) => value.value as unknown)
        .filter(fieldValue => !!fieldValue && Array.isArray(fieldValue))
        .filter(fieldValue => (fieldValue as unknown[]).length > 0).length !== 0
    );
  }

  hasChildOfTag(doc: IDocument, tagname: string): boolean {
    if (!doc?.references) {
      return false;
    }
    return doc.references.some(reference => reference.tags.some(tag => tag === tagname) === true);
  }

  isDocumentReferenced(doc: IDocument, parent: IDocument): boolean {
    if (!doc?.references || !parent) {
      return false;
    }
    return doc.references.some(reference => reference.referencedDocumentId === parent.id);
  }

  isParentDocument(doc: IDocument, refDocument: IDocument): boolean {
    if (!doc?.references || !refDocument) {
      return false;
    }
    return doc.references.some(
      reference =>
        (reference.tags.some(tag => tag === this.getType(refDocument)) &&
          reference.tags.some(tag => tag === siamConst.parentTag)) === true
    );
  }

  isChildDocument(doc: IDocument, refDocumentId: string): boolean {
    if (!doc.references || !refDocumentId) {
      return false;
    }
    return doc.references.some(
      reference =>
        reference.referencedDocumentId === refDocumentId &&
        reference.tags.some(tag => tag === siamConst.childTag) === true
    );
  }

  countChildOfTag(doc: IDocument, tagname: string): number {
    if (!doc?.references) {
      return 0;
    }
    return doc.references.filter(reference => reference.tags.filter(tag => tag === tagname).length).length;
  }

  getRereferencesObject(doc: IDocument): Record<string, boolean> {
    if (!doc?.references) {
      return {};
    }
    const result: Record<string, boolean> = {};
    for (const reference of doc.references) {
      const tmpTag = reference.tags.find(tag => tag.startsWith('app:document-type'));
      const refType = this.tagsService.getTypeName(tmpTag);
      if (reference.tags.includes(siamConst.childTag) && refType) {
        result[`${siamConst.conditionChildExistsTag}:${refType}`] = true;
      }
      if (reference.tags.includes(siamConst.parentTag) && refType) {
        result[`${siamConst.conditionParentExistsTag}:${refType}`] = true;
      }
    }
    return result;
  }

  getChildDocumentWithTag(doc: IDocument, tagname: string): IReference {
    if (!doc?.references) {
      return null;
    }
    return doc.references.find(ref => ref.tags.some(tag => tag === tagname));
  }

  getChildDocumentsWithTag(doc: IDocument, tagname: string): IDocument[] {
    if (!doc?.references) {
      return null;
    }
    return doc.references.filter(ref => ref.tags.some(tag => tag === tagname)).map(ref => ref.document);
  }

  /**
   * returns the workflow edges for document with documentId
   *
   * @param document
   * @param body
   */
  getDocumentWorkFlow(
    document: IDocument,
    body?: Record<string, unknown>
  ): Observable<{ actions: IWorkflowAction[]; actionItems: IWorkflowActionSheetItem[] }> {
    // Workflows holen, die dem Vorgang zugeordnet wurden
    return this.documentApi.getWorkflows(document.id).pipe(
      // in Observable konvertieren, um RXJS nutzen zu können
      switchMap(workflowIds => from(workflowIds)),
      share(),
      // alle Aktionen (Edges) der zugeordneten Workflows laden
      mergeMap(workflowId =>
        this.getWorkflowEdges(document.id, workflowId, body).pipe(
          // in Observable konvertieren
          switchMap(workflowActions => from(workflowActions)),
          // in neue Liste laden (alle Aktionen mit der zugehörigen WorkflowId)
          map(workflowAction => ({ workflowId, action: workflowAction })),
          share()
        )
      ),
      // neues Array erstellen mit den Aktionen
      map(actionEntry => {
        const action = actionEntry.action;
        return {
          ...action,
          workflowId: actionEntry.workflowId,
          documentId: document.id
        };
      }),
      // zweites Array erstellen für die WF-Auswahlmöglichkeit in der GUI
      map((workflowAction: IWorkflowAction) => {
        const values = workflowAction.customValues || {};
        return {
          text: workflowAction.label,
          hint: workflowAction.description,
          type: values.type || 'normal',
          icon: values.icon,
          action: workflowAction
        };
      }),
      reduce(
        (pv, item) => {
          pv.actions.push(item.action);
          pv.actionItems.push(item);
          return pv;
        },
        {
          actions: [],
          actionItems: []
        } as { actions: IWorkflowAction[]; actionItems: IWorkflowActionSheetItem[] }
      ),
      share()
    );
  }

  getDocumentsWorkFlows(
    documents: IDocument[]
  ): Observable<Record<string, { actions: IWorkflowAction[]; actionItems: IWorkflowActionSheetItem[] }>> {
    return from(documents).pipe(
      mergeMap(document => this.getDocumentWorkFlow(document).pipe(map(data => Object.assign(data, { document })))),
      reduce((pv, item) => {
        pv[item.document.id] = { actions: item.actions, actionItems: item.actionItems };
        return pv;
      }, {} as Record<string, { actions: IWorkflowAction[]; actionItems: IWorkflowActionSheetItem[] }>)
    );
  }

  fetchWorkflowStatus(workflowDocuments: IWorkflowDocument[], arg?: string): string {
    let value: string;
    if (workflowDocuments) {
      const workflowDocument = workflowDocuments.find(
        x => !x.tags.length || x.tags.includes('app:document-type:document-workflow')
      );
      if (arg === 'label') {
        value = this.getWorkflowStateLabel(workflowDocument);
      } else {
        const status = this.getWorkflowStatus(workflowDocument);
        value = status && (status.value as string);
      }
    }
    return value || '';
  }

  /**
   * returns the label of the current workflow state
   */
  getWorkflowStateLabel(workflowDocument: IWorkflowDocument): string {
    if (workflowDocument) {
      const nameToLabel = workflowDocument.workflow.vertices.reduce((pv, item) => {
        pv[item.name] = item.label;
        return pv;
      }, {} as { [key: string]: string });

      const status = this.getWorkflowStatus(workflowDocument);
      return status && nameToLabel[status.value as string];
    }
    return '';
  }

  /**
   * returns the label of the current workflow state
   */
  getWorkflowStateColor(workflowDocument: IWorkflowDocument): string {
    if (workflowDocument) {
      const nameToColor = workflowDocument.workflow.vertices.reduce((pv, item) => {
        pv[item.name] = item.clientConfiguration?.color || 'default';
        return pv;
      }, {} as { [key: string]: string });

      const status = this.getWorkflowStatus(workflowDocument);
      return status && nameToColor[status.value as string];
    }
    return '';
  }

  /**
   * returns the decision info of the current documnet
   */
  getDecisionInfo(doc: IDocument): string {
    const fields = doc.fields;
    let decisionInfo = '';
    if (fields['--approval_date'] && fields['--approval_number']) {
      const decisionNumber =
        (fields['--approval_number']?.value as string) ||
        (fields['--approval_category']?.value as string) ||
        'Beschluss';
      decisionInfo = `${decisionNumber}`;
    }
    return decisionInfo;
  }

  removeCompositeId(source: IPermissionTarget | IPermissionTarget[]): IPermissionTarget | IPermissionTarget[] {
    if (source) {
      if (Array.isArray(source)) {
        if (!source.length) {
          source = [];
        } else {
          source.forEach(value => {
            delete value.compositeId;
          });
          source = source.map(s => (s?.targetId && s?.type ? { type: s.type, targetId: s.targetId } : s));
        }
      } else {
        delete source.compositeId;
        if (source?.targetId && source?.type) {
          source = { type: source.type, targetId: source.targetId };
        }
      }
    }
    return source;
  }

  isAttachmentChanged(current: IAttachment[], source: IAttachment[]): boolean {
    const isArrayCurrent = Array.isArray(current);
    const isArraySource = Array.isArray(source);

    if ((isArrayCurrent && !isArraySource) || (!isArrayCurrent && isArraySource)) {
      return true;
    }

    if (!isArrayCurrent && !isArraySource) {
      return false;
    }

    const lengthCurrent = current.length;
    const lengthSource = source.length;

    if (lengthCurrent !== lengthSource) {
      return true;
    }

    for (let i = 0; i < lengthCurrent; i++) {
      const iCurrent = current[i];
      const iSource = source[i];
      if (
        iCurrent.size !== iSource.size &&
        iCurrent.fileName !== iSource.fileName &&
        iCurrent.contentType !== iSource.contentType
      ) {
        return true;
      }
    }
    return false;
  }

  isPattern(document: IDocument): boolean {
    return this.hasTag(document, siamConst.patternTag);
  }
  isPatternTemplate(document: IDocument, templateId: string): boolean {
    return (
      this.hasTag(document, siamConst.patternTag) &&
      this.hasTag(document, `${siamConst.patternTemplateIdTag}:${templateId}`)
    );
  }

  isAgenda(document: IDocument): boolean {
    return this.hasTag(document, 'app:document-type:agenda');
  }

  isDecision(document: IDocument): boolean {
    return this.hasTag(document, 'app:document-type:decision');
  }

  isMeetingMinutes(document: IDocument): boolean {
    return this.hasTag(document, 'app:document-type:meetingminutes');
  }

  isPause(document: IDocument): boolean {
    return this.hasTag(document, 'app:document-type:pause');
  }

  isProtocol(document: IDocument): boolean {
    return this.hasTag(document, 'app:document-type:protocol');
  }

  isSubmission(document: IDocument): boolean {
    return this.hasTag(document, 'app:document-type:submission');
  }

  isTask(document: IDocument): boolean {
    return this.hasTag(document, 'app:document-type:task');
  }

  isTop(document: IDocument): boolean {
    return this.hasTag(document, 'app:document-type:top');
  }

  getWorkflowStatus(workflowDocument?: IWorkflowDocument): IDocumentField {
    if (workflowDocument) {
      const fields = workflowDocument.fields;
      return fields.status || fields.Status;
    }
    return null;
  }

  getWorkflowCurrentAssignee(workflowDocument?: IWorkflowDocument): IPermissionTarget[] {
    if (workflowDocument) {
      const fields = workflowDocument.fields;
      return (fields['current-assignee']?.value as IPermissionTarget[]) || [];
    }
    return null;
  }
  createDecisionDocumentFromDecisionStamp(decisionStamp: IDecisionStamp): Observable<IDocument> {
    const templateId = decisionStamp.dialogId || _defaultDecisionId;
    const isDefault = templateId === _defaultDecisionId || !templateId;

    const stream$ = this.createDocumentByTemplateIdDecision(templateId);
    return stream$.pipe(
      switchMap(document => zip(this.listsService.getListItems(decisionStamp.decisionChoicesId), of(document))),
      map(tuple => ({ list: tuple[0], document: tuple[1] })),
      map(response => {
        const document = response.document;
        const decisionChoicesList = response.list;
        const fieldType = isDefault ? 'decision' : decisionStamp.decisionDialogFieldMapping['decision'];
        const fieldCategory = isDefault ? 'category' : (decisionStamp.decisionDialogFieldMapping['category'] as string);
        const fieldDate = isDefault ? 'date' : (decisionStamp.decisionDialogFieldMapping['date'] as string);

        if (!decisionChoicesList || !fieldType || !fieldCategory) {
          NotifyService.global.warn('Beschluss kann nicht erstellt werden');
          return null;
        }
        document.template.fields.map(field => {
          if (field.name === fieldType) {
            field.choices = decisionChoicesList.entries;
          }
          if (field.name === fieldCategory) {
            field.choices = [{ label: decisionStamp.displayName, value: decisionStamp.name }];
          }
        });
        document.fields[fieldCategory] = { value: decisionStamp.name, effectivePermissions: ['read'] };
        if (document.fields[fieldDate] && !document.fields[fieldDate]?.value) {
          document.fields[fieldDate].value = new Date();
        }
        return document;
      })
    );
  }
  createDecisionDocumentFromDecisionValue(decisionStamp: FieldDecisionValue, category: string): Observable<IDocument> {
    const templateId = decisionStamp.documentTemplateId;
    const isDefault = templateId === _defaultDecisionId || !templateId;

    const stream$ = this.createDocumentByTemplateIdDecision(templateId);
    const getdata = (document: IDocument, decisionChoicesList: SiamList): IDocument => {
      const fieldType = isDefault ? 'decision' : decisionStamp.fieldMap['decision'];
      const fieldCategory = isDefault ? 'category' : decisionStamp.fieldMap['category'];

      if (!decisionChoicesList || !fieldType || !fieldCategory) {
        NotifyService.global.warn('Beschluss kann nicht erstellt werden');
        return null;
      }
      document.template.fields.map(field => {
        if (field.name === fieldType) {
          field.choices = decisionChoicesList.entries;
        }
        if (field.name === fieldCategory) {
          field.choices = [{ label: decisionStamp.caption, value: category }];
        }
      });
      const fields = clone(decisionStamp.fields);
      Object.keys(decisionStamp.fieldMap).forEach(key => {
        const fieldName = decisionStamp.fieldMap[key];
        if (key === 'decision') {
          fields[fieldName] = { value: decisionStamp.decision, effectivePermissions: ['read', 'update'] };
        }
        if (key === 'category') {
          fields[fieldName] = { value: category, effectivePermissions: ['read'] };
        }
        if (key === 'date') {
          fields[fieldName] = { value: decisionStamp.date, effectivePermissions: ['read', 'update'] };
        }
        if (key === 'number') {
          fields[fieldName] = { value: decisionStamp.number, effectivePermissions: ['read'] };
        }
      });
      const resultFields: IDocumentFields = {};

      for (const key in fields) {
        const currentItem = fields[key];
        const newItem: IDocumentField = { value: null, effectivePermissions: ['read', 'update'] };
        if (currentItem.value !== undefined) {
          newItem.value = currentItem.value as unknown;
        }
        const findKeyByValue = (obj: Record<string, string>, targetValue: string): string => {
          return Object.keys(obj).find(key => obj[key] === targetValue) || null;
        };
        if (['number', 'category'].includes(findKeyByValue(decisionStamp.fieldMap, key))) {
          newItem.effectivePermissions = ['read'];
        }

        resultFields[key] = newItem;
      }
      document.fields = resultFields;
      return document;
    };
    return stream$.pipe(
      switchMap(document => {
        if (!document) {
          return of(null);
        }
        if (decisionStamp.decisionChoicesId) {
          return this.listsService
            .getListItems(decisionStamp.decisionChoicesId)
            .pipe(map(list => getdata(document, list)));
        } else {
          return this.listsService.getList('Beschlussvermerke').pipe(map(list => getdata(document, list)));
        }
      })
    );
  }
  createDocumentByTemplateIdDecision(templateId: string): Observable<IDocument> {
    if (this.createDocumentByTemplateIdDecisionCache.has(templateId)) {
      const doc = this.createDocumentByTemplateIdDecisionCache.get(templateId);
      return of(clone(doc));
    }
    return this.templateService.getTemplate(templateId).pipe(
      switchMap(template => this.createDocumentByTemplateId(template)),
      map(document => {
        if (document) {
          this.createDocumentByTemplateIdDecisionCache.set(templateId, document);
        }
        return clone(document);
      })
    );
  }
  updateDecisionDocumentFromDecisionValue(doc: IDocument, category: string): Observable<IDocumentDecision> {
    const decisionValue = doc.fields[siamConst.decisionField]?.value as FieldDecisionsValue;
    if (!decisionValue) {
      return null;
    }
    if (!decisionValue[category]) {
      return null;
    }
    const decisionStamp = decisionValue[category];
    const templateId = decisionStamp.documentTemplateId;
    const isDefault = templateId === _defaultDecisionId || !templateId;

    const stream$ = this.createDocumentByTemplateIdDecision(templateId);
    const getdata = (document: IDocument, decisionChoicesList: SiamList): IDocument => {
      const fieldType = isDefault ? 'decision' : decisionStamp.fieldMap['decision'];
      const fieldCategory = isDefault ? 'category' : decisionStamp.fieldMap['category'];

      if (!decisionChoicesList || !fieldType || !fieldCategory) {
        NotifyService.global.warn('Beschluss kann nicht erstellt werden');
        return null;
      }
      document.template.fields.map(field => {
        if (field.name === fieldType) {
          field.choices = decisionChoicesList.entries;
        }
        if (field.name === fieldCategory) {
          field.choices = [{ label: decisionStamp.caption, value: category }];
        }
      });
      const fields = clone(decisionStamp.fields);
      Object.keys(decisionStamp.fieldMap).forEach(key => {
        const fieldName = decisionStamp.fieldMap[key];
        if (key === 'decision') {
          fields[fieldName] = { value: decisionStamp.decision, effectivePermissions: ['read', 'update'] };
        }
        if (key === 'category') {
          fields[fieldName] = { value: category, effectivePermissions: ['read'] };
        }
        if (key === 'date') {
          fields[fieldName] = { value: decisionStamp.date, effectivePermissions: ['read', 'update'] };
        }
        if (key === 'number') {
          fields[fieldName] = { value: decisionStamp.number, effectivePermissions: ['read'] };
        }
      });
      const resultFields: IDocumentFields = {};

      for (const key in fields) {
        const currentItem = fields[key];
        const newItem: IDocumentField = { value: null, effectivePermissions: ['read', 'update'] };
        if (currentItem.value !== undefined) {
          newItem.value = currentItem.value as unknown;
        }
        const findKeyByValue = (obj: Record<string, string>, targetValue: string): string => {
          return Object.keys(obj).find(key => obj[key] === targetValue) || null;
        };
        if (
          (!Object.keys(decisionStamp.fieldMap)?.length && ['number', 'category'].includes(key)) ||
          ['number', 'category'].includes(findKeyByValue(decisionStamp.fieldMap, key))
        ) {
          newItem.effectivePermissions = ['read'];
        }

        resultFields[key] = newItem;
      }
      document.fields = resultFields;
      return document;
    };
    return stream$.pipe(
      switchMap(document => {
        if (!document) {
          return of(null);
        }
        return this.listsService.getListItems(decisionStamp.decisionChoicesId).pipe(
          map(list => {
            const decisionDocument = getdata(document, list);
            return {
              caption: decisionStamp.caption,
              decisionName: category,
              categoryId: decisionStamp.categoryId,
              document: decisionDocument,
              status: decisionStamp.status,
              statusCaption: this.getDecisionStatusCaption(decisionStamp.status),
              creationSource: this.getDecisionCreationSource(decisionStamp),
              isActive: decisionStamp.status === 'final',
              color: (decisionDocument.fields['decisionColor']?.value as string) || _defaultDecisionColor,
              title: decisionStamp.number
            };
          })
        );
      })
    );
  }
  async getDecisionsFromDocument(doc: IDocument, agendaId?: string): Promise<IDocumentDecision[]> {
    const result: IDocumentDecision[] = [];
    const decisionValue = doc.fields[siamConst.decisionField]?.value as FieldDecisionsValue;
    if (!decisionValue) {
      return result;
    }
    for (const key of Object.keys(decisionValue)) {
      const decision = decisionValue[key];
      if (decision.status === 'deleted') {
        continue;
      }
      const document = await lastValueFrom(this.createDecisionDocumentFromDecisionValue(decision, key));
      if (!document) {
        continue;
      }
      document.template.caption = decision.caption;
      const color = (document.fields['decisionColor']?.value as string) || _defaultDecisionColor;
      const isActive = decision.status === 'final';
      const statusCaption = this.getDecisionStatusCaption(decision.status);
      const creationSource = this.getDecisionCreationSource(decision);
      const itemToPush = {
        caption: decision.caption,
        decisionName: key,
        categoryId: decision.categoryId,
        document,
        status: decision.status,
        statusCaption,
        creationSource,
        isActive,
        color,
        title: decision.number
      };
      if (agendaId && document?.fields[siamConst.decisionAgendaFieldId]?.value === agendaId) {
        result.push(itemToPush);
      }
      if (!agendaId) {
        result.push(itemToPush);
      }
    }
    return result;
  }

  getDecisionStatusCaption(status: TDecisionActionType): string {
    let statusCaption = '';
    switch (status) {
      case 'preliminary':
        statusCaption = 'Entwurf';
        break;
      case 'final':
        statusCaption = 'Aktiv';
        break;
      default:
        statusCaption = 'Gelöscht';
        break;
    }
    return statusCaption;
  }
  getDecisionCreationSource(decision: FieldDecisionValue): string {
    const agendaField = decision.fields[siamConst.decisionAgendaFieldId]?.value as string;
    return agendaField ? 'Sitzung' : 'Workflow';
  }
  /**
   * Erstellung der Dokument-Felder anhand eines Templates.
   *
   * @param fields Die Template-Felder
   * @returns Ein Dokument-Feld-Objekt, das die Default-Werte der übergebenen Felder enthält.
   */
  private createDefaultDocumentFields(fields?: FieldServer[]): IDocumentFields {
    const documentFields: IDocumentFields = {};

    if (fields) {
      fields.forEach(field => {
        documentFields[field.name] = {
          value: field.default !== undefined ? (field.default as unknown) : null,
          effectivePermissions: ['read', 'update']
        };
      });
    }

    return documentFields;
  }

  /**
   * Check if the given document has specific tag
   *
   * @param document
   * @param tagName
   * @private
   */
  private hasTag(document: IDocument | IReference, tagName: TTag): boolean {
    if (!document) {
      return false;
    }
    return document.tags.some(tag => tag.toLowerCase() === tagName);
  }
}
