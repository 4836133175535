<dx-popup
  *ngIf="approvalDocument"
  shadingColor="rgba(0, 0, 50, 0.5)"
  [width]="'min(1200px, 80vw)'"
  resizeEnabled="true"
  [(visible)]="dialogOpen"
  [height]="'auto'"
  [dragEnabled]="true"
  [fullScreen]="isFullscreen"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  [shading]="true"
  [toolbarItems]="toolbarItems">
  <div *dxTemplate="let data of 'content'">
    <app-component-notification></app-component-notification>
    <app-old-decision-include
      [editMode]="editMode"
      (visibleResult)="eventvisibleResult($event)"
      [currentDocument]="approvalDocument">
    </app-old-decision-include>
  </div>
</dx-popup>
