<ng-container *ngIf="(panel | async)?.loading">
  <dx-load-panel
    shadingColor="rgba(0,0,0,0.4)"
    [message]="(panel | async).message"
    [visible]="(panel | async).loading"
    [height]="(panel | async).height"
    [width]="(panel | async).width"
    [position]="{ of: '.app-wrapper' }"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false">
  </dx-load-panel>
</ng-container>
