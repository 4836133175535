import { IDxCustomRule, IDxEventComponentCheckBox, IDxItemValidationCallback, IDxRequiredRule } from './devextreme';
import {
  editorToolbarItemsBasic,
  editorToolbarItemsFont,
  editorToolbarItemsTable,
  isHasProperty
} from '@factories/helpers';
import { IFieldDataSourceStatic } from './fieldServer';
import { IDefaultValueSourceStatic, IDocumentFields, IPermissionTarget, ISignatureColumn, TPermission } from './siam';
import { ContentReadyEvent as ButtonContentReadyEvent } from 'devextreme/ui/button';
import {
  SelectionChangedEvent as TagBoxSelectionChangedEvent,
  ValueChangedEvent as TagBoxValueChangedEvent
} from 'devextreme/ui/tag_box';
import { ValueChangedEvent as CheckBoxValueChangedEvent } from 'devextreme/ui/check_box';
import * as uuid from 'uuid';
import {
  IBaseEditorOptions,
  ICheckBoxEditorOptions,
  ICheckBoxGroupEditorOptions,
  IDateBoxEditorOptions,
  IFieldVisibleFormula,
  IFileUploaderEditorOptions,
  INumberBoxEditorOptions,
  IReachTextBoxEditorOptions,
  ISelectableDefaultSourceType,
  ISelectBoxEditorOptions,
  ISignatureEditorOptions,
  ISliderEditorOptions,
  ITagBoxEditorOptions,
  ITextAreaEditorOptions,
  ITextBoxEditorOptions,
  TRequired,
  TVisibility
} from '@interfaces/fields';
import DevExpress from 'devextreme';
import { siamConst } from './siamConst';
import { TDecisionActionType } from './edgeServer';
import {
  defaultAgendaItemField,
  defaultAgendaItemFieldMeetingMinutes,
  defaultAgendaItemProtocolField,
  defaultAgendaItemSubmissionField,
  defaultAgendaItemSubmissionFieldMeetingMinutes,
  defaultAgendaItemTaskField,
  defaultAgendaPrintSettings,
  IAgendaSettings
} from './fieldAgenda';

export type FieldTypeClient =
  | 'dxTagBox'
  | 'dxTextBox'
  | 'dxDateBox'
  | 'dxNumberBox'
  | 'staticText'
  | 'linkText'
  | 'dxSlider'
  | 'dxCheckBox'
  | 'dxCheckboxGroup'
  | 'dxTextArea'
  | 'dxRadioGroup'
  | 'dxSelectBox'
  | 'dxFileUploader'
  | 'group'
  | 'empty'
  | 'placeholder'
  | 'dxHtmlEditor'
  | 'signature';
export type TLayoutPlaceholderType =
  | 'decision'
  | 'votes'
  | 'signature'
  | 'toc:agenda'
  | 'toc:meeting-minutes'
  | 'agenda'
  | 'meeting-minutes'
  | 'tasks'
  | 'reference-documents'
  | 'diagram:gantt'
  | 'diagram:dx-diagram';
export const layoutPlaceholderTable: { label: string; name: TLayoutPlaceholderType; value: string; caption: string }[] =
  [
    { label: 'Beschluss-Informationen', name: 'decision', value: 'decision', caption: 'Beschluss-Informationen' },
    { label: 'Abstimmungen', name: 'votes', value: 'votes', caption: 'Abstimmungen' },
    { label: 'Aufgaben', name: 'tasks', value: 'tasks', caption: 'Aufgaben' },
    { label: 'Workflowprotokoll', name: 'signature', value: 'signature', caption: 'Workflowprotokoll' },
    { label: 'Referenzdokumente', name: 'reference-documents', value: 'children', caption: 'Referenzdokumente' },
    { label: 'Agenda (veraltet)', name: 'toc:agenda', value: 'agenda', caption: 'Agenda' },
    {
      label: 'Protokollzusammenfassung (veraltet)',
      name: 'toc:meeting-minutes',
      value: 'protocol',
      caption: 'Protokollzusammenfassung'
    },
    { label: 'Agenda', name: 'agenda', value: 'agenda', caption: 'Agenda' },
    {
      label: 'Protokollzusammenfassung',
      name: 'meeting-minutes',
      value: 'protocol',
      caption: 'Protokollzusammenfassung'
    }
  ];

export const fieldTypes: { label: string; name: FieldTypeClient }[] = [
  { label: 'Textfeld', name: 'dxTextBox' },
  { label: 'Textbereich', name: 'dxTextArea' },
  { label: 'Textbereich (Richtext)', name: 'dxHtmlEditor' },
  { label: 'Numerisches Feld', name: 'dxNumberBox' },
  { label: 'Datumsfeld', name: 'dxDateBox' },
  { label: 'Kontrollkästchen', name: 'dxCheckBox' },
  { label: 'Kontrollkästchen (mehrfach)', name: 'dxCheckboxGroup' },
  { label: 'Optionsfeld', name: 'dxRadioGroup' },
  { label: 'Auswahlfeld', name: 'dxSelectBox' },
  { label: 'Anlagenfeld', name: 'dxFileUploader' },
  { label: 'Namensfeld', name: 'dxTagBox' },
  { label: 'Statischer Text', name: 'staticText' },
  { label: 'Link', name: 'linkText' },
  { label: 'Schieberegler', name: 'dxSlider' },
  { label: 'Unterschriftsfeld', name: 'signature' }
];
const selectableDefaultSourceTypes: ISelectableDefaultSourceType[] = [
  { value: 'none', label: 'Keine' },
  { value: 'static', label: 'Statisch' }
];

export const defaultSignatureColumns: ISignatureColumn[] = [
  {
    type: 'timeStamp',
    dataField: 'historyEntry.timestamp',
    caption: 'Datum',
    visible: true,
    format: 'dd.MM.yyyy - HH:mm',
    width: 'auto'
  },
  { type: 'user', dataField: 'historyEntry.user', caption: 'Name', visible: true, width: 'auto' },
  {
    type: 'references',
    dataField: 'historyEntry.variables.assignee',
    caption: 'Empfänger',
    visible: true,
    width: 'auto'
  },
  {
    type: 'text',
    dataField: 'historyEntry.selectedEdge.label',
    caption: 'Workflowschritt',
    visible: true,
    width: 'auto'
  },
  { type: 'text', dataField: 'historyEntry.variables.kommentar', caption: 'Kommentar', visible: true },
  { type: 'signatureTemplate', dataField: 'historyEntry.variables.signature', caption: 'Unterschrift', visible: false }
];

export interface IAgendaAllowedTemplates {
  templateSubmissionIds?: string[];
  templateTopId?: string;
  templateTopProtocolId?: string;
  templateTopTaskId?: string;
  templateMeetingMinutesId?: string;
  templateRapidMeetingMinutesId?: string;
  allowCreateDecision?: boolean;
}

export interface FieldLabel {
  text: string;
  visible?: boolean;
}

export const fieldConstant = {
  type: 'dxTextBox',
  width: '100%',
  height: '80',
  stylingMode: 'filled' as DevExpress.common.EditorStyle,
  placeholder: '',
  hint: '',
  visible: true,
  included: true,
  required: false,
  displayExpr: 'label',
  valueExpr: 'value',
  withTime: true,
  fileUploadMaxSizeMB: 20,
  dataSource: [
    { label: 'Item 1', value: '1' },
    { label: 'Item 2', value: '2' },
    { label: 'Item 3', value: '3' }
  ]
};

export class FieldBaseClient<T = IBaseEditorOptions> {
  template?: string;
  kind?: FieldTypeClient;
  id?: string;
  name: string;
  caption: string;
  label: FieldLabel;
  included?: boolean;
  type?: FieldTypeClient;
  editorType?: FieldTypeClient;
  itemType?: FieldTypeClient;
  dataField: string;
  parentContainerName?: string;
  permissions?: TPermission[];
  validationRules?: Array<IDxCustomRule | IDxRequiredRule>;
  isRequired?: boolean;
  items?: any[];
  cssClass?: string;
  colSpan?: number;
  description?: string;
  editorOptions: T;
  requiredValidationId?: string;
  layoutEntryId?: string;

  constructor(type: FieldTypeClient, init?: Partial<FieldBaseClient<T>>) {
    this.type = type;
    this.editorType = type;
    this.itemType = undefined;
    this.colSpan = (init && init.colSpan) || 1;
    // kind > darf nicht für normale DxFields
    // gesetzt werden, da der Validator sonst nicht funktioniert, nur für unsere "group" verwenden;
    this.id = init?.id;
    this.name = (init && init.name) || ''; // || (init && init.caption);

    this.caption = (init && init.caption) || '';

    this.description = (init && init.description) || '';

    this.label = {
      text: (init && init.caption) || '',
      visible: init && init.label && init.label.visible !== undefined ? init.label.visible : true
    };

    this.cssClass = (init && init.cssClass) || '';

    this.layoutEntryId = init && init.layoutEntryId;

    this.parentContainerName = (init && init.parentContainerName) || undefined;
    this.included = (init && init.included) == null ? fieldConstant.included : init.included;
    this.items = init && init.items ? init.items : [];

    const options = ((init && init.editorOptions) as IBaseEditorOptions) || null;
    this.dataField = options ? this.name : '';

    this.editorOptions = (options as unknown as T) || ({} as T);

    const visibility = isHasProperty(options, 'visibility') ? options.visibility : fieldConstant.visible;
    (this.editorOptions as IBaseEditorOptions).fieldVisible = visibility === 'formular' ? false : !!visibility;
    (this.editorOptions as IBaseEditorOptions).selectableDefaultSource = selectableDefaultSourceTypes;
    (this.editorOptions as IBaseEditorOptions).visibleFormula = options?.visibleFormula || null;
    (this.editorOptions as IBaseEditorOptions).visibility = visibility;
    (this.editorOptions as IBaseEditorOptions).visible = true;
    (this.editorOptions as IBaseEditorOptions).requiredOption = options?.requiredOption || false;
    (this.editorOptions as IBaseEditorOptions).required = isHasProperty(options, 'required')
      ? options.required
      : fieldConstant.required;
    (this.editorOptions as IBaseEditorOptions).placeholder = isHasProperty(options, 'placeholder')
      ? options.placeholder
      : fieldConstant.placeholder;
    (this.editorOptions as IBaseEditorOptions).hint = isHasProperty(options, 'hint')
      ? options.hint
      : fieldConstant.hint;
    (this.editorOptions as IBaseEditorOptions).width = isHasProperty(options, 'width')
      ? options.width
      : fieldConstant.width;
    (this.editorOptions as IBaseEditorOptions).isTreeList = options?.isTreeList || null;
    (this.editorOptions as IBaseEditorOptions).isTreeListParent = options?.isTreeListParent || null;
    (this.editorOptions as IBaseEditorOptions).isTreeListChild = options?.isTreeListChild || null;
    (this.editorOptions as IBaseEditorOptions).treeListParentFieldName = options?.treeListParentFieldName || null;

    switch (options?.requiredOption) {
      case true:
        this.validationRules = [{ type: 'required', message: `Bitte füllen Sie das Feld "${this.label.text}" aus.` }];
        break;

      case 'required-if-visible':
        this.validationRules = [
          {
            type: 'custom',
            reevaluate: true,
            message: `Bitte füllen Sie das Feld "${this.label.text}" aus.`,
            validationCallback: (options: IDxItemValidationCallback<string>): boolean => {
              if (options.formItem.cssClass.includes('field-invisible')) {
                return true;
              } else {
                return !(options.value === undefined || options.value === null || options.value === '');
              }
            }
          }
        ];
        break;
      default:
        break;
    }
  }
}

/**
 *  Field types
 */
export class DateBox extends FieldBaseClient<IDateBoxEditorOptions> {
  kind: FieldTypeClient = 'dxDateBox';
  default: Date;

  constructor(init?: Partial<DateBox>) {
    super('dxDateBox', init);

    delete this.editorOptions.height;

    this.default = (init && init.default) || null;

    const options = init && init.editorOptions;
    const withTime = isHasProperty(options, 'withTime') ? options.withTime : fieldConstant.withTime;

    this.editorOptions.type = withTime ? 'datetime' : 'date';
    this.editorOptions.stylingMode = isHasProperty(options, 'stylingMode')
      ? options.stylingMode
      : fieldConstant.stylingMode;
    this.editorOptions.withTime = withTime;
    this.editorOptions.displayFormat = this.editorOptions.type === 'datetime' ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy';
    this.editorOptions.defaultSource = init?.editorOptions?.defaultSource || { type: 'none', value: null };
    this.editorOptions.selectableDefaultSource = selectableDefaultSourceTypes.concat({
      value: 'today',
      label: 'dynamisches Datum'
    });
  }
}

export class NumberBox extends FieldBaseClient<INumberBoxEditorOptions> {
  kind: FieldTypeClient = 'dxNumberBox';
  default: number;

  constructor(init?: Partial<NumberBox>) {
    super('dxNumberBox', init);
    delete this.editorOptions.height;

    this.default = (init && init.default) || 0;

    let numberFormat = '#0';
    const options = init && init.editorOptions;

    this.editorOptions.stylingMode = isHasProperty(options, 'stylingMode')
      ? options.stylingMode
      : fieldConstant.stylingMode;
    this.editorOptions.decimalPlaces = options?.decimalPlaces || 0;
    this.editorOptions.thousandsSeparator = options?.thousandsSeparator || false;
    this.editorOptions.underlyingType = options?.underlyingType || 'float';
    this.editorOptions.defaultSource = options?.defaultSource || { type: 'none', value: null };

    if (this.editorOptions.thousandsSeparator) {
      numberFormat = '###,##0';
    }

    this.editorOptions.format = numberFormat;

    if (this.editorOptions.underlyingType === 'float' && this.editorOptions.decimalPlaces > 0) {
      this.editorOptions.format = numberFormat.concat('.');
      for (let i = 0; i < this.editorOptions.decimalPlaces; i++) {
        this.editorOptions.format = this.editorOptions.format.concat('0');
      }
    }
  }
}

export class TextBox extends FieldBaseClient<ITextBoxEditorOptions> {
  kind: FieldTypeClient = 'dxTextBox';
  default: string;

  constructor(init?: Partial<TextBox>) {
    super('dxTextBox', init);

    delete this.editorOptions.height;

    this.default = (init && init.default) || '';

    const options = init && init.editorOptions;

    this.editorOptions.defaultSource = options?.defaultSource || { type: 'none', value: null };
    this.editorOptions.stylingMode = isHasProperty(options, 'stylingMode')
      ? options.stylingMode
      : fieldConstant.stylingMode;
    this.editorOptions.selectableDefaultSource = selectableDefaultSourceTypes.concat({
      value: 'serial-number',
      label: 'Nummernkreis'
    });
  }
}

export class RichTextEditor extends FieldBaseClient<IReachTextBoxEditorOptions> {
  kind: FieldTypeClient = 'dxHtmlEditor';
  template = 'htmlEditor';
  default: string;

  constructor(init?: Partial<RichTextEditor>) {
    super('dxHtmlEditor', init);

    this.default = (init && init.default) || '';

    const options = init && init.editorOptions;
    const toolbarOptions = options?.htmlEditorToolbarItemsOptions;
    const editorSettings = options?.htmlEditorSettings;

    this.editorOptions.stylingMode = isHasProperty(options, 'stylingMode')
      ? options.stylingMode
      : fieldConstant.stylingMode;
    this.editorOptions.defaultSource = options?.defaultSource || { type: 'none', value: null };
    this.editorOptions.height = options?.height || null;
    this.editorOptions.showEditModeButton = options?.showEditModeButton || false;
    this.editorOptions.htmlEditorToolbarItemsOptions = {
      image: toolbarOptions?.image || false,
      font: toolbarOptions?.font || true,
      link: toolbarOptions?.link || true,
      table: toolbarOptions?.table || true
    };

    this.editorOptions.htmlEditorSettings = {
      dynamicHeight: isHasProperty(editorSettings, 'dynamicHeight') ? editorSettings.dynamicHeight : true,
      fontSize: editorSettings?.fontSize || '',
      fontType: editorSettings?.fontType || '',
      maxHeight: editorSettings?.maxHeight || null
    };

    this.editorOptions.htmlEditorToolbarItems = editorToolbarItemsBasic();

    if (this.editorOptions.htmlEditorToolbarItemsOptions?.font) {
      this.editorOptions.htmlEditorToolbarItems = this.editorOptions.htmlEditorToolbarItems.concat(
        editorToolbarItemsFont()
      );
    }

    if (this.editorOptions.htmlEditorToolbarItemsOptions?.table) {
      this.editorOptions.htmlEditorToolbarItems = this.editorOptions.htmlEditorToolbarItems.concat(
        editorToolbarItemsTable()
      );
    }

    if (!this.editorOptions.htmlEditorToolbarItemsOptions?.link) {
      const index = this.editorOptions.htmlEditorToolbarItems.findIndex(i => i.name === 'link');
      if (index !== -1) {
        this.editorOptions.htmlEditorToolbarItems.splice(index, 1);
      }
    }

    if (!this.editorOptions.htmlEditorToolbarItemsOptions?.image) {
      const index = this.editorOptions.htmlEditorToolbarItems.findIndex(i => i.name === 'image');
      if (index !== -1) {
        this.editorOptions.htmlEditorToolbarItems.splice(index, 1);
      }
    }

    if (this.editorOptions.showEditModeButton) {
      this.editorOptions.htmlEditorToolbarItems.unshift({
        widget: 'dxButton',
        options: {
          icon: 'material-icons lock',
          hint: 'Bearbeiten sperren',
          stylingMode: 'text',
          elementAttr: { id: 'modeBtn' },
          onClick: (e: ButtonContentReadyEvent) => {
            const toolbarButton = e.element;
            const htmlEditor = toolbarButton.closest('dx-html-editor');
            const htmlContent = htmlEditor.querySelector('.dx-htmleditor-content');
            const toolbarContainer = toolbarButton.closest('.dx-toolbar-items-container');
            const allButtons = toolbarContainer.querySelectorAll('.dx-toolbar-button');
            const readState = htmlContent.getAttribute('contenteditable');
            htmlEditor.classList.toggle('editor-read-mode');
            if (readState === 'true') {
              // set content in read mode
              htmlContent.setAttribute('contenteditable', 'false');
              // hide toolbar buttons
              allButtons.forEach((btn, index) => {
                if (index !== 0) {
                  (btn as HTMLElement).style.visibility = 'hidden';
                }
              });
              e.component.option({ icon: 'edit', hint: 'Bearbeiten' });
            } else {
              // set content in edit mode
              htmlContent.setAttribute('contenteditable', 'true');
              // show toolbar buttons
              allButtons.forEach(btn => ((btn as HTMLElement).style.visibility = 'visible'));
              e.component.option({ icon: 'material-icons lock', hint: 'Bearbeiten sperren' });
            }
          }
        }
      });
    }
  }
}

export class TextArea extends FieldBaseClient<ITextAreaEditorOptions> {
  kind: FieldTypeClient = 'dxTextArea';
  default: string;

  constructor(init?: Partial<TextArea>) {
    super('dxTextArea', init);

    delete this.editorOptions.height;

    this.default = (init && init.default) || '';

    const options = init && init.editorOptions;

    this.editorOptions.autoResizeEnabled = options?.autoResizeEnabled || false;
    this.editorOptions.minHeight = options?.minHeight || fieldConstant.height;
    this.editorOptions.maxHeight = options?.maxHeight || fieldConstant.height;
    this.editorOptions.placeholder = options?.placeholder || '';
    this.editorOptions.defaultSource = options?.defaultSource || { type: 'none', value: null };
    this.editorOptions.stylingMode = isHasProperty(options, 'stylingMode')
      ? options.stylingMode
      : fieldConstant.stylingMode;

    if (this.editorOptions.autoResizeEnabled) {
      delete this.editorOptions.maxHeight;
    }
  }
}

export class StaticText extends FieldBaseClient<IReachTextBoxEditorOptions> {
  kind: FieldTypeClient = 'staticText';
  default: string;

  constructor(init?: Partial<StaticText>) {
    super('dxHtmlEditor', init);

    delete this.editorOptions.height;
    delete this.editorOptions.placeholder;

    this.default = (init && init.default) || '';

    const options = init && init.editorOptions;

    this.cssClass = this.cssClass.concat(' ', 'static-text');

    this.editorOptions.isStaticText = true;
    this.editorOptions.readOnly = true;
    this.editorOptions.required = false;
    this.editorOptions.autoResizeEnabled = true;
    this.editorOptions.defaultSource = options?.defaultSource || { type: 'none', value: null };
    this.editorOptions.stylingMode = isHasProperty(options, 'stylingMode')
      ? options.stylingMode
      : fieldConstant.stylingMode;
  }
}

export class LinkText extends FieldBaseClient<ITextAreaEditorOptions> {
  kind: FieldTypeClient = 'linkText';
  default: string;
  template = 'linkText';

  constructor(init?: Partial<LinkText>) {
    super('dxTextArea', init);

    delete this.editorOptions.height;

    const options = init && init.editorOptions;

    this.cssClass = this.cssClass.concat(' ', 'link-text');
    this.editorOptions.isLinkText = true;
    this.editorOptions.readOnly = !isHasProperty(options, 'required') || true;
    this.editorOptions.required = options?.required || false;
    this.editorOptions.autoResizeEnabled = true;
    this.editorOptions.linkContent = options?.linkContent || '#';
    this.editorOptions.linkName = options?.linkName || init.caption || '';
    this.editorOptions.defaultSource = options?.defaultSource || { type: 'none', value: null };
    this.editorOptions.stylingMode = isHasProperty(options, 'stylingMode')
      ? options.stylingMode
      : fieldConstant.stylingMode;
  }
}

export class CheckBox extends FieldBaseClient<ICheckBoxEditorOptions> {
  kind: FieldTypeClient = 'dxCheckBox';
  default: boolean;

  constructor(init?: Partial<CheckBox>) {
    super('dxCheckBox', init);
    delete this.editorOptions.height;

    const options = init && init.editorOptions;

    this.default = (init && init.default) || false;
    this.editorOptions.required = options?.required || fieldConstant.required;
    this.editorOptions.width = options?.width || fieldConstant.width;
    this.editorOptions.defaultSource = options?.defaultSource || { type: 'none', value: false };
    this.editorOptions.threeState = options?.threeState || false;
    this.editorOptions.stylingMode = isHasProperty(options, 'stylingMode')
      ? options.stylingMode
      : fieldConstant.stylingMode;

    if (this.editorOptions.threeState) {
      this.editorOptions.onValueChanged = (e: CheckBoxValueChangedEvent) => {
        const component = e.component as IDxEventComponentCheckBox;
        if (component.skipOnValueChanged) {
          component.skipOnValueChanged = false;
          return;
        }
        if (component.setUndefinedNextTime) {
          component.setUndefinedNextTime = false;
          component.skipOnValueChanged = true;
          component.option('value', undefined);
          return;
        }
        if (e.value === false) {
          component.option('isValid', true);
          component.setUndefinedNextTime = true;
        }
      };
    } else {
      this.editorOptions.onValueChanged = (e: CheckBoxValueChangedEvent) => {
        if (e.value === false || e.value === null || e.value === undefined) {
          e.component.option('value', false);
        } else {
          e.component.option('value', true);
        }
      };
    }
  }
}

export class CheckBoxGroup extends FieldBaseClient<ICheckBoxGroupEditorOptions> {
  kind: FieldTypeClient = 'dxCheckboxGroup';
  template = 'checkboxGroup';
  default: unknown;

  constructor(init?: Partial<CheckBoxGroup>) {
    super('dxCheckboxGroup', init);

    delete this.editorOptions.height;

    const options = init && init.editorOptions;

    this.default = (init && init.default) || [];
    this.editorOptions.displayExpr = options?.displayExpr || fieldConstant.displayExpr;
    this.editorOptions.valueExpr = options?.valueExpr || fieldConstant.valueExpr;
    this.editorOptions.layout = options?.layout || 'vertical';
    this.editorOptions.defaultSource = options?.defaultSource || { type: 'none', value: null };
    this.editorOptions.checkboxGroupColumns = options?.checkboxGroupColumns || 0;
    this.editorOptions.value = (options?.value as unknown) || [];
    this.editorOptions.dataSource = options?.dataSource || fieldConstant.dataSource;
    this.editorOptions.choices = options?.choices || null;
    this.editorOptions.stylingMode = isHasProperty(options, 'stylingMode')
      ? options.stylingMode
      : fieldConstant.stylingMode;
    this.editorOptions.choicesDataSource =
      options?.choicesDataSource ||
      ({ type: 'static', json: this.editorOptions.dataSource as unknown } as IFieldDataSourceStatic);
    this.editorOptions.selectableDefaultSource =
      this.editorOptions.choicesDataSource.type === 'list' &&
      !selectableDefaultSourceTypes?.find(v => v.value === 'list-all-entries')
        ? selectableDefaultSourceTypes.concat({ value: 'list-all-entries', label: 'alle Werte der Liste' })
        : selectableDefaultSourceTypes;
  }
}

export class RadioButton {
  label: string;
  value: string;

  constructor(label: string, value: string) {
    this.label = label;
    this.value = value;
  }
}

export class RadioGroup extends FieldBaseClient<ICheckBoxGroupEditorOptions> {
  kind: FieldTypeClient = 'dxRadioGroup';
  default: string;

  constructor(init?: Partial<RadioGroup>) {
    super('dxRadioGroup', init);

    delete this.editorOptions.height;

    const options = init && init.editorOptions;

    this.default = isHasProperty(init, 'default') ? init.default : null;

    this.editorOptions.defaultSource = options?.defaultSource || { type: 'none', value: null };
    this.editorOptions.layout = options?.layout || 'horizontal';
    this.editorOptions.valueExpr = options?.valueExpr || fieldConstant.valueExpr;
    this.editorOptions.displayExpr = options?.displayExpr || fieldConstant.displayExpr;
    this.editorOptions.dataSource = options?.dataSource || [new RadioButton('Ja', '1'), new RadioButton('Nein', '0')];
    this.editorOptions.choicesDataSource =
      options?.choicesDataSource ||
      ({ type: 'static', json: this.editorOptions.dataSource as unknown } as IFieldDataSourceStatic);
    this.editorOptions.stylingMode = isHasProperty(options, 'stylingMode')
      ? options.stylingMode
      : fieldConstant.stylingMode;
  }
}

export class SelectBox extends FieldBaseClient<ISelectBoxEditorOptions> {
  kind: FieldTypeClient = 'dxSelectBox';
  default: string;

  constructor(init?: Partial<SelectBox>) {
    super('dxSelectBox', init);

    delete this.editorOptions.height;

    const options = init && init.editorOptions;

    this.default = isHasProperty(init, 'default') ? init.default : null;

    this.editorOptions.defaultSource = init?.editorOptions?.defaultSource || { type: 'none', value: null };
    this.editorOptions.searchEnabled = isHasProperty(options, 'searchEnabled') ? options.searchEnabled : true;
    this.editorOptions.stylingMode = isHasProperty(options, 'stylingMode')
      ? options.stylingMode
      : fieldConstant.stylingMode;
    this.editorOptions.dataSource = options?.dataSource || fieldConstant.dataSource;
    this.editorOptions.choicesDataSource =
      options?.choicesDataSource ||
      ({ type: 'static', json: this.editorOptions.dataSource as unknown } as IFieldDataSourceStatic);
    this.editorOptions.valueExpr = options?.valueExpr || fieldConstant.valueExpr;
    this.editorOptions.displayExpr = options?.displayExpr || fieldConstant.displayExpr;
  }
}

export class DxSlider extends FieldBaseClient<ISliderEditorOptions> {
  kind: FieldTypeClient = 'dxSlider';
  default: number;
  template = 'sliderTemplate';

  constructor(init?: Partial<DxSlider>) {
    super('dxSlider', init);
    delete this.editorOptions.height;

    const options = init && init.editorOptions;
    const max = options?.customValues?.max || 100;
    const min = options?.customValues?.min || 0;
    const step = options?.customValues?.step || 0;
    const withTooltip = options?.customValues?.withTooltip || false;
    const labelPosition = options?.customValues?.labelPosition || null;
    const tooltipMode = options?.customValues?.tooltipMode || 'onHover';
    const displayFormat = options?.customValues?.displayFormat || '${value} %';

    this.default = (init && init.default) || 0;

    this.editorOptions.stylingMode = isHasProperty(options, 'stylingMode')
      ? options.stylingMode
      : fieldConstant.stylingMode;
    this.editorOptions.defaultSource = options?.defaultSource || { type: 'none', value: null };
    this.editorOptions.value = options?.value;
    this.editorOptions.min = min;
    this.editorOptions.max = max;
    this.editorOptions.step = step;
    this.editorOptions.underlyingType = options?.underlyingType || 'int';
    this.editorOptions.decimalPlaces = options?.decimalPlaces || 0;
    this.editorOptions.customValues = {
      min,
      max,
      step,
      withTooltip,
      labelPosition,
      tooltipMode,
      displayFormat
    };

    if (withTooltip) {
      this.editorOptions.tooltip = {
        enabled: true,
        format: (value: number): string => {
          if (displayFormat.includes('${value}')) {
            return displayFormat.replaceAll('${value}', String(value));
          }
          return String(value);
        },
        showMode: tooltipMode,
        position: 'bottom'
      };
    }

    if (labelPosition) {
      this.editorOptions.sliderLabel = {
        visible: true,
        format: (value: number) => {
          if (displayFormat.includes('${value}')) {
            return displayFormat.replaceAll('${value}', String(value));
          }
          return String(value);
        },
        position: labelPosition
      };
    }
  }
}

export class FileUploader extends FieldBaseClient<IFileUploaderEditorOptions> {
  kind: FieldTypeClient = 'dxFileUploader';
  template = 'fileUpload';
  default: string[];

  constructor(init?: Partial<FileUploader>) {
    super('dxFileUploader', init);

    delete this.editorOptions.height;

    this.default = init && init.default ? ['Datei'] : [];

    const options = init && init.editorOptions;

    this.editorOptions.stylingMode = isHasProperty(options, 'stylingMode')
      ? options.stylingMode
      : fieldConstant.stylingMode;
    this.editorOptions.defaultSource = options?.defaultSource || { type: 'none', value: undefined };
    (this.editorOptions.defaultSource as IDefaultValueSourceStatic<unknown>).value = undefined;
    this.editorOptions.allowedFileMimeTypes = options?.allowedFileMimeTypes || ['*/*'];

    // sometimes some file extensions does not recognize by the browser and mime type stay empty
    // in this case for DevExtreme need to say explicitly that it can be empty
    let allowedFileMimeTypes = this.editorOptions.allowedFileMimeTypes;
    if (allowedFileMimeTypes.includes('*/*')) {
      allowedFileMimeTypes = allowedFileMimeTypes.concat('');
    }

    // MimeTypes change from array to string, for DevExtreme accept property
    this.editorOptions.allowedFileMimeTypesDX = allowedFileMimeTypes.join();
    this.editorOptions.allowedFileExtensions = this.getFileExtensions();
    this.editorOptions.fileuploaderButtonTitel = init?.caption || 'Anlagen auswählen';
    this.editorOptions.fileuploaderMaxFileSizeMBit =
      options?.fileuploaderMaxFileSizeMBit || fieldConstant.fileUploadMaxSizeMB;
    this.editorOptions.fileuploaderMaxFileSizeBit = this.editorOptions.fileuploaderMaxFileSizeMBit * 1024 * 1024;
  }

  private getFileExtensions(): string[] {
    const result = new Set<string>();
    this.editorOptions.allowedFileMimeTypes.forEach(extension => {
      switch (extension) {
        case 'application/pdf':
          result.add('.pdf');
          break;

        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          result.add('.doc');
          result.add('.docx');
          break;

        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          result.add('.ppt');
          result.add('.pptx');
          break;

        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          result.add('.xls');
          result.add('.xlsx');
          break;

        case 'image/png':
          result.add('.png');
          break;

        case 'image/jpg':
          result.add('.jpg');
          break;

        case 'image/jpeg':
          result.add('.jpeg');
          break;

        default:
          result.add('*');
      }
    });
    return Array.from(result);
  }
}

export class PermissionTarget extends FieldBaseClient<ITagBoxEditorOptions> {
  kind: FieldTypeClient = 'dxTagBox';
  default: IPermissionTarget[];

  constructor(init?: Partial<PermissionTarget>) {
    super('dxTagBox', init);
    delete this.editorOptions.height;

    const options = init && init.editorOptions;
    const allowMultiple = isHasProperty(options, 'allowMultiple') ? options.allowMultiple : true;

    this.default = (init && init.default) || [];

    this.editorOptions.selectableDefaultSource = selectableDefaultSourceTypes.concat({
      value: 'current-user',
      label: 'aktueller Benutzer'
    });
    this.editorOptions.stylingMode = isHasProperty(options, 'stylingMode')
      ? options.stylingMode
      : fieldConstant.stylingMode;
    this.editorOptions.defaultSource = options?.defaultSource || { type: 'none', value: null };
    this.editorOptions.searchEnabled = options?.searchEnabled !== undefined ? options.searchEnabled : true;
    this.editorOptions.allowMultiple = allowMultiple;
    this.editorOptions.forbidRoles = !!options?.forbidRoles;
    this.editorOptions.forbidUsers = !!options?.forbidUsers;
    this.editorOptions.displayExpr = options?.displayExpr || 'name';
    this.editorOptions.valueExpr = options?.valueExpr || 'id';
    this.editorOptions.include = options?.include || [];
    this.editorOptions.targetField = options?.targetField || null;
    this.editorOptions.exclude = options?.exclude || [];
    this.editorOptions.resolve = options?.resolve || [];
    this.editorOptions.selection = options?.selection || null;
    this.editorOptions.showClearButton = true;
    this.editorOptions.showSelectionControls = allowMultiple;
    this.editorOptions.showDropDownButton = true;
    this.editorOptions.hideSelectedItems = false;
    this.editorOptions.onSelectionChanged = (e: TagBoxSelectionChangedEvent) => {
      if (e.addedItems.length > 0) {
        (e.component.field() as HTMLInputElement).value = ''; // clear the text
      }
    };

    if (!allowMultiple) {
      this.editorOptions.onValueChanged = (e: TagBoxValueChangedEvent) => {
        const value = e.value as string[];
        if (value && value.length > 1) {
          e.component.option('value', value.slice(-1));
        }
      };
    }
  }
}

export class SignatureField extends FieldBaseClient<ISignatureEditorOptions> {
  kind: FieldTypeClient = 'signature';
  default: string;
  template = 'signatureFieldTemplate';

  constructor(init?: Partial<SignatureField>) {
    super('signature', init);

    delete this.editorOptions.height;
    delete this.editorOptions.placeholder;

    const options = init && init.editorOptions;
    const customValuesSignature = options?.customValuesSignature;

    this.default = (init && init.default) || '';

    this.editorOptions.autoResizeEnabled = true;
    this.editorOptions.defaultSource = options?.defaultSource || { type: 'none', value: null };

    this.editorOptions.customValuesSignature = {
      width: customValuesSignature?.width || 600,
      height: customValuesSignature?.height || 200,
      maxWidth: customValuesSignature?.maxWidth || 2,
      penColor: customValuesSignature?.penColor || 'black',
      backgroundColor: customValuesSignature?.backgroundColor || 'white',
      isSecurityPatternEnabled: customValuesSignature?.isSecurityPatternEnabled || false
    };
  }
}

export class Group {
  template?: string;
  id: string;
  kind: FieldTypeClient = 'group';
  name: string;
  caption: string;
  label?: FieldLabel;
  default?: string;
  cssClass?: string;
  dataField?: string;
  editorType?: FieldTypeClient;
  items?: (FieldClient | Group | Container | Empty)[];
  itemType?: FieldTypeClient;
  colCount?: number;
  colSpan?: number;
  parentContainerName?: string;
  permissions?: TPermission[];
  type?: FieldTypeClient;
  validationRules?: Array<IDxCustomRule | IDxRequiredRule>;
  layoutEntryId?: string;
  editorOptions: {
    validationRules?: Array<IDxCustomRule | IDxRequiredRule>;
    id?: string;
    elementAttr?: { id: string };
    readOnly?: boolean;
    fieldVisible?: boolean; // as default True
    visibility?: TVisibility; // as default True
    width: string;
    value?: any;
    disabled?: boolean;
    required?: boolean;
    requiredOption?: TRequired;
    visibleFormula?: IFieldVisibleFormula;
    cssClassColor?: string;
    isGroupClosed?: boolean;
    hint?: string;
  };

  constructor(init?: Partial<Group>) {
    const options = init?.editorOptions;

    this.id = init?.id;
    this.caption = init?.caption || init?.name;
    this.name = init?.name || init?.caption;
    this.label = {
      text: '',
      visible: init?.label && init.label.visible !== undefined ? init.label.visible : true
    };
    this.type = 'group';
    this.editorType = 'group';
    this.itemType = 'group';
    this.colCount = init?.colCount || 1;
    this.colSpan = init?.colSpan || 1;
    this.cssClass = options?.cssClassColor || init?.cssClass || '';
    this.parentContainerName =
      init && init.parentContainerName && init.name !== init.parentContainerName ? init.parentContainerName : undefined;
    this.items = init?.items || [];

    this.editorOptions = options || { width: fieldConstant.width };
    this.editorOptions.disabled = options?.disabled || false;
    this.editorOptions.required = options?.required || false;
    this.editorOptions.fieldVisible = options?.visibleFormula
      ? false
      : options?.fieldVisible !== undefined
      ? options.fieldVisible
      : fieldConstant.visible;
    this.editorOptions.visibility = options?.visibleFormula ? 'formular' : this.editorOptions.fieldVisible;
    this.editorOptions.requiredOption = options?.requiredOption || false;
    this.editorOptions.width = options?.width || '100%';
    this.editorOptions.cssClassColor = options?.cssClassColor || '';
    this.editorOptions.isGroupClosed = options?.isGroupClosed || false;
    this.editorOptions.hint = options?.hint || fieldConstant.hint;
  }
}

export class Empty {
  id: string;
  kind: FieldTypeClient = 'empty';
  name: string;
  caption: string;
  label?: FieldLabel;
  default?: string;
  cssClass?: string;
  dataField?: string;
  itemType?: FieldTypeClient;
  colSpan?: number;
  parentContainerName?: string;
  permissions?: TPermission[];
  type?: FieldTypeClient;
  validationRules?: Array<IDxCustomRule | IDxRequiredRule>;
  layoutEntryId?: string;
  editorOptions: {
    validationRules?: Array<IDxCustomRule | IDxRequiredRule>;
    id?: string;
    elementAttr?: { id: string };
    readOnly?: boolean;
    fieldVisible?: boolean; // as default True
    visibility?: TVisibility; // as default True
    width: string;
    value?: any;
    disabled?: boolean;
    required?: boolean;
    requiredOption?: TRequired;
    visibleFormula?: IFieldVisibleFormula;
    cssClassColor?: string;
    isGroupClosed?: boolean;
  };

  constructor(init?: Partial<Group>) {
    const options = init?.editorOptions || null;

    this.id = init?.id;
    this.caption = 'Spacer';
    this.name = init?.name || init?.caption || `spacer-${uuid.v4()}`;
    this.label = {
      text: '',
      visible: init && init.label && init.label.visible !== undefined ? init.label.visible : true
    };
    this.type = 'empty';
    this.itemType = 'empty';
    this.colSpan = init?.colSpan || 1;
    this.cssClass = options?.cssClassColor || init?.cssClass || '';
    this.parentContainerName =
      init && init.parentContainerName && init.name !== init.parentContainerName ? init.parentContainerName : undefined;

    this.editorOptions = options || { width: fieldConstant.width };
    this.editorOptions.disabled = options?.disabled || false;
    this.editorOptions.required = options?.required || false;
    this.editorOptions.fieldVisible = options?.visibleFormula
      ? false
      : options && options.fieldVisible !== undefined
      ? options.fieldVisible
      : fieldConstant.visible;
    this.editorOptions.visibility = options?.visibleFormula ? 'formular' : this.editorOptions.fieldVisible;
    this.editorOptions.requiredOption = options?.requiredOption || false;
    this.editorOptions.width = options?.width || '100%';
    this.editorOptions.cssClassColor = options?.cssClassColor || '';
    this.editorOptions.isGroupClosed = options?.isGroupClosed || false;
  }
}

export class Container {
  id: string;
  template = 'container';
  kind = 'placeholder';
  name: string;
  placeholderType: TLayoutPlaceholderType;
  caption: string;
  label?: FieldLabel;
  default?: string;
  cssClass?: string;
  dataField?: string;
  editorType?: FieldTypeClient;
  items?: (FieldClient | Group)[];
  itemType?: FieldTypeClient;
  colCount?: number;
  colSpan?: number;
  parentContainerName?: string;
  permissions?: TPermission[];
  type?: FieldTypeClient;
  validationRules?: Array<IDxCustomRule | IDxRequiredRule>;
  layoutEntryId?: string;
  childrenTags?: string[];
  dynamicListName?: string;
  childrenAllowedTemplates?: string[];
  showParentDocuments?: boolean;
  agendaAllowedTemplates?: IAgendaAllowedTemplates;
  allowCreateChild?: boolean;
  allowAddReference?: boolean;
  taskTemplateId?: string;
  taskType?: 'list' | 'gantt' | 'both';
  editorOptions: {
    isMeetingMinutes?: boolean;
    agendaSettings?: IAgendaSettings;
    placeholderType?: TLayoutPlaceholderType;
    validationRules?: Array<IDxCustomRule | IDxRequiredRule>;
    id?: string;
    elementAttr?: { id: string };
    label?: FieldLabel;
    fieldVisible?: boolean; // as default True
    visibility?: TVisibility; // as default True
    width?: string;
    value?: any;
    disabled?: boolean;
    readOnly?: boolean;
    required?: boolean;
    requiredOption?: TRequired;
    visibleFormula?: IFieldVisibleFormula;
    cssClassColor?: string;
    isGroupClosed?: boolean;
    childrenTags?: string[];
    dynamicListName?: string;
    childrenAllowedTemplates?: string[];
    agendaAllowedTemplates?: IAgendaAllowedTemplates;
    allowCreateChild?: boolean;
    allowAddReference?: boolean;
    showParentDocuments?: boolean;
    taskTemplateId?: string;
    taskType?: 'list' | 'gantt' | 'both';
    signatureColumns?: ISignatureColumn[];
    hint?: string;
    denyPermissionRead?: string[];
    denyPermissionUpdate?: string[];
    allowPermissionRead?: string[];
    allowPermissionUpdate?: string[];
  };

  constructor(type: TLayoutPlaceholderType, init: Partial<Container> = {}) {
    const options = init?.editorOptions;

    this.id = init?.id;
    this.placeholderType = type || 'tasks';
    this.name = init?.name || this.placeholderType;
    this.caption = init?.caption || layoutPlaceholderTable.find(p => p.name === this.name)?.caption || 'Aufgaben';
    this.label = {
      text: '',
      visible: init && init.label && init.label.visible !== undefined ? init.label.visible : true
    };
    this.taskType = init?.taskType || 'list';
    this.taskTemplateId = init?.taskTemplateId || null;
    this.type = 'placeholder';
    this.items = init?.items || [];
    this.colCount = init?.colCount || 1;
    this.colSpan = init?.colSpan || 1;
    this.cssClass = init?.editorOptions?.cssClassColor || init?.cssClass || '';
    this.childrenTags = init?.childrenTags || [];
    this.dynamicListName = init?.dynamicListName || null;
    this.childrenAllowedTemplates = init?.childrenAllowedTemplates || [];
    this.showParentDocuments = init?.showParentDocuments || false;
    this.agendaAllowedTemplates = init?.agendaAllowedTemplates || { allowCreateDecision: true };
    if (
      init?.agendaAllowedTemplates?.allowCreateDecision === undefined ||
      init?.agendaAllowedTemplates?.allowCreateDecision === null
    ) {
      this.agendaAllowedTemplates = { ...init.agendaAllowedTemplates, allowCreateDecision: true };
    }
    this.allowCreateChild = init?.allowCreateChild !== undefined ? init.allowCreateChild : true;
    this.allowAddReference = init?.allowAddReference !== undefined ? init.allowAddReference : true;
    this.parentContainerName =
      init && init.parentContainerName && init.name !== init.parentContainerName ? init.parentContainerName : undefined;

    this.editorOptions = options ? options : { width: fieldConstant.width };
    this.editorOptions.disabled = options?.disabled || false;
    this.editorOptions.placeholderType = this.placeholderType;
    this.editorOptions.required = options?.required || false;
    this.editorOptions.fieldVisible = options?.visibleFormula
      ? false
      : options?.fieldVisible !== undefined
      ? options.fieldVisible
      : fieldConstant.visible;
    this.editorOptions.visibility = options?.visibleFormula ? 'formular' : this.editorOptions.fieldVisible;
    this.editorOptions.requiredOption = options?.requiredOption || false;
    this.editorOptions.width = options?.width || '100%';
    this.editorOptions.cssClassColor = options?.cssClassColor || '';
    this.editorOptions.isGroupClosed = (options && options.isGroupClosed) || false;
    this.editorOptions.signatureColumns = options?.signatureColumns || defaultSignatureColumns;
    this.editorOptions.hint = options?.hint !== undefined ? options.hint : fieldConstant.hint;
  }
}

export class AgendaField extends Container {
  name = siamConst.agendaField;

  constructor(init?: Partial<AgendaField>) {
    super('agenda', init);
    this.editorOptions = init?.editorOptions || {};
    this.editorOptions.width = init?.editorOptions?.width || '100%';
    this.editorOptions.agendaSettings = {
      topColumns: init?.editorOptions?.agendaSettings?.topColumns || defaultAgendaItemField,
      agendaSubTempates: {
        templateMeetingMinutesId:
          init?.editorOptions?.agendaSettings?.agendaSubTempates?.templateMeetingMinutesId || null,
        templateRapidMeetingMinutesId:
          init?.editorOptions?.agendaSettings?.agendaSubTempates?.templateRapidMeetingMinutesId || null
      },
      topSubTemplates: {
        dynamicListName: init?.editorOptions?.agendaSettings?.topSubTemplates?.dynamicListName || null,
        templateTopId: init?.editorOptions?.agendaSettings?.topSubTemplates?.templateTopId || null,
        templateProtocolId: init?.editorOptions?.agendaSettings?.topSubTemplates?.templateProtocolId || null,
        templateSubmissionIds: init?.editorOptions?.agendaSettings?.topSubTemplates?.templateSubmissionIds || [],

        templateTaskId: init?.editorOptions?.agendaSettings?.topSubTemplates?.templateTaskId || null
      },
      topDecision: {
        decisionStampIds: init?.editorOptions?.agendaSettings?.topDecision?.decisionStampIds || [],
        status: init?.editorOptions?.agendaSettings?.topDecision?.status || 'preliminary'
      },
      maxDepth: init?.editorOptions?.agendaSettings?.maxDepth || 1,
      startDateFieldName: init?.editorOptions?.agendaSettings?.startDateFieldName || null,
      submissionFields: init?.editorOptions?.agendaSettings?.submissionFields || defaultAgendaItemSubmissionField,
      taskFields: init?.editorOptions?.agendaSettings?.taskFields || defaultAgendaItemTaskField
    };
    const visibility = isHasProperty(init?.editorOptions, 'visibility')
      ? init?.editorOptions.visibility
      : fieldConstant.visible;
    (this.editorOptions as IBaseEditorOptions).fieldVisible = visibility === 'formular' ? false : !!visibility;
    (this.editorOptions as IBaseEditorOptions).selectableDefaultSource = selectableDefaultSourceTypes;
    (this.editorOptions as IBaseEditorOptions).visibleFormula = init?.editorOptions?.visibleFormula || null;
    (this.editorOptions as IBaseEditorOptions).visibility = visibility;
    (this.editorOptions as IBaseEditorOptions).visible = true;
  }
}

export class MeetingMinutesField extends Container {
  name = siamConst.agendaField;
  constructor(init?: Partial<MeetingMinutesField>) {
    super('meeting-minutes', init);
    this.editorOptions = init?.editorOptions || {};
    this.editorOptions.width = init?.editorOptions?.width || '100%';
    this.editorOptions.isMeetingMinutes = true;
    this.editorOptions.agendaSettings = {
      topColumns: init?.editorOptions?.agendaSettings?.topColumns || defaultAgendaItemFieldMeetingMinutes,
      submissionFields:
        init?.editorOptions?.agendaSettings?.submissionFields || defaultAgendaItemSubmissionFieldMeetingMinutes,
      protocolFields: init?.editorOptions?.agendaSettings?.protocolFields || defaultAgendaItemProtocolField,
      printSettings: init?.editorOptions?.agendaSettings?.printSettings || defaultAgendaPrintSettings
    };
    /*     this.name = '--agenda-items';
    this.caption = 'TOP';
 */
  }
}

export type FieldTypes = FieldClient | Group | Container | Empty;
export type FieldClient =
  | TextBox
  | TextArea
  | RadioGroup
  | SelectBox
  | StaticText
  | DxSlider
  | CheckBox
  | CheckBoxGroup
  | DateBox
  | FileUploader
  | PermissionTarget
  | NumberBox
  | LinkText
  | RichTextEditor
  | SignatureField;

export interface FieldDecisionValue {
  caption: string;
  categoryId: string;
  date: string;
  decision: string;
  documentTemplateId: string;
  decisionChoicesId: string;
  fieldMap: Record<string, string>;
  fields: IDocumentFields;
  lastChange: { lastChange: string; status: TDecisionActionType }[];
  number: string;
  status: TDecisionActionType;
}

export interface FieldDecisionsValue {
  [KEY: string]: FieldDecisionValue;
}
