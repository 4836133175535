import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  Renderer2
} from '@angular/core';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { DxFormComponent } from 'devextreme-angular';
import { ListsService } from '@services/lists.service';
import { NumberGroupsService } from '@services/number-groups.service';
import { NotifyService } from '@services/notify.service';
import { LoggerService } from '@services/logger.service';
import { IApprovalForm, IDocument, IEventVisibleCategorie, SiamListItem, TEditMode } from '@interfaces/siam';
import { NumberGroup } from '@interfaces/numberGroup';
import { ValueChangedEvent } from 'devextreme/ui/select_box';
import { ContentReadyEvent as FormContentReadyEvent } from 'devextreme/ui/form';

@Component({
  selector: 'app-old-decision-include',
  templateUrl: './old-decision-include.component.html',
  styleUrls: ['./old-decision-include.component.scss']
})
export class OldDecisionIncludeComponent implements OnChanges, OnDestroy {
  @ViewChild(DxFormComponent) approvalForm: DxFormComponent;

  @Input() currentDocument: IDocument;
  @Input() editMode: TEditMode = 'ReadOnly';

  @Output() visibleResult = new EventEmitter<IEventVisibleCategorie>();

  approvalFormData: IApprovalForm = null;
  decisionCategories: SiamListItem[] = [];
  decisionTypes: Observable<SiamListItem[]>;
  isCategoryExists = true;
  numberGroups: NumberGroup[] = [];

  private unlistener: () => void;
  private destroyed$ = new Subject<void>();

  constructor(
    private logger: LoggerService,
    private listsService: ListsService,
    private numberGroupService: NumberGroupsService,
    private renderer: Renderer2
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.currentDocument?.currentValue) {
      return;
    }
    this.logger.debug('documentform-view - CHANGES: {@0}', changes);
    void this.initApprovalForm();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    if (typeof this.unlistener === 'function') {
      this.unlistener();
    }
  }

  // freie Beschlussnummer vergeben
  getNextApprovalNumber(): Observable<string> {
    return this.numberGroupService.getNext(this.approvalFormData.category).pipe(
      tap({
        next: (nextNumber): void => {
          // eslint-disable-next-line id-blacklist
          this.approvalFormData.number = nextNumber;
        },
        error: (): void => {
          NotifyService.global.error('Es wurde kein Nummernkreis in der Anwendung definiert!');
        }
      }),
      takeUntil(this.destroyed$)
    );
  }

  onFieldDataChanged = (e: ValueChangedEvent): void => {
    // bei Auswahl einer Beschlusskategorie nächste freie Beschlussnummer ermitteln
    if (e.value && e.previousValue) {
      // eslint-disable-next-line
      this.approvalFormData.number = '';
      const newValue = e.value as string;
      this.peekNextNumber(newValue)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(nextNumber => {
          this.logger.debug('nächste freie Nummer für [{0}] : {1}', newValue, nextNumber);
          // eslint-disable-next-line
          this.approvalFormData.number = nextNumber;
        });
    }
  };

  onContentFormReady = (e: FormContentReadyEvent): void => {
    /* add open-close event for not empty group */
    /*------ group styles -------*/
    const formGroups = Array.from(e.element.querySelectorAll('.group-item'));
    for (const element of formGroups) {
      const groupHeader = element.querySelector('.dx-form-group-caption');
      const groupContent = element.querySelector('.dx-form-group-content');

      let isGroupClosed = element.classList.contains('close-group');
      if (groupContent?.childNodes[0]?.childNodes.length && groupHeader) {
        this.unlistener = this.renderer.listen(groupHeader, 'click', (clickEvent: Event) => {
          clickEvent.stopPropagation();
          clickEvent.preventDefault();

          const action = isGroupClosed ? 'removeClass' : 'addClass';
          this.renderer[action](element, 'close-group');
          isGroupClosed = !isGroupClosed;
        });
      }
    }
  };
  private async initApprovalForm(): Promise<void> {
    this.decisionTypes = this.listsService.getListEntries('Beschlussvermerke');
    this.numberGroups = await lastValueFrom(this.numberGroupService.getNumberGroups());
    if (this.currentDocument) {
      const fields = this.currentDocument.fields;
      let data: IApprovalForm;
      this.numberGroups.forEach(numberGroup => {
        if (numberGroup.tags.some(tag => tag === this.currentDocument.template.caption)) {
          if (!this.decisionCategories.find(categorie => categorie.value === numberGroup.name)) {
            this.decisionCategories.push({ value: numberGroup.name, label: numberGroup.name });
          }
        }
      });
      if (!this.decisionCategories.length) {
        this.isCategoryExists = false;
        if (this.editMode !== 'ReadOnly') {
          NotifyService.component.error(
            `Keine passende Beschlusskategorie für '(${this.currentDocument.template.caption})' gefunden.`
          );
        }
      }

      this.visibleResult.emit({
        visible: this.isCategoryExists
      });

      if (
        fields['--approval_category']?.value ||
        fields['--approval_date']?.value ||
        fields['--approval_number']?.value ||
        fields['--approval_type']?.value
      ) {
        data = {
          category: (fields['--approval_category']?.value as string) || 'Gesamtvorstandsbeschluss',
          date: (fields['--approval_date']?.value as string) || new Date().toISOString(),
          // eslint-disable-next-line
          number: fields['--approval_number']?.value as string,
          type: fields['--approval_type']?.value as string,
          comment: fields['--approval_comment']?.value as string
        };
        this.logger.debug('initialisiere Beschlussdialog mit bestehenden Daten...');
      } else {
        data = {
          category: this.decisionCategories?.length ? (this.decisionCategories[0].value as string) : '',
          date: new Date().toISOString(),
          // eslint-disable-next-line
          number: this.decisionCategories?.length
            ? await lastValueFrom(this.peekNextNumber(this.decisionCategories[0].value as string))
            : '',
          type: '',
          comment: ''
        };
      }
      this.approvalFormData = data;
    }
  }

  private peekNextNumber(value: string): Observable<string> {
    return this.numberGroupService.peekNext(value).pipe(
      tap({
        next: (): void => {
          // nothing to do
        },
        error: (error: string): void => {
          this.logger.warn('WARN: {@0}', error);
          // Falls der Nummernkreis für die gewählte Beschlusskategorie nicht existiert,
          // auf Nummernkreis "Beschluss" zurückfallen...
          this.peekNextNumber('Beschluss')
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
              next: (nextNumber): void => {
                this.logger.warn(
                  'Nummernkreis für Beschlusskategorie {0} nicht gefunden, suche nach [Beschluss]: {0}',
                  nextNumber
                );
                // eslint-disable-next-line
                this.approvalFormData.number = nextNumber;
              },
              error: (): void => {
                NotifyService.global.error('Es wurde kein Nummernkreis in der Anwendung definiert!');
              }
            });
        }
      })
    );
  }
}
