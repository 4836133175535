import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { concat, concatMap, lastValueFrom, Observable, of, Subject, toArray, zip } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import DevExpress from 'devextreme';
import { DxDataGridComponent, DxPopoverComponent } from 'devextreme-angular';
import { ListsService } from '@services/lists.service';
import { DocumentHelperService } from '@services/document-helper.service';
import { DocumentService } from '@services/document.service';
import { NotifyService } from '@services/notify.service';
import {
  IDxDataGridCellTemplate,
  IDxDataGridOnCustomItemHeaderFilter,
  IDxEditorToolbarItem,
  IDxGroupItem,
  IDxToolbarItem,
  TFilterGrid
} from '@interfaces/devextreme';
import {
  IColumnHeaderFilter,
  IDocument,
  IDocumentField,
  IDocumentSearchFieldConfiguration,
  IDocumentsSearch,
  IDynamicDocumentInfo,
  IDynamicGridSettings,
  IDynamicGridSettingsColumn,
  IDynamicList,
  IDynamicListState,
  IDynamicListStateToolbar,
  IError,
  IEventEmitter,
  ILabel,
  ILabelHeaderFilter,
  IPermissionTarget,
  ISearch,
  ISortColumn,
  IUserPipe,
  IWorkflow,
  PeriodDataSource,
  SiamListItem,
  TCardPeriod,
  TDynamicGridColumnDataType,
  TEditMode,
  TSelectionMode
} from '@interfaces/siam';
import { GetNameDataPipe } from '@pipes/get-namedata.pipe';
import { create, getCurrentUser } from '@factories/user.factory';
import { UserService } from '@services/user.service';
import { RoleService } from '@services/roles.service';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { ConfigurationService } from '@services/configuration.service';
import { SiamList } from '@interfaces/siamList';
import CustomStore from 'devextreme/data/custom_store';
import { LoginService } from '@services/login.service';
import { LoggerService } from '@services/logger.service';
import * as Factory from '@factories/document.factory';
import {
  clone,
  combineQueries,
  convertDevexpressDataType,
  dateToLocal,
  extractFieldFromFilter,
  extractFirstValue,
  getDateRange,
  getStringLength,
  isHasProperty,
  queryAdapter,
  sortBy
} from '@factories/helpers';
import { Cell, CellHyperlinkValue, Workbook, Worksheet } from 'exceljs';
import { DataGridCell, exportDataGrid } from 'devextreme/excel_exporter';
import { DateTime } from 'luxon';
import * as FS from 'file-saver';
import { TagsService } from '@services/tags.service';
import dxDataGrid, {
  CellHoverChangedEvent,
  EditorPreparingEvent,
  ExportingEvent,
  OptionChangedEvent,
  SelectionChangedEvent
} from 'devextreme/ui/data_grid';
import { LoadPanelService } from '@services/load-panel.service';
import { siamConst } from '@interfaces/siamConst';
import { ValueChangedEvent } from 'devextreme/ui/switch';
import { ItemClickEvent } from 'devextreme/ui/drop_down_button';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import dxList, { ItemRenderedEvent } from 'devextreme/ui/list';
import dxTreeView from 'devextreme/ui/tree_view';
import * as uuid from 'uuid';
import { DecisionStampService } from '@services/decision-stamp.service';
import { _defaultDecisionColor, IDecisionStamp, TDecisionFields } from '@interfaces/default-decision';
import ArrayStore from 'devextreme/data/array_store';
import Column = DevExpress.ui.dxDataGrid.Column;
import DataType = DevExpress.common.DataType;
import { WorkflowService } from '@services/workflow.service';
import { FieldDecisionsValue } from '@interfaces/fieldClient';
import { TDecisionActionType } from '@interfaces/edgeServer';

interface IParentDocument {
  parentDocumentId?: string;
  parentDocumentTags?: string[];
  parentDocumentType?: string;
  parentDocumentIcon?: string;
  parentDocumentSubject?: string;
}

interface IChildDocument {
  childDocumentId?: string;
  childDocumentTags?: string[];
  childDocumentType?: string;
  childDocumentIcon?: string;
  childDocumentSubject?: string;
}

interface IDataSource {
  document: IDocument;
  attachment: boolean;
  creatorNameWithAvatar: string;
  modificationNameWithAvatar: string;
  creatorDepartment: string;
  labels: ILabel[];
  labelsTooltip: string;
  status: string;
  statusColor: string;
  progress: number;
  currentAssignee: string;
  parentDocuments?: IParentDocument[];
  childDocuments?: IChildDocument[];
}

interface IDynamicGridSettingsTemplates {
  showIcon: boolean;
  showLabels: boolean;
  showCreatorName: boolean;
  showModificationName: boolean;
  showCreatorDepartment: boolean;
  showProgress: boolean;
  showWorkflowStatus: boolean;
  showWorkflowColor: boolean;
  showCurrentAssignee: boolean;
  showParentDocuments: boolean;
  showChildDocuments: boolean;
  showDecisionData: boolean;
  showDecisionApprovalType: boolean;
}

interface IDxExcelExportOptions {
  excelCell?: Cell;
  gridCell?: DataGridCell;
}

interface ITimer {
  start: () => Promise<unknown>;
  abort: () => void;
}

interface IDxEditorPreparingEvent extends EditorPreparingEvent {
  updateValueTimeout?: number;
}

@Component({
  selector: 'app-dynamic-list',
  templateUrl: './dynamic-list.component.html',
  styleUrls: ['./dynamic-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DynamicListComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  @ViewChild('dynamicGrid', { static: true }) gridComponent: DxDataGridComponent;
  @ViewChild('popOverCreationColumn') popOverCreationColumn: DxPopoverComponent;

  @Input() additionalSettings: IDynamicGridSettings = null;
  @Input() buttons: IDxEditorToolbarItem[] = [];
  @Input() allowDeleting = false;
  @Input() selectionMode: TSelectionMode = 'single';
  @Input() documentType: Observable<string> | string;
  @Input() embeddedMode = false;
  @Input() personalMode = false;
  @Input() listener: Observable<IEventEmitter<unknown>>;
  @Output() emitter = new EventEmitter<IEventEmitter<IDocument | IDocument[] | string>>();
  /**
   * Do not store state if true
   */
  @Input() noState = false;

  allowedPageSizes = [25, 50, 75, 100];
  dataGridId = uuid.v4();
  dataSource: CustomStore;
  readonly dateRangeValues = PeriodDataSource;
  dateRangeDataSource = PeriodDataSource;
  info: IDynamicDocumentInfo;
  labelsColumnHeaderFilter: ILabelHeaderFilter[];
  decisionColumnHeaderFilterDataStore: ArrayStore = new ArrayStore({ key: 'key', data: [] });
  decisionTypeColumnHeaderFilterDataStore: ArrayStore = new ArrayStore({ key: 'key', data: [] });
  decisionStamps: IDecisionStamp[];
  workflowsVertices: IWorkflow[];
  listState: IDynamicListState;
  pagerVisible = false;
  pageSize: number;
  popoverElement: HTMLElement;
  popoverContent: string;
  selectedDocument: IDocument;
  settings: IDynamicGridSettings;
  summaryColumnName: string;
  timer: ITimer;

  #attachmentFields: string[] = [];
  #destroyable$ = new Subject<void>();
  #documentType: string = null;
  #doNotQuery = true;
  #fields: string[] = [];
  #firstValue: string;
  #approvalLists: Record<string, SiamList> = {};
  #lists: Record<string, SiamList> = {};
  readonly #loadingMessage = 'Liste wird zusammengestellt...';
  readonly #loadingErrorMessage =
    'Beim Laden einer Ansicht ist ein Fehler aufgetreten. Bitte wenden Sie sich an Ihren Administrator.';
  #roles: Map<string, IUserPipe>;
  #users: Map<string, IUserPipe>;
  #templates: IDynamicGridSettingsTemplates;
  #unlistener: () => void;

  constructor(
    private configurationService: ConfigurationService,
    private documentService: DocumentService,
    private workflowService: WorkflowService,
    private helperService: DocumentHelperService,
    private tagsService: TagsService,
    private listsService: ListsService,
    private loadPanelService: LoadPanelService,
    private nameDataPipe: GetNameDataPipe,
    private roleService: RoleService,
    private userService: UserService,
    private loginService: LoginService,
    private logger: LoggerService,
    private decisionStampService: DecisionStampService,
    private renderer: Renderer2,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private zone: NgZone,
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    this.resetState();
    /*  Add tooltip in header filter */
    dxList.defaultOptions({
      device: { deviceType: 'desktop' },
      options: {
        onItemRendered: (args: ItemRenderedEvent<ILabelHeaderFilter>) => {
          if (args.itemData.userName) {
            args.itemElement.setAttribute('title', args.itemData.userName);
          } else {
            args.itemElement.setAttribute('title', args.itemData.text);
          }
        }
      }
    });

    dxTreeView.defaultOptions({
      device: { deviceType: 'desktop' },
      options: {
        onItemRendered: (args: ItemRenderedEvent<ILabelHeaderFilter>) => {
          args.itemElement.setAttribute('title', args.itemData.text);
        }
      }
    });
  }

  private get isPersonalMode(): boolean {
    return (
      this.personalMode || (this.settings?.toolbar.isShowPersonalMode && this.listState.toolbar?.personalMode === 2)
    );
  }

  /**
   * get DataGridColumnChooser element
   *
   * @param elem
   * @param selector
   */
  private static getDataGridColumnChooserElement(elem: HTMLElement | Document, selector: string): HTMLElement {
    for (; elem && elem !== document; elem = elem.parentNode as HTMLElement) {
      if ((elem as HTMLElement).matches(selector)) {
        return elem as HTMLElement;
      }
    }
    return null;
  }

  private static isUndefined(source: unknown): boolean {
    return typeof source === 'undefined';
  }

  /**
   * Sanitaze HTML
   *
   * @param str {string}
   */
  private static sanitizeHTML(str: string): string {
    const temporaryDiv = document.createElement('div');
    temporaryDiv.innerHTML = str;
    return (temporaryDiv.textContent || temporaryDiv.innerText || '').trim();
  }

  ngOnInit(): void {
    this.listener?.pipe(takeUntil(this.#destroyable$)).subscribe({
      next: e => {
        switch (e.command) {
          case 'reload':
            this.onRefresh();
            break;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.saveState();
    this.#destroyable$.next();
    this.#destroyable$.complete();

    if (this.#unlistener) {
      this.#unlistener();
    }
  }

  ngAfterViewInit(): void {
    const loadpanelId = this.loadPanelService.generateUuid();
    this.loginService.getCurrentUser
      .pipe(
        switchMap(() => {
          if (typeof this.documentType === 'string') {
            return of(this.documentType);
          }
          return this.documentType;
        }),
        switchMap(type => {
          this.loadPanelService.showPanel(loadpanelId, 'Einstellungen laden...', 90, 220);
          this.saveState();
          this.resetState();
          if (type && (!this.#documentType || this.#documentType !== type)) {
            this.#documentType = type;
          }
          return this.configurationService.loadListStates();
        }),
        switchMap(() => this.listsService.getGlobalLabels()),
        switchMap(globalLabels => {
          this.labelsColumnHeaderFilter = sortBy(globalLabels, 'name').map(label => ({
            text: label.name,
            value: ['labels', 'contains', label.value],
            key: label.value
          }));
          return this.userService.getAllUsers();
        }),
        switchMap(() => {
          if (this.#documentType) {
            const currentState = this.configurationService.loadListState(this.#documentType) as IDynamicListState;
            if (currentState) {
              this.listState = currentState;
              this.gridComponent?.instance?.state(currentState.grid || null);
            }
            if (this.embeddedMode || this.personalMode) {
              this.listState.toolbar.personalMode = 2;
            }
            this.listState.documentType = this.#documentType;
            return this.listsService.getDynamicListByType(this.#documentType);
          }
          return of(null);
        }),
        tap(entry => {
          if (entry) {
            const info = entry.properties as IDynamicList;
            this.info = {
              tag: entry.value as string,
              icon: info.icon,
              name: entry.label,
              selector: info.selector
            };
            if (this.activatedRoute?.snapshot?.data['name'] === 'list') {
              const title = this.info.name || 'Ansicht';
              this.titleService.setTitle(`${title} | ${siamConst.appTitle}`);
            }

            BreadcrumbComponent.resetSubBreadcrumb();
            BreadcrumbComponent.addBreadcrumb(entry.label, ['', 'application', 'documents', this.#documentType]);

            if (!info.settings) {
              this.handleError('Tabellenkonfiguration ist nicht vorgesehen');
              return;
            }

            if (this.additionalSettings) {
              this.settings = { ...info.settings, ...this.additionalSettings };
            } else {
              this.settings = info.settings;
            }
            this.pageSize = (this.listState.grid?.pageSize as number) || this.settings.pageSize;
            this.dateRangeDataSource = this.settings?.toolbar?.dateFilterDataSource?.length
              ? PeriodDataSource.filter(p => this.settings?.toolbar?.dateFilterDataSource.includes(p.value))
              : PeriodDataSource;
            if (DynamicListComponent.isUndefined(this.listState.toolbar.selectedDateRange)) {
              this.listState.toolbar.selectedDateRange = this.getDefaultDateFilter();
            }

            this.#fields = ['tags', 'references'];
            void this.gridComponent?.instance?.beginUpdate();
            if (this.additionalSettings) {
              this.settings = { ...this.settings, ...this.additionalSettings };
            }

            // because here new document type, need to clear all the data from the data source,
            // it might be situation, when new columns setup apply for the old data source
            this.dataSource = new CustomStore({
              key: 'empty',
              load: () => lastValueFrom(of([]))
            });
            this.setColumns();
            this.loadPanelService.hidePanel(loadpanelId);
            this.doQuery();
            return;
          }
          const errMsg = `Eine im Dokumenttyp verwendete Ansicht
           '${this.#documentType}' wurde nicht gefunden. Bitte wenden Sie sich an Ihren Administrator.`;
          this.handleError(errMsg);
          this.logger.error(errMsg);
        }),
        takeUntil(this.#destroyable$)
      )
      .subscribe({
        error: (error: IError) => {
          this.emitter.emit({
            command: 'error',
            object: this.#loadingErrorMessage
          });
          this.logger.error(this.#loadingErrorMessage, error);
        }
      });

    this.#unlistener = this.renderer.listen('document', 'mousedown', (e: MouseEvent) => {
      if (
        !DynamicListComponent.getDataGridColumnChooserElement(e.target as HTMLElement, '.dx-datagrid-column-chooser')
      ) {
        this.gridComponent?.instance?.hideColumnChooser();
      }
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  createPermissionTarget(str: string): IPermissionTarget {
    return str ? Factory.createPermissionTarget(str) : null;
  }

  customizeHeaderFilterAssignee = (options: IDxDataGridOnCustomItemHeaderFilter<ILabelHeaderFilter[]>): void => {
    options.dataSource.postProcess = (results: ILabelHeaderFilter[]) => {
      const users: Record<'userName', string>[] = [];
      const roles: Record<'userName', string>[] = [];
      results.forEach(result => {
        const key = result.key;
        if (!Factory.isCompositeId(key)) {
          const labelFilter = {
            text: '[Leer]',
            userName: '',
            value: ['currentAssignee', '<>', '[Leer]'],
            key
          };
          users.push(labelFilter);
          return;
        }
        const target = this.createPermissionTarget(key);
        let text = '';
        let userName = '';
        let isUser = true;
        switch (target?.type) {
          case 'user':
            {
              const user = this.#users.get(target.targetId);
              if (user) {
                text = user.avatarName;
                userName = user.lastname;
              } else {
                text = '[gelöschter Benutzer]';
              }
            }
            break;

          case 'role':
            {
              const role = this.#roles.get(target.targetId);
              if (role) {
                text = role.avatarName;
                userName = role.lastname;
              } else {
                text = '[gelöschte Rolle]';
              }
              isUser = false;
            }
            break;
        }

        if (this.shouldInclude(userName)) {
          const labelFilter = {
            text: text || '[kein Eintrag]',
            userName: userName || '',
            value: ['currentAssignee', '=', key],
            key
          };
          if (isUser) {
            users.push(labelFilter);
          } else {
            roles.push(labelFilter);
          }
        }
      });
      return [].concat(...sortBy(users, 'userName'), ...sortBy(roles, 'userName')) as ILabelHeaderFilter[];
    };
  };

  customizeHeaderFilterCreator = (options: IDxDataGridOnCustomItemHeaderFilter<ILabelHeaderFilter[]>): void => {
    options.dataSource.postProcess = (results: ILabelHeaderFilter[]) =>
      sortBy(
        results
          .map(result => {
            const key = result.key;
            const user = this.#users.get(key);
            const userName = user?.name;
            if (this.shouldInclude(userName)) {
              return {
                text: user?.avatarName || (user ? '[kein Eintrag]' : '[gelöschter Benutzer]'),
                value: ['creatorNameWithAvatar', '=', key],
                key,
                userName
              };
            }
            return null;
          })
          .filter(entry => entry !== null),
        'userName'
      );
  };

  customizeHeaderFilterModification = (options: IDxDataGridOnCustomItemHeaderFilter<ILabelHeaderFilter[]>): void => {
    options.dataSource.postProcess = (results: ILabelHeaderFilter[]) =>
      sortBy(
        results
          .map(result => {
            const key = result.key;
            const user = this.#users.get(key);
            const userName = user?.name;
            if (this.shouldInclude(userName)) {
              return {
                text: user?.avatarName || (user ? '[kein Eintrag]' : '[gelöschter Benutzer]'),
                value: ['modificationNameWithAvatar', '=', key],
                key,
                userName
              };
            }
            return null;
          })
          .filter(entry => entry !== null),
        'userName'
      );
  };
  groupCellTemplateUser = async (
    element: HTMLElement,
    data: IDxDataGridCellTemplate<IDxGroupItem, string>
  ): Promise<void> => {
    const countText = `<span>&nbsp;(Anzahl: ${data.data.count})</span>`;
    let text = '';
    if (!data.value) {
      text = '[kein Eintrag]';
    } else if (data.value === '[Leer]') {
      text = '[Leer]';
    } else {
      if (Factory.checkIfValidUUID(data.value)) {
        const user = await lastValueFrom(
          this.nameDataPipe.transform({ type: 'user', targetId: data.value } as IPermissionTarget)
        );
        text = user.avatarName;
      } else if (Factory.isCompositeId(data.value)) {
        const permissionTarget = this.createPermissionTarget(data.value);
        if (permissionTarget) {
          const user = await lastValueFrom(this.nameDataPipe.transform(permissionTarget));
          text = user.avatarName;
        }
      }
    }
    element.innerHTML = `<div class="v-center">${text.concat(countText)}</div>`;
  };

  onClosePreview(): void {
    void this.gridComponent?.instance?.deselectAll();
    this.selectedDocument = null;
  }

  /**
   * Called on change date range in corresponding drop down list of toolbar
   *
   * @param e
   */
  onDateRange = (e: ItemClickEvent): void => {
    const itemData = e.itemData as IDxToolbarItem;
    this.onUpdateToolbarProperty('selectedDateRange', itemData.value);
    this.doQuery();
  };

  onEditorPreparing = (e: EditorPreparingEvent): void => {
    if (e.parentType === 'searchPanel') {
      (e as IDxEditorPreparingEvent).updateValueTimeout = 1000;
    }
  };

  /**
   * Data export to Excell
   *
   */
  onExporting(e: ExportingEvent): void {
    const dataGrid = e.component;
    const date = DateTime.now().toFormat('MM-dd-yyyy_HH-mm');
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(`Export_${this.info.name}-${date}`);
    const iconColumns = dataGrid?.getVisibleColumns().filter(column => column.cellTemplate === 'Icon');
    dataGrid.beginUpdate();
    this.setColumnVisibility(dataGrid, iconColumns, false);

    void exportDataGrid({
      component: dataGrid,
      worksheet,
      keepColumnWidths: false,
      loadPanel: { enabled: false },
      customizeCell: (options: IDxExcelExportOptions) => {
        const { gridCell, excelCell } = options;

        // sanitize all cell value if string
        if (typeof excelCell.value === 'string') {
          excelCell.value = DynamicListComponent.sanitizeHTML(excelCell.value);
        }

        // create paretn cell
        if (gridCell.rowType === 'data' && gridCell.column.dataField === 'parentDocuments') {
          const parentDocs = (gridCell.data as IDataSource).parentDocuments;

          if (!parentDocs.length) {
            excelCell.value = '';
          } else if (parentDocs.length === 1) {
            const parent = parentDocs[0];

            // create parent link
            const tmpTag = parent.parentDocumentTags.find(tag => tag.startsWith('app:document-type'));
            const docType = this.tagsService.getTypeName(tmpTag);
            const cellLink = `${this.baseUrl}application/documents/${docType}/${parent.parentDocumentId}`;

            excelCell.value = {
              text: this.setParentLabel(parent),
              hyperlink: cellLink
            };
          } else {
            const excelTextValue: string[] = [];
            for (const parent of parentDocs) {
              excelTextValue.push(this.setParentLabel(parent));
            }
            excelCell.value = excelTextValue.join(', ');
          }
        }
      }
    })
      .then(() => {
        this.autoColumnWidth(worksheet, 10);
        void workbook.xlsx.writeBuffer().then(buffer => {
          FS.saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Export_${this.info.name}-${date}.xlsx`);
        });
      })
      .then(() => {
        // show "Icon" column after export
        this.setColumnVisibility(dataGrid, iconColumns, true);
        dataGrid.endUpdate();
      });
    e.cancel = true;
  }

  onLabelsColumnFilter = (data: IDataSource): string => {
    if (data.labels && data.labels.length > 0) {
      const names = data.labels.map(label => label.name);
      return names.join(', ');
    }
    return '';
  };

  onOpenDocument(): void {
    let mode: TEditMode = 'ReadOnly';
    if (
      !(this.settings.isEditMode !== undefined && !this.settings.isEditMode) &&
      this.selectedDocument.effectivePermissions.indexOf('update') !== -1
    ) {
      mode = 'EditPreview';
    }
    this.helperService.openDocument(this.selectedDocument, mode);
  }

  onOptionChanged(e: OptionChangedEvent): void {
    if (e.name === 'columns') {
      if (e.fullName.endsWith('.visible')) {
        const regEx = /\d+/g;
        const matches = e.fullName.match(regEx);
        if (matches) {
          const column = this.gridComponent.columns[+matches[0]] as Column;
          const visibility = this.gridComponent.instance.columnOption(column.dataField, 'visible') as boolean;
          this.gridComponent.instance.columnOption(column.dataField, {
            allowSearch: visibility
          });
        }
      }
    }
  }

  onPersonalModeSwitch = (e: ItemClickEvent): void => {
    if (this.info) {
      const itemData = e.itemData as IDxToolbarItem;
      this.onUpdateToolbarProperty('personalMode', itemData.value);
      this.doQuery();
    }
  };

  onPreviewSwitch = (e: ValueChangedEvent): void => {
    this.onUpdateToolbarProperty('isPreviewEnabled', !!e.value);
  };

  onRefresh = (): void => {
    if (this.info) {
      void this.gridComponent?.instance?.deselectAll();
      this.doQuery();
    }
  };

  onResetFilter = (): void => {
    const instance = this.gridComponent?.instance;
    void instance?.clearGrouping();
    void instance?.clearFilter();

    this.listState.toolbar = {
      selectedDateRange: this.getDefaultDateFilter(),
      isPreviewEnabled: false,
      personalMode: this.personalMode ? 2 : 1,
      currentMode: 1
    };

    this.listState.grid = null;

    this.setColumns();
    this.updateDataGrid();
    this.saveState();
    this.doQuery();
  };

  /**
   * on click select the current document
   *
   * @param event devextreme event
   */
  onSelectDocument(event: SelectionChangedEvent<IDocument, IDocument>): void {
    const keys = event.selectedRowKeys;

    switch (this.selectionMode) {
      case 'single':
        {
          if (!keys.length) {
            return;
          }
          this.selectedDocument = null;
          const selectedDocument = keys[0];
          if (!this.listState.toolbar.isPreviewEnabled) {
            let editMode: TEditMode = 'Edit';
            if (this.settings.isEditMode !== undefined && !this.settings.isEditMode) {
              editMode = 'ReadOnly';
            }
            this.helperService.openDocument(selectedDocument, editMode);
          } else {
            // for preview need to load document from DB
            this.documentService
              .getDocumentById(selectedDocument.id)
              .pipe(takeUntil(this.#destroyable$))
              .subscribe(document => {
                this.selectedDocument = document;
              });
          }
        }
        break;
      case 'multiple':
        {
          this.emitter.emit({ command: 'selection', object: keys });
        }
        break;

      default:
        break;
    }
  }

  async onShowToolTipColumn(e: CellHoverChangedEvent<IDataSource>): Promise<void> {
    if (
      e.rowType === 'data' &&
      e.eventType === 'mouseover' &&
      (e.column.dataField === 'creatorNameWithAvatar' || e.column.dataField === 'modificationNameWithAvatar')
    ) {
      const target = e.cellElement;
      if (target.firstElementChild.matches('[data-user-id]')) {
        this.timer = this.timeout();
        let logonUserInfo = '';
        const user = this.#users.get((target?.firstElementChild as HTMLElement)?.dataset?.userId);
        if (e.data.document.creation.userId !== e.data.document.creation.logonUserId) {
          const logonUser = this.#users.get(e.data.document.creation.logonUserId);
          logonUserInfo = `* Vertretung durch (${logonUser.fullName})`;
        }
        this.popoverContent = `<div class="tooltip-user-info"><div class="tooltip-user-info__avatar" style="background-image:url(${
          user.avatarUrl
        })"></div><div><div><strong>${user.fullName.trim() ? user.fullName : user.name}</strong></div><div>(${
          user.loginName
        })</div><div>${user.department}</div></div></div><div>${logonUserInfo}</div>`;
        this.popoverElement = target;
        await this.timer.start();
        await this.popOverCreationColumn?.instance?.show();
      }
    }

    if (e.rowType === 'data' && e.eventType === 'mouseover' && e.column.dataField === 'currentAssignee') {
      const target = e.cellElement;
      if (target?.firstElementChild?.matches('[data-user-id]')) {
        this.timer = this.timeout();
        const user = this.#users.get((target.firstElementChild as HTMLElement).dataset?.userId);
        this.popoverContent = `<div class="tooltip-user-info"><div class="tooltip-user-info__avatar" style="background-image:url(${
          user.avatarUrl
        })"></div><div><div><strong>${user.fullName.trim() ? user.fullName : user.name}</strong></div><div>(${
          user.loginName
        })</div><div>${user.department}</div></div></div>`;
        this.popoverElement = target;
        await this.timer.start();
        await this.popOverCreationColumn?.instance?.show();
      }
    }
    if (e.rowType === 'data' && e.eventType === 'mouseover' && e.column.dataField === 'decision.fields.number') {
      const target = e.cellElement;
      if (target?.firstElementChild?.matches('[data-decision-name]')) {
        for (const child of Array.from(target.children)) {
          child.addEventListener('mouseover', () => {
            void this.zone.run(async () => {
              const decisionName = (child as HTMLElement).dataset?.decisionName;
              const decisionValue = e.data.document?.fields[siamConst.decisionField]?.value as FieldDecisionsValue;
              const decision = decisionValue[decisionName];
              if (decision) {
                this.popoverContent = `<div><span><b>Beschlusskategorie:</b> ${decision.caption}</span>
            <span><b>Status:</b> ${this.documentService.getDecisionStatusCaption(decision.status)}</span>
            <span><b>Erstellt in:</b> ${this.documentService.getDecisionCreationSource(decision)}</span></div>`;
              }
              this.timer = this.timeout();
              this.popoverElement = child as HTMLElement;
              await this.timer.start();
              await this.popOverCreationColumn?.instance?.show();
            });
          });
          child.addEventListener('mouseleave', () => {
            void this.zone.run(async () => {
              this.timer?.abort();
              this.timer = null;
              await this.popOverCreationColumn?.instance?.hide();
            });
          });
        }
      }
    }

    if (e.rowType === 'data' && e.eventType === 'mouseout') {
      if (this.timer) {
        this.timer?.abort();
        this.timer = null;
        await this.popOverCreationColumn?.instance?.hide();
      }
    }
  }

  /**
   * Autofit columns by width
   *
   * @param worksheet {Worksheet}
   * @param minimalWidth
   */
  private autoColumnWidth = (worksheet: Worksheet, minimalWidth: number): void => {
    worksheet?.columns?.forEach(column => {
      let maxColumnLength = 0;
      column.eachCell({ includeEmpty: true }, cell => {
        if (typeof cell.value === 'string') {
          maxColumnLength = Math.max(maxColumnLength, minimalWidth, cell.value ? cell.value.toString().length : 0);
        } else {
          maxColumnLength = Math.max(
            maxColumnLength,
            minimalWidth,
            (cell.value as CellHyperlinkValue)?.text ? (cell.value as CellHyperlinkValue)?.text.toString().length : 0
          );
        }
      });
      column.width = maxColumnLength + 2;
    });
  };

  /**
   * Prepare and doing the query of documents
   *
   * @private
   */
  private doQuery(): void {
    const loadpanelId = this.loadPanelService.generateUuid();

    this.#doNotQuery = false;
    // exclude Muster Documente für alle Ansichten
    const excludeTags = this.settings.excludeTags?.length
      ? ([] as string[]).concat(this.settings.excludeTags, siamConst.patternTag)
      : [siamConst.patternTag];
    const properties: IDocumentsSearch = {
      tag: this.info.tag,
      documentFields: [...new Set(this.#fields)],
      isIncludeLists: true,
      includeTags: this.settings.includeTags,
      excludeTags,
      includeOneOfTags: this.settings.includeOneOfTags,
      includeOneOfTemplateId: this.settings.includeOneOfTemplateId,
      excludeDocumentIds: this.settings.excludeDocumentIds,
      includeDocumentIds: this.settings.includeDocumentIds,
      excludeChildIds: this.settings.excludeChildIds,
      excludeParentIds: this.settings.excludeParentIds,
      includeParentIds: this.settings.includeParentIds,
      includeChildIds: this.settings.includeChildIds,
      excludeStatus: this.settings.excludeStatus,
      hasNoChild: this.settings.hasNoChild,
      hasChild: this.settings.hasChild,
      hasNoParent: this.settings.hasNoParent,
      hasParent: this.settings.hasParent,
      hasParentTag: this.settings.hasParentTag,
      hasChildTag: this.settings.hasChildTag
    };

    if (this.isPersonalMode) {
      properties.user = getCurrentUser();
      properties.userField = this.settings.toolbar.userField || 'creation.user.id';
    }

    let selectedDateRange: TCardPeriod = 'all';
    if (this.embeddedMode && this.personalMode) {
      selectedDateRange = 'nextThirtyDays';
    } else if (this.settings.toolbar.isShowDateFilter) {
      selectedDateRange = this.listState.toolbar.selectedDateRange || 'all';
    }

    if (selectedDateRange === 'all' && !this.isPersonalMode) {
      // nothing to do
    } else if (selectedDateRange !== 'all') {
      const dateRange = getDateRange(selectedDateRange);
      properties.dateFrom = dateRange.startDate;
      properties.dateTo = dateRange.endDate;
      properties.dateField = this.settings.toolbar.dateField || null;
    }

    this.dataSource = new CustomStore({
      key: 'document',
      load: (options): Promise<unknown> => {
        /*
         * It seems like CustomStore makes load every time state of the grid is changed. We need to prevent it by
         * returning empty object
         */
        if (this.#doNotQuery) {
          return lastValueFrom(
            of({
              data: [],
              totalCount: 0,
              summary: [0]
            })
          );
        }
        const adapter = queryAdapter(this.getGroupField);
        this.loadPanelService.showPanel(loadpanelId, this.#loadingMessage, 90, 220);
        properties.skip = options.skip;
        properties.count = options.take;
        const fields: string[] = [];

        if (Array.isArray(options.group) && options.group.length) {
          for (const group of options.group as ISortColumn[]) {
            fields.push(group.selector);
          }
        }

        if (Array.isArray(options.sort) && options.sort.length) {
          properties.sortFields = {};
          for (const sort of options.sort as ISortColumn[]) {
            fields.push(sort.selector);

            switch (sort.selector) {
              case 'creatorNameWithAvatar':
                {
                  properties.sortFields['creation.user.surname'] = { desc: sort.desc };
                  properties.sortFields['creation.user.forename'] = { desc: sort.desc };
                  properties.sortFields['creation.user.name'] = { desc: sort.desc };
                }
                break;

              case 'modificationNameWithAvatar':
                {
                  properties.sortFields['last_write.user.surname'] = { desc: sort.desc };
                  properties.sortFields['last_write.user.forename'] = { desc: sort.desc };
                  properties.sortFields['last_write.user.name'] = { desc: sort.desc };
                }
                break;

              default:
                {
                  const sortField = this.getGroupField(sort.selector);
                  if (sortField) {
                    properties.sortFields[sortField] = { desc: sort.desc };
                  }
                }
                break;
            }
          }
        }

        extractFieldFromFilter(options.filter as TFilterGrid, fields);
        properties.query = combineQueries(adapter(this.info.selector), adapter(options.filter as TFilterGrid), 'and');

        const descriptors: Record<string, string> = {};
        const fieldConfiguration: IDocumentSearchFieldConfiguration[] = [];
        new Set<string>(fields).forEach(field => {
          const column = (this.gridComponent?.columns as IDynamicGridSettingsColumn[]).find(c => c.dataField === field);
          let typeDescriptor = column?.dataType;
          if (typeDescriptor === 'object') {
            typeDescriptor = (column?.choices[0]?.properties as Record<string, string>)?.dataType as DataType;
          }

          switch (field) {
            case 'creatorNameWithAvatar':
              {
                ['creation.user.surname', 'creation.user.forename', 'creation.user.name'].forEach(fieldName => {
                  fieldConfiguration.push({ fieldName, typeDescriptor: 'text' });
                });
                descriptors[field] = 'text';
              }
              break;

            case 'modificationNameWithAvatar':
              {
                ['last_write.user.surname', 'last_write.user.forename', 'last_write.user.name'].forEach(fieldName => {
                  fieldConfiguration.push({ fieldName, typeDescriptor: 'text' });
                });
                descriptors[field] = 'text';
              }
              break;

            default: {
              const fieldName = this.getGroupField(field);
              if (fieldName) {
                fieldConfiguration.push({ fieldName, typeDescriptor: convertDevexpressDataType(typeDescriptor) });
                descriptors[fieldName] = convertDevexpressDataType(typeDescriptor, true);
              }
            }
          }
        });

        if (fieldConfiguration.length) {
          properties.fieldConfiguration = fieldConfiguration;
        }

        let stream$: Observable<unknown>;
        if (Array.isArray(options.group) && options.group.length) {
          // Virtualscrolling für Groups ignorieren da Server liefert alle Daten immer zurück
          if (options.skip > 0) {
            stream$ = of(null);
          } else {
            properties.groupFields = [];
            (options.group as ISortColumn[]).forEach(group => {
              const field = this.getGroupField(group.selector);
              if (field) {
                properties.groupFields.push({
                  path: field,
                  descending: group.desc,
                  sortType: descriptors[field] || 'text'
                });
              }
            });
            stream$ = this.fetchUsersAndRoles().pipe(
              switchMap(() => this.documentService.documentsSearchGroup(properties)),
              map(data => {
                this.setPagerOptions(data?.totalCount);
                this.#firstValue = extractFirstValue(options.filter as TFilterGrid) as string;
                return data;
              })
            );
          }
        } else {
          stream$ = this.fetchUsersAndRoles().pipe(
            switchMap(() => this.documentService.documentsSearch(properties)),
            map(response => {
              this.#lists = response.lists;
              return response;
            }),
            switchMap(response => zip(this.setDataSource(response.documents), of(response))),
            map(tuple => ({ response: tuple[1], dataSource: tuple[0] })),
            map(data => {
              const totalCount = data.response.totalCount;
              if (
                (isHasProperty(options, 'requireTotalCount') && options.requireTotalCount) ||
                !isHasProperty(options, 'requireTotalCount')
              ) {
                this.setPagerOptions(totalCount);
              }
              return {
                data: data.dataSource,
                totalCount,
                summary: [totalCount]
              };
            })
          );
        }
        return lastValueFrom(stream$);
      },
      onRemoving: (key: IDocument): void => {
        if (key) {
          this.emitter.emit({ command: 'delete', object: key });
        }
      },
      remove: (): Promise<void> => new Promise(resolve => resolve()),
      onLoaded: () => {
        this.loadPanelService.hidePanel(loadpanelId);
      },
      errorHandler: (error: string) => {
        this.logger.error(`Fehler in Dynamische DataGrid:\n {@error}`, JSON.stringify(error));
        this.handleError(error);
      }
    });
  }

  private extractFieldName = (fieldName: string): string => {
    if (fieldName?.includes('fields.')) {
      return fieldName.split('.')[1];
    }
    return null;
  };

  private extractValue(list: SiamListItem[], value: string | string[], column: IDynamicGridSettingsColumn): string {
    if (!list) {
      return null;
    }

    if (Array.isArray(value)) {
      const result: string[] = [];
      for (const val of value) {
        const item = list?.find(i => i.value === val);
        result.push(item?.label);
      }
      return column?.isBreakWord
        ? result.filter(item => !!item).join('\r\n')
        : result.filter(item => !!item).join(', ');
    }
    return list.find(item => item.value === value)?.label;
  }

  private fetchUsersAndRoles(): Observable<void> {
    return this.userService.getAllUsers().pipe(
      switchMap(entries => {
        const promises: Promise<IUserPipe>[] = [];
        for (const entry of entries) {
          promises.push(lastValueFrom(this.nameDataPipe.transform(entry)));
        }
        return Promise.all(promises);
      }),
      switchMap(entries => {
        this.#users = new Map();
        entries.forEach(entry => {
          this.#users.set(entry.id, entry);
        });
        return this.roleService.getAllRoles();
      }),
      switchMap(entries => {
        const promises: Promise<IUserPipe>[] = [];

        for (const entry of entries) {
          promises.push(lastValueFrom(this.nameDataPipe.transform(entry)));
        }
        return Promise.all(promises);
      }),
      switchMap(entries => {
        this.#roles = new Map();
        entries.forEach(entry => {
          this.#roles.set(entry.id, entry);
        });

        return of(null);
      })
    );
  }

  private getColumnOptions(column: IDynamicGridSettingsColumn): IDynamicGridSettingsColumn {
    const width = column.isAutoWidth ? 'auto' : column.width || null;
    const cssClass = column.isBreakWord ? 'column-break-word' : null;
    const gridSelector = this.gridComponent?.instance?.element();
    const columnMinWidth = getStringLength(column.caption, gridSelector, 'dx-datagrid-headers') + 50;

    switch (column.templateName) {
      /* zurzeit Gruppierung und Sortierung für Spalte Label sperren */
      case 'labels': {
        this.#templates.showLabels = true;
        return this.setProperties(column, {
          allowGrouping: false,
          allowSorting: false,
          cellTemplate: column.isBreakWord ? 'Labels in column' : 'Labels',
          calculateCellValue: this.onLabelsColumnFilter,
          dataField: 'labels',
          width,
          minWidth: columnMinWidth,
          headerFilter: {
            dataSource: this.labelsColumnHeaderFilter
          }
        });
      }

      /* zurzeit Gruppierung, Filterung und Sortierung für Spalte Icon sperren */
      case 'icon': {
        this.#templates.showIcon = true;
        return {
          allowGrouping: false,
          allowSorting: false,
          allowSearch: false,
          allowHeaderFiltering: false,
          caption: '',
          width: '40px',
          cellTemplate: 'Icon',
          allowHiding: false,
          showInColumnChooser: false
        };
      }

      /* zurzeit Gruppierung, Filterung und Sortierung für Spalte Abteilung des Erstellers sperren */
      case 'creatorDepartment': {
        this.#fields.push('creation.user.profile.department');
        this.#templates.showCreatorDepartment = true;
        return this.setProperties(column, {
          allowGrouping: false,
          allowSorting: false,
          allowHeaderFiltering: false,
          dataField: 'creatorDepartment',
          width,
          minWidth: columnMinWidth
        });
      }

      case 'creatorName': {
        this.#fields.push('creation.userId');
        this.#templates.showCreatorName = true;
        return this.setProperties(column, {
          dataField: 'creatorNameWithAvatar',
          encodeHtml: false,
          width,
          minWidth: columnMinWidth,
          headerFilter: {
            allowSearch: true,
            dataSource: this.customizeHeaderFilterCreator
          },
          groupCellTemplate: this.groupCellTemplateUser
        });
      }

      /* zurzeit Gruppierung, Filterung und Sortierung für Spalte Bezug sperren */
      case 'parentDocuments': {
        this.#templates.showParentDocuments = true;
        return this.setProperties(column, {
          allowGrouping: true,
          allowSorting: false,
          allowHeaderFiltering: false,
          allowSearch: false,
          dataField: 'parentDocuments',
          cellTemplate: 'parentDocumentsTemplate',
          width,
          minWidth: columnMinWidth
        });
      }
      case 'childDocuments': {
        this.#templates.showChildDocuments = true;
        return this.setProperties(column, {
          allowGrouping: true,
          allowSorting: false,
          allowHeaderFiltering: false,
          allowSearch: false,
          dataField: 'childDocuments',
          cellTemplate: 'childDocumentsTemplate',
          width,
          minWidth: columnMinWidth
        });
      }

      case 'modificationName': {
        this.#fields.push('last_write.userId');
        this.#templates.showModificationName = true;
        return this.setProperties(column, {
          dataField: 'modificationNameWithAvatar',
          encodeHtml: false,
          width,
          minWidth: columnMinWidth,
          headerFilter: {
            dataSource: this.customizeHeaderFilterModification
          },
          groupCellTemplate: this.groupCellTemplateUser
        });
      }

      case 'creationDate': {
        this.#fields.push('creation.timestamp');
        return this.setProperties(column, {
          dataField: 'document.creation.timestamp',
          dataType: 'date',
          format: column.format || 'dd.MM.yyyy',
          sortOrder: column.sortOrder,
          width,
          minWidth: columnMinWidth,
          allowSorting: true,
          allowHeaderFiltering: false,
          allowGrouping: false
        });
      }

      case 'modificationDate': {
        this.#fields.push('modification.timestamp');
        return this.setProperties(column, {
          dataField: 'document.modification.timestamp',
          dataType: 'date',
          format: column.format || 'dd.MM.yyyy',
          sortOrder: column.sortOrder,
          width,
          minWidth: columnMinWidth,
          allowSorting: true,
          allowHeaderFiltering: false,
          allowGrouping: false
        });
      }

      /* zurzeit Gruppierung, Filterung und Sortierung für Spalte Fortsrittbalken sperren */
      case 'progressBar': {
        this.#templates.showProgress = true;
        return this.setProperties(column, {
          allowGrouping: false,
          allowSorting: false,
          allowHeaderFiltering: false,
          allowSearch: false,
          cellTemplate: 'Progress',
          dataField: 'progressBar',
          width,
          minWidth: columnMinWidth
        });
      }

      case 'type': {
        this.#fields.push('template.caption');
        return this.setProperties(column, {
          dataField: 'document.template.caption',
          width,
          minWidth: columnMinWidth
        });
      }

      case 'workflowStatus': {
        this.#fields.push('documentWorkflowDocument.currentStatusLabel');
        this.#templates.showWorkflowStatus = true;
        this.#templates.showWorkflowColor = column.showStatusColor;
        return this.setProperties(column, {
          dataField: 'status',
          cellTemplate: column.showStatusColor ? 'workflow-status' : null,
          width,
          minWidth: columnMinWidth,
          filterValues:
            column.allowHeaderFiltering && column.defaultStatusValue?.length ? [column.defaultStatusValue] : []
        });
      }

      case 'currentAssignee': {
        this.#fields.push('documentWorkflowDocument.currentAssignee');
        this.#templates.showCurrentAssignee = true;
        return this.setProperties(column, {
          dataField: 'currentAssignee',
          width,
          cssClass: column.isBreakWord ? 'column-break-flex' : null,
          encodeHtml: false,
          minWidth: columnMinWidth,
          headerFilter: {
            allowSearch: true,
            dataSource: this.customizeHeaderFilterAssignee
          },
          groupCellTemplate: this.groupCellTemplateUser
        });
      }
      case 'decisionData': {
        const dataField = `fields.${siamConst.decisionField}`;
        this.#fields.push(dataField);
        this.#templates.showDecisionData = true;
        const decisionField = column.decisionField as TDecisionFields;
        const getDataFieldDecision = (): string => {
          if (decisionField === 'decision') {
            this.#templates.showDecisionApprovalType = true;
          }
          switch (decisionField) {
            case 'category':
              return 'decision.category';
            default:
              return `decision.fields.${decisionField}`;
          }
        };
        const options = this.setProperties(column, {
          allowGrouping: false,
          allowHeaderFiltering:
            column.allowHeaderFiltering && (column.decisionField === 'category' || column.decisionField === 'decision'),
          headerFilter: {
            dataSource:
              column.decisionField === 'category'
                ? this.decisionColumnHeaderFilterDataStore
                : column.decisionField === 'decision'
                ? this.decisionTypeColumnHeaderFilterDataStore
                : null
          },
          encodeHtml: false,
          dataField: getDataFieldDecision(),
          width,
          minWidth: columnMinWidth,
          calculateCellValue: (data: IDataSource): unknown => {
            const getTempValue = (field: string): string => {
              const parts = field.split('.');
              let temporaryValue: unknown = data.document;
              for (const part of parts) {
                if (isHasProperty(temporaryValue, part)) {
                  temporaryValue = (temporaryValue as Record<string, unknown>)[part];
                } else {
                  temporaryValue = null;
                  break;
                }
              }
              return temporaryValue as string;
            };
            const fieldDecisionName = column.decisionField === 'category' ? 'caption' : column.decisionField;
            const CategoryDecisionNames = column.decisionCategory;
            const result: string[] = [];
            const decisionField = `fields.${siamConst.decisionField}.value`;
            const decisionFieldvalue = getTempValue(decisionField) as unknown as FieldDecisionsValue;
            if (!decisionFieldvalue) {
              return '';
            }
            const documentCategories = Object.keys(decisionFieldvalue);
            for (const CategoryDecisionName of documentCategories) {
              if (!CategoryDecisionNames?.includes(CategoryDecisionName)) {
                result.push('');
                continue;
              }
              const decisionDataField = `fields.${siamConst.decisionField}.value.${CategoryDecisionName}.${fieldDecisionName}`;
              const decisionStatus = `fields.${siamConst.decisionField}.value.${CategoryDecisionName}.status`;
              const decisionColor = `fields.${siamConst.decisionField}.value.${CategoryDecisionName}.fields.decisionColor.value`;
              const decisionComment = `fields.${siamConst.decisionField}.value.${CategoryDecisionName}.fields.comment.value`;
              const decisionStatusValue = getTempValue(decisionStatus) as TDecisionActionType;

              if (decisionStatusValue === 'deleted') {
                result.push('');
                continue;
              }
              const itemTemplate = (item: string) => {
                if (column.decisionField === 'number') {
                  const color = getTempValue(decisionColor) || _defaultDecisionColor;
                  const status = getTempValue(decisionStatus);
                  const isActive = status === 'final';
                  const opacity = isActive ? '1' : '0.5';
                  return `<div data-decision-name="${CategoryDecisionName}" class="dynamic-list-decision-badge" style="background-color: ${color}; opacity: ${opacity}"> <i class="material-icons md-18">bookmark</i>
                  <span>${item}</span></div>`;
                } else {
                  return `<div class="dynamic-list-decision-column">${item}</div>`;
                }
              };
              switch (column.decisionField as TDecisionFields) {
                case 'decision':
                  {
                    const categoryIdField = `fields.${siamConst.decisionField}.value.${CategoryDecisionName}.decisionChoicesId`;
                    const listValue = this.extractValue(
                      this.#approvalLists[getTempValue(categoryIdField)]?.entries,
                      getTempValue(decisionDataField),
                      column
                    );
                    const item = listValue || getTempValue(decisionDataField);
                    if (item) {
                      result.push(itemTemplate(item));
                    }
                  }
                  break;
                case 'comment':
                  {
                    const item = getTempValue(decisionComment);
                    if (item) {
                      result.push(itemTemplate(item));
                    }
                  }

                  break;
                case 'status':
                  {
                    const item = getTempValue(decisionStatus);
                    const statusCaption = this.documentService.getDecisionStatusCaption(item as TDecisionActionType);
                    if (statusCaption) {
                      result.push(itemTemplate(statusCaption));
                    }
                  }

                  break;
                case 'date':
                  {
                    const item = getTempValue(decisionDataField);
                    if (item) {
                      const dateValue = dateToLocal(item, (column.format as string) || 'dd.MM.yyyy');
                      result.push(itemTemplate(dateValue));
                    }
                  }

                  break;
                default:
                  {
                    const item = getTempValue(decisionDataField);
                    if (item) {
                      result.push(itemTemplate(item));
                    }
                  }
                  break;
              }
            }
            return result.filter(item => !!item).join(' ');
          }
        });
        if (decisionField === 'date') {
          options.dataType = 'datetime';
          options.format = column.format || 'dd.MM.yyyy';
        }

        return options;
      }
      default: {
        const fieldName = this.extractFieldName(column.dataField);
        const dataType = column.dataType || 'string';
        let dataField = `document.${column.dataField}`;

        if (column.dataField?.includes('fields.')) {
          if (column.showAttachment && column.attachmentField) {
            this.#attachmentFields.push(this.extractFieldName(column.attachmentField));
            this.#fields.push(column.attachmentField);
          }
          this.#fields.push(column.dataField);
        }

        if (fieldName) {
          dataField += '.value';
        }

        const options = this.setProperties(column, {
          width,
          minWidth: columnMinWidth,
          dataField,
          dataType,
          cssClass,
          encodeHtml: false,
          calculateCellValue: (data: IDataSource): unknown => {
            let field: IDocumentField;
            // document can be without fields
            if (data.document?.fields) {
              field = data.document.fields[fieldName];
            }

            if (!field) {
              // probably it is document's property value
              const parts = column.dataField.split('.');
              let temporaryValue: unknown = data.document;
              for (const part of parts) {
                if (isHasProperty(temporaryValue, part)) {
                  temporaryValue = (temporaryValue as Record<string, unknown>)[part];
                } else {
                  temporaryValue = null;
                  break;
                }
              }
              if (temporaryValue) {
                switch (column.dataType) {
                  case 'datetime':
                    return new Date(temporaryValue as string);

                  case 'number':
                    return Number(temporaryValue as string);

                  case 'boolean':
                    return Boolean(temporaryValue as string);

                  default:
                    return temporaryValue;
                }
              }
              return null;
            }

            const value = field.value as unknown;

            if (
              field.type !== 'double' &&
              field.type !== 'decimal' &&
              (null === value || DynamicListComponent.isUndefined(value))
            ) {
              return '';
            }

            // any type of field which value is the list's value
            if (field.listName) {
              return this.extractValue(this.#lists[field.listName]?.entries, value as string[], column) || value;
            }

            switch (field.type) {
              case 'boolean':
                return value;

              case 'timeStamp':
                return new Date(value as string);
              case 'json':
              case 'text':
                if (column.dataType === 'object' && column.choices?.length) {
                  return this.extractValue(column.choices, value as string[], column);
                } else {
                  return value as string;
                }

              case 'double':
              case 'decimal':
                return Number(value);

              case 'references': {
                if (!Array.isArray(value)) {
                  return '';
                }

                const result: string[] = [];
                let entry: IUserPipe;
                for (const val of value as IPermissionTarget[]) {
                  switch (val.type) {
                    case 'user':
                      entry = this.#users.get(val.targetId);
                      break;

                    case 'role':
                      entry = this.#roles.get(val.targetId);
                      break;
                  }

                  if (entry) {
                    result.push(entry.avatarName);
                  }
                }
                return column.isBreakWord ? result.join('\r\n') : result.join('\u2002');
              }

              default:
                return value;
            }
          },
          headerFilter: {
            allowSearch: true,
            dataSource: (customHeader: IDxDataGridOnCustomItemHeaderFilter<ILabelHeaderFilter[]>): void => {
              customHeader.dataSource.postProcess = (results: ILabelHeaderFilter[]) => {
                const users: Record<string, string | string[]>[] = [];
                const roles: Record<string, string | string[]>[] = [];
                const isUserRole = column.dataType === 'userRole';
                const sortProperty = isUserRole ? 'userName' : 'text';

                results.forEach(result => {
                  let text = '';
                  let userName: string;
                  const key = result.key;
                  let isUser = true;
                  if (isUserRole) {
                    if (!Factory.isCompositeId(key)) {
                      const labelFilter = {
                        text: '[Leer]',
                        userName: '',
                        value: [dataField, '<>', '[Leer]'],
                        key
                      };
                      users.push(labelFilter);
                      return;
                    }
                    const target = this.createPermissionTarget(key);
                    switch (target?.type) {
                      case 'user':
                        {
                          const user = this.#users.get(target.targetId);
                          if (user) {
                            text = user.avatarName;
                            userName = user.lastname;
                          } else {
                            text = '[gelöschter Benutzer]';
                          }
                        }
                        break;

                      case 'role':
                        {
                          const role = this.#roles.get(target.targetId);
                          if (role) {
                            text = role.avatarName;
                            userName = role.name;
                          } else {
                            text = '[gelöschte Rolle]';
                          }
                          isUser = false;
                        }
                        break;
                    }
                  } else {
                    if (column.choicesName && this.#lists[column.choicesName]?.entries) {
                      text = this.extractValue(this.#lists[column.choicesName]?.entries, key, column) || key;
                    } else if (column.choices?.length) {
                      text = this.extractValue(column.choices, key, column) || key;
                    } else if (!column.choices?.length) {
                      text = key;
                    }
                  }

                  if (this.shouldInclude(userName)) {
                    const labelFilter = {
                      text: text || '[kein Eintrag]',
                      userName: userName || '',
                      value: [dataField, '=', key],
                      key
                    };
                    if (isUser) {
                      users.push(labelFilter);
                    } else {
                      roles.push(labelFilter);
                    }
                  }
                });
                return [].concat(
                  ...sortBy(users as Record<string, string>[], sortProperty),
                  ...sortBy(roles as Record<string, string>[], sortProperty)
                ) as ILabelHeaderFilter[];
              };
            }
          },
          groupCellTemplate: async (
            element: HTMLElement,
            data: IDxDataGridCellTemplate<IDxGroupItem, string>
          ): Promise<void> => {
            const countText = `<span>&nbsp;(Anzahl: ${data.data.count})</span>`;
            const dataColumn = data.column as unknown as IDynamicGridSettingsColumn;
            let displayValue = data.value;
            if (!data.value) {
              displayValue = '[kein Eintrag]';
            }
            if (Factory.isCompositeId(data.value)) {
              const permissionTarget = this.createPermissionTarget(data.value);
              if (permissionTarget) {
                const user = await lastValueFrom(this.nameDataPipe.transform(permissionTarget));
                displayValue = user.avatarName;
              }
            } else {
              if (dataColumn.choicesName) {
                if (this.#lists[dataColumn.choicesName]?.entries) {
                  const item = this.extractValue(this.#lists[dataColumn.choicesName]?.entries, data.value, dataColumn);
                  if (item) {
                    displayValue = item;
                  }
                } else {
                  if (dataColumn.choices) {
                    const item = dataColumn.choices.find(c => c.value === data.value);
                    if (item) {
                      displayValue = item.label;
                    }
                  }
                }
              }
              if ((column.dataType as TDynamicGridColumnDataType) === 'userRole') {
                const permissionTarget = this.createPermissionTarget(data.value);
                if (permissionTarget) {
                  const user = await lastValueFrom(this.nameDataPipe.transform(permissionTarget));
                  displayValue = user.avatarName;
                }
              }
            }
            element.innerHTML = `<div class="v-center">${displayValue.concat(countText)}</div>`;
          }
        });

        if (column.showAttachment) {
          options.cellTemplate = 'Attachment';
        }
        if (column.columnType === 'dxSlider') {
          options.cellTemplate = 'ProgressField';
        }

        switch (dataType) {
          case 'datetime':
            options.format = 'dd.MM.yyyy';
          /* falls through */
          case 'number':
            {
              if (column.format) {
                options.format = column.format;
              }
            }
            break;
          default:
        }
        return options;
      }
    }
  }

  private getDefaultDateFilter(): TCardPeriod {
    let selectedDateRange = this.dateRangeValues[1].value;
    if (this.settings.toolbar.isShowDateFilter) {
      if (!DynamicListComponent.isUndefined(this.settings.toolbar.dateFilterDefault)) {
        selectedDateRange = this.settings.toolbar.dateFilterDefault;
        if (typeof selectedDateRange === 'number') {
          switch (selectedDateRange) {
            case 0:
              return 'currentMonth';
            case 3:
              return 'previousThreeMonth';
            case 6:
              return 'previousSixMonth';
            case 12:
              return 'currentYear';
            case -1:
              return 'all';

            default:
              break;
          }
        }
      }
    }
    return selectedDateRange;
  }

  private getDocumentsByIds(ids: string[]): Observable<IDocument[]> {
    if (!ids.length) {
      return of([] as IDocument[]);
    }

    // not more than 1024 OR conditions per request is allowed
    // every ID generate 2 OR conditions, that's why step should be MAX ALLOWED / 2
    const step = 500;
    let batch = step;
    const uniqueIds = Array.from(new Set(ids));
    const streams$: Observable<IDocument[]>[] = [];
    const length = uniqueIds.length;

    for (let i = 0; i < length; i += step) {
      const part = uniqueIds.slice(i, batch);

      const query = part
        .map(id => {
          const _id = JSON.stringify(id);
          return `references.child:${_id} || references.parent:${_id}`;
        })
        .join(' || ');
      const search: ISearch = {
        query,
        resultConfiguration: {
          selectedFields: ['fields.subject', 'template.caption', 'tags', 'documentWorkflowDocument.currentStatus']
        }
      };
      streams$.push(this.documentService.searchDocuments(search).pipe(map(data => data.documents)));

      batch += step;
    }
    return concat(...streams$);
  }

  private getGroupField = (sortSelector: string): string => {
    switch (sortSelector) {
      case 'creatorNameWithAvatar':
        return 'creation.userId';

      case 'creation.user.surname':
      case 'creation.user.forename':
        return sortSelector;

      case 'modificationNameWithAvatar':
        return 'last_write.userId';

      case 'currentAssignee':
        return 'documentWorkflowDocument.currentAssignee';

      case 'creatorDepartment':
        return 'creation.user.profile.department';

      case 'creationDate':
        return 'creation.timestamp';

      case 'modificationDate':
        return 'modification.timestamp';

      case 'status':
        return 'documentWorkflowDocument.currentStatusLabel';

      case 'labels':
      case 'configuration-tag':
      case 'configuration-label':
        return 'tags';

      case 'configuration-parent-tag':
        return 'references.parent.tags';
      case 'configuration-child-tag':
        return 'references.child.tags';
      case 'configuration-confidential':
        return 'confidentialId';

      case 'configuration-template':
        return 'template.id';

      default: {
        if (sortSelector?.startsWith('document.')) {
          const source = sortSelector.split('.');
          source.shift();
          if (sortSelector.endsWith('.value')) {
            source.pop();
          }
          return source.join('.');
        } else if (sortSelector?.startsWith('decision.')) {
          return sortSelector;
        }
      }
    }
    return null;
  };

  private getReferencesFromDocuments(documents: IDocument[]): Observable<IDocument[]> {
    if (!documents.length) {
      return of([] as IDocument[]);
    }
    return of(documents).pipe(
      map(docs => docs.filter(d => d.references.length)),
      map(docs => docs.map(d => d.id)),
      switchMap(ids => this.getDocumentsByIds(ids)),
      concatMap(response => response),
      toArray(),
      map(parents =>
        documents.map(doc => {
          doc.references = doc.references.map(r => {
            r.document = parents.find(p => p.id === r.referencedDocumentId);
            return r;
          });
          return doc;
        })
      )
    );
  }

  private handleError = (message: string): void => {
    this.loadPanelService.hide();
    NotifyService.global.error(message, 3000);
  };

  /**
   * Check if document has attahcments accordingly to the provided field of attachment
   *
   * @param document
   * @private
   */
  private isHasAttachment(document: IDocument): boolean {
    if (!this.#attachmentFields.length) {
      return false;
    }

    const documentFields = document.fields;
    return (
      this.#attachmentFields
        .filter(fieldName => !!documentFields[fieldName])
        .map(fieldName => documentFields[fieldName].value as unknown[])
        .filter(fieldValue => !!fieldValue && Array.isArray(fieldValue))
        .filter(fieldValue => fieldValue.length > 0).length !== 0
    );
  }

  private onUpdateToolbarProperty(property: string, value: unknown): void {
    if (!this.listState.toolbar) {
      this.listState.toolbar = {} as IDynamicListStateToolbar;
    }
    this.listState.toolbar[property] = value;
  }

  private resetState(): void {
    this.#doNotQuery = true;
    const instance = this.gridComponent?.instance;
    instance?.clearFilter();
    instance?.clearGrouping();
    instance?.state(null);

    this.listState = {
      toolbar: {
        selectedDateRange: undefined,
        isPreviewEnabled: false,
        personalMode: this.personalMode ? 2 : 1,
        currentMode: 1
      },
      documentType: '',
      grid: null
    };

    this.#templates = {
      showCreatorDepartment: false,
      showCreatorName: false,
      showModificationName: false,
      showIcon: false,
      showLabels: false,
      showProgress: false,
      showWorkflowStatus: false,
      showWorkflowColor: false,
      showCurrentAssignee: false,
      showParentDocuments: false,
      showChildDocuments: false,
      showDecisionData: false,
      showDecisionApprovalType: false
    };
  }

  private saveState(): void {
    if (this.listState.documentType && !this.noState) {
      this.listState.grid = this.gridComponent?.instance?.state() as Record<string, unknown>;
      this.configurationService
        .saveListState(this.listState.documentType, this.listState)
        .pipe(takeUntil(this.#destroyable$))
        .subscribe();
    }
  }

  private setPagerOptions(count: number): void {
    const pageSize = this.settings?.pageSize || 25;
    let allowedPageSizes: number[] = [];
    if (count <= pageSize) {
      this.pagerVisible = false;
    } else if (count > pageSize && count <= 25) {
      this.pagerVisible = true;
      allowedPageSizes = [25];
    } else if (count > 25 && count <= 50) {
      this.pagerVisible = true;
      allowedPageSizes = [25, 50];
    } else if (count > 50 && count <= 75) {
      this.pagerVisible = true;
      allowedPageSizes = [25, 50, 75];
    } else {
      this.pagerVisible = true;
      allowedPageSizes = [25, 50, 75, 100];
    }
    allowedPageSizes.push(pageSize);
    this.allowedPageSizes = Array.from(new Set(allowedPageSizes)).sort((a, b) => a - b);
  }

  private async setDataSource(documents: IDocument[]): Promise<IDataSource[]> {
    const dataSource: IDataSource[] = [];
    if (!documents?.length) {
      return lastValueFrom(of(dataSource));
    }
    this.emitter.emit({ command: 'documents', object: documents });
    let currentDocuments = documents;
    if (this.#templates.showParentDocuments || this.#templates.showProgress || this.#templates.showChildDocuments) {
      currentDocuments = await lastValueFrom(this.getReferencesFromDocuments(documents));
    }
    for (const document of currentDocuments) {
      const data: IDataSource = {
        document,
        attachment: this.isHasAttachment(document),
        creatorNameWithAvatar: '',
        modificationNameWithAvatar: '',
        creatorDepartment: '',
        labels: null,
        labelsTooltip: '',
        status: '',
        statusColor: '',
        progress: 0,
        currentAssignee: '',
        parentDocuments: [],
        childDocuments: []
      };
      if (this.#templates.showParentDocuments && document.references.length) {
        const parents = document.references
          .filter(p => p.tags.includes(siamConst.parentTag) && p.document)
          .map(ref => ref.document);
        const tagType = 'app:document-icon:';
        for (const parent of parents) {
          const parentDocument: IParentDocument = {};
          parentDocument.parentDocumentId = parent?.id;
          parentDocument.parentDocumentTags = parent?.tags;
          if (parent?.fields?.subject) {
            parentDocument.parentDocumentSubject = parent?.fields?.subject?.value as string;
          }
          parentDocument.parentDocumentType = (parent?.template && parent.template.caption) || null;
          parentDocument.parentDocumentIcon =
            parent?.tags
              .filter(tag => tag.startsWith(tagType))
              .map(tag => tag.split(new RegExp(`^${tagType}`))[1])[0] || null;
          data.parentDocuments.push(parentDocument);
        }
      }
      if (this.#templates.showChildDocuments && document.references.length) {
        const children = document.references
          .filter(p => p.tags.includes(siamConst.childTag) && p.document)
          .map(ref => ref.document);
        const tagType = 'app:document-icon:';
        for (const parent of children) {
          const childDocument: IChildDocument = {};
          childDocument.childDocumentId = parent?.id;
          childDocument.childDocumentTags = parent?.tags;
          if (parent?.fields?.subject) {
            childDocument.childDocumentSubject = parent?.fields?.subject?.value as string;
          }
          childDocument.childDocumentType = (parent?.template && parent.template.caption) || null;
          childDocument.childDocumentIcon =
            parent?.tags
              .filter(tag => tag.startsWith(tagType))
              .map(tag => tag.split(new RegExp(`^${tagType}`))[1])[0] || null;
          data.childDocuments.push(childDocument);
        }
      }
      if (this.#templates.showCreatorName || this.#templates.showCreatorDepartment) {
        const user = create();
        user.id = document.creation.userId;
        data.creatorNameWithAvatar = document.creation.userId;
        const userInformation = await lastValueFrom(this.nameDataPipe.transform(user, document.creation.logonUserId));
        data.creatorNameWithAvatar = userInformation.avatarName;
        data.creatorDepartment = userInformation.department;
      }

      if (this.#templates.showModificationName) {
        const user = create();
        if (!document.modification) {
          document.modification = document.creation;
        }
        user.id = document.modification.userId;
        data.modificationNameWithAvatar = document.modification.userId;
        const userInformation = await lastValueFrom(
          this.nameDataPipe.transform(user, document.modification.logonUserId)
        );
        data.modificationNameWithAvatar = userInformation.avatarName;
      }

      if (this.#templates.showProgress) {
        const count = document?.references?.length || 0;
        if (count > 0) {
          let completed = 0;
          for (const ref of document.references) {
            if (ref.document) {
              const state = ref.document.documentWorkflowDocument?.currentStatus;
              if (state === 'completed') {
                completed++;
              }
            }
          }
          data.progress = (completed / count) * 100;
        }
      }

      if (this.#templates.showWorkflowStatus) {
        data.status = document.documentWorkflowDocument?.currentStatusLabel;
        if (this.#templates.showWorkflowColor) {
          const getStatusColor = (): string => {
            const workflowDocument = this.workflowsVertices.find(
              workflow => workflow.id === document.documentWorkflowDocument?.workflowId
            );
            const vertice = workflowDocument?.vertices.find(
              v =>
                v.name === document.documentWorkflowDocument.currentStatus ||
                v.label === document.documentWorkflowDocument.currentStatusLabel
            );
            if (vertice) {
              return vertice?.clientConfiguration?.color || 'default';
            }
            return 'default';
          };
          if (!this.workflowsVertices?.length) {
            this.workflowsVertices = await lastValueFrom(
              this.workflowService.getWorkFlowsVertices().pipe(takeUntil(this.#destroyable$))
            );
          }
          data.statusColor = getStatusColor();
        }
      }

      if (this.#templates.showCurrentAssignee) {
        const currentAssignees = document.documentWorkflowDocument?.currentAssignee;
        const result: string[] = [];
        if (Array.isArray(currentAssignees)) {
          for (const val of currentAssignees) {
            let entry: IUserPipe;
            switch (val.type) {
              case 'user':
                entry = this.#users.get(val.targetId);
                break;

              case 'role':
                entry = this.#roles.get(val.targetId);
                break;
            }

            if (entry) {
              result.push(entry.avatarName);
            }
          }
        }
        data.currentAssignee = result.join('');
      }

      if (this.#templates.showLabels) {
        data.labels = await lastValueFrom(this.documentService.getLabels(document));
        const labelNames = data.labels ? data.labels.map(label => label.name) : [];
        data.labelsTooltip = labelNames?.join(', ');
      }
      if (this.#templates.showDecisionData) {
        const count = await this.decisionColumnHeaderFilterDataStore.totalCount({});
        if (count === 0) {
          const decisionStamps = await lastValueFrom(this.decisionStampService.getDecisionStamps());
          sortBy(decisionStamps, 'displayName').forEach(decisionStamp => {
            const data = {
              text: decisionStamp.displayName,
              value: ['decision.category', '=', decisionStamp.name],
              key: decisionStamp.name,
              data: decisionStamp
            };
            void this.decisionColumnHeaderFilterDataStore.insert(data);
          });
          void this.decisionColumnHeaderFilterDataStore.load();
        }
        if (this.#templates.showDecisionApprovalType) {
          const countApproval = await this.decisionTypeColumnHeaderFilterDataStore.totalCount({});
          if (countApproval === 0) {
            const decisionStamps = (await this.decisionColumnHeaderFilterDataStore.load()) as IColumnHeaderFilter[];
            const approvalListIds = decisionStamps.map(item => (item.data as IDecisionStamp).decisionChoicesId);
            const uniqueIds = Array.from(new Set(approvalListIds));
            const approvalLists = await lastValueFrom(
              this.listsService.getListbyIds(uniqueIds).pipe(takeUntil(this.#destroyable$))
            );

            const listDataSource: IColumnHeaderFilter[] = [];
            approvalLists.forEach(list => {
              list.entries.forEach(entry => {
                const data = {
                  text: entry.label,
                  value: ['decision.fields.decision', '=', entry.value],
                  key: entry.value as string,
                  data: list
                };

                const entryExists = listDataSource.find(item => item.key === entry.value);
                if (!entryExists) {
                  listDataSource.push(data);
                }
              });
            });
            sortBy(listDataSource, 'text').forEach(data => {
              void this.decisionTypeColumnHeaderFilterDataStore.insert(data);
            });
            void this.decisionTypeColumnHeaderFilterDataStore.load();
            this.#approvalLists = clone(approvalLists).reduce((acc, item) => {
              acc[item.id] = item;
              return acc;
            }, {} as Record<string, SiamList>);
          }
        }
      }

      dataSource[dataSource.length] = data;
    }
    return dataSource;
  }

  private setColumns(): void {
    const columns: Column[] = [];

    if (Array.isArray(this.settings?.columns)) {
      let groupIndex = 0;
      let sortIndex = 0;
      let columnIndex = 0;
      for (const column of this.settings.columns) {
        columnIndex++;
        let gridColumn = this.getColumnOptions(column);

        // set summary in first column
        if (columns.length === 0) {
          this.summaryColumnName = gridColumn.name;
        }

        if (this.listState.grid) {
          const listColumn = (this.listState.grid.columns as IDynamicGridSettingsColumn[])?.find(
            col => col.dataField === gridColumn.dataField
          );
          if (listColumn) {
            // need to delete here the sortOrder property,
            // because column from the saved state might does not have sorting anymore
            gridColumn.sortOrder = null;
            gridColumn = { ...gridColumn, ...listColumn };
          }
        } else {
          // if column is in group, it should not get sortIndex value
          if (column.allowSorting && !column.isGrouping) {
            gridColumn.sortIndex = sortIndex++;
          }

          if (!column.isGroupingDisabled && column.isGrouping) {
            gridColumn.groupIndex = groupIndex++;
          }
        }
        if (columns.find(c => c.name === gridColumn.name)) {
          gridColumn.name = `${gridColumn.name}-${columnIndex}`;
        }
        columns.push(<Column>gridColumn);
      }
    }
    this.gridComponent.columns = columns;
    void this.gridComponent?.instance?.endUpdate();
  }

  /**
   * Set visibility to 'Icon' column
   *
   */
  private setColumnVisibility(component: dxDataGrid, columns: Column[], isVisible: boolean): void {
    columns.forEach(column => {
      component.columnOption(column.visibleIndex, 'visible', isVisible);
    });
  }

  /**
   * Create parent label
   *
   * @param parent
   */
  private setParentLabel = (parent: IParentDocument): string =>
    parent.parentDocumentSubject
      ? `${parent.parentDocumentType}: ${parent.parentDocumentSubject}`
      : `${parent.parentDocumentType}`;

  private setProperties = (
    column: IDynamicGridSettingsColumn,
    addons: Record<string, unknown>
  ): IDynamicGridSettingsColumn => {
    // if it is not explicitly set to FALSE, assume it is TRUE
    let allowGrouping: boolean;
    if (column.allowGrouping === undefined) {
      allowGrouping = true;
    } else {
      allowGrouping = !!column.allowGrouping;
    }
    const properties: IDynamicGridSettingsColumn = {
      caption: column.caption,
      allowHiding: !!column.allowHiding,
      allowResizing: !!column.allowResizing,
      allowHeaderFiltering: !!column.allowHeaderFiltering,
      allowSorting: !!column.allowSorting,
      allowGrouping: !!this.settings.toolbar.isShowGrouping && allowGrouping,
      visible: true,
      name: column.caption,
      choices: column.choices || null,
      choicesName: column.choicesName
    };

    if (column.isGroupingDisabled) {
      properties.allowGrouping = false;
    }

    if (column.allowSorting && column.sortOrder) {
      properties.sortOrder = column.sortOrder;
    }

    if (!column.isGrouping && column.allowHiding && column.isHidden) {
      properties.visible = false;
      properties.allowSearch = false;
    }

    // Date and datetime throwing errors by filtering and grouping, so disable it
    switch (column.dataType) {
      case 'date':
      case 'datetime':
        {
          properties.allowHeaderFiltering = false;
          properties.allowGrouping = false;
        }
        break;
    }

    return { ...properties, ...addons };
  };

  private shouldInclude(userName: string): boolean {
    let isInclude = true;
    if (this.#firstValue && userName && !userName.toLowerCase().includes(this.#firstValue.toLowerCase())) {
      isInclude = false;
    }
    return isInclude;
  }

  /**
   * start or abort timeout
   */
  private timeout = (): ITimer => {
    let id: NodeJS.Timeout = null;
    const start = () =>
      new Promise(resolve => {
        id = setTimeout(resolve, 1000);
      });
    const abort = () => {
      if (id !== null) {
        clearTimeout(id);
        id = null;
      }
    };
    return {
      start,
      abort
    };
  };

  private updateDataGrid(): void {
    const instance = this.gridComponent?.instance;
    if (instance) {
      void instance.endUpdate();
      void instance.repaint();
    }
    this.loadPanelService.hide();
  }
}
