import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Apollo, gql } from 'apollo-angular';
import { EMPTY, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  IAccount,
  IPasswordRequirements,
  IPermissionTarget,
  ISearchUser,
  ISearchUserRersponse,
  IUser,
  IUserProfile,
  TGraphqlAcoountMode
} from '@interfaces/siam';
import { LoggerService } from '@services/logger.service';

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

interface GraphqlData {
  account: IAccount;
}

@Injectable({
  providedIn: 'root'
})
export class UserApi {
  readonly #apiBaseUrl: string;
  readonly #baseUrl: string;

  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private logger: LoggerService,
    @Inject('BASE_URL') baseUrl: string
  ) {
    this.#apiBaseUrl = `${baseUrl}api/account`;
    this.#baseUrl = `${baseUrl}api`;
  }

  create(user: IUser, password: string): Observable<IUser> {
    const headers = new HttpHeaders({
      accept: 'application/json',
      // eslint-disable-next-line
      'x-user-password': password
    });
    return this.http.post<IUser>(`${this.#apiBaseUrl}/users`, user, { headers }).pipe(
      switchMap(u => this.getUser(u.id)),
      tap({
        next: result => {
          this.logger.info('Benutzer Erstellen = {@userinfo}', result);
        },
        error: error => {
          this.logger.error('Fehler beim Erstellen des Benutzers: {@error}', error);
        }
      })
    );
  }

  /**
   * import a users
   *
   */
  importUsers(): Observable<IUser[]> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });
    return this.http.post<IUser[]>(`${this.#apiBaseUrl}/users/import`, { headers }).pipe(
      tap({
        next: result => {
          this.logger.info('Benutzer importiert = {@userinfo}', result);
        },
        error: error => {
          this.logger.error('Fehler beim Benutzer import: {@error}', error);
        }
      })
    );
  }

  /**
   * update a user
   *
   * @param user
   */
  update(user: IUser): Observable<IUser> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });
    return this.http.put<IUser>(`${this.#apiBaseUrl}/users/${user.id}`, user, { headers }).pipe(
      switchMap(u => this.getUser(u.id)),
      tap({
        next: result => {
          this.logger.info('Benutzer Bearbeiten = {@userinfo}', result);
        },
        error: error => {
          this.logger.error('Fehler beim Bearbeiten des Benutzers: {@error}', error);
        }
      })
    );
  }

  /**
   * update a profile
   *
   * @param user
   */
  updateProfile(user: IUser): Observable<IUser> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });
    return this.http.post<IUserProfile>(`${this.#apiBaseUrl}/users/${user.id}/profile`, user.profile, { headers }).pipe(
      switchMap(() => this.getUser(user.id)),
      tap({
        next: result => {
          this.logger.info('Benutzer Bearbeiten = {@userinfo}', result);
        },
        error: error => {
          this.logger.error('Fehler beim Bearbeiten des Benutzers: {@error}', error);
        }
      })
    );
  }

  /**
   * deleting the user from DB
   *
   * @param user
   */
  delete(user: IUser): Observable<IUser> {
    return this.http.delete<IUser>(`${this.#apiBaseUrl}/users/${user.id}`).pipe(
      tap({
        next: () => {
          this.logger.info('Benutzers Löschen= {@document}', user);
        },
        error: error => {
          this.logger.error('Fehler beim Löschen des Benutzers: {@error}', error);
        }
      })
    );
  }

  /**
   * Change the password
   *
   * @param user
   * @param oldPassword
   * @param newPassword
   */
  changePassword(user: IUser, oldPassword: string, newPassword: string): Observable<boolean> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });
    const body = new HttpParams({ encoder: new CustomEncoder() })
      .set('oldPassword', oldPassword)
      .set('newPassword', newPassword);
    return this.http.post(`${this.#apiBaseUrl}/change-password/${user.id}`, body, { headers }).pipe(
      map(() => true),
      tap({
        error: error => {
          this.logger.error('Fehler beim Bearbeiten des Benutzerpassworts: {@error}', error);
        }
      })
    );
  }

  /**
   * Get all users
   *
   * @params lite?: get only minimum user data
   * @return list of users
   */
  getAllUsers(): Observable<IUser[]> {
    return this.getUsers();
  }

  /**
   * Get certain users
   *
   * @param userIds: List of requested UserIds
   * @return list of users
   */
  getUsers(userIds?: string[], mode: TGraphqlAcoountMode = 'all'): Observable<IUser[]> {
    const query = gql`
      query ($userIds: [ID!], $mode: UserFilterMode) {
        account {
          users(ids: $userIds, mode: $mode) {
            id
            isAdministrator
            isPasswordChangeRequired
            isLockedOut
            isIntern
            isArchived
            failedLoginCount
            name
            email
            connectedLogins
            roles {
              id
              name
            }
            substituteUsers {
              id
            }
            substituteRoles {
              id
            }
            picture {
              lastUpdate
              url
              originalFileName
            }
            profile {
              title
              forename
              surname
              company
              department
              jobTitle
              location
              picture {
                lastUpdate
                url
                originalFileName
              }
            }
          }
        }
      }
    `;
    return this.apollo
      .query<GraphqlData>({
        query,
        variables: { userIds, mode }
      })
      .pipe(map(({ data }) => data.account.users));
  }

  /**
   * Returns user information by user ID
   *
   * @param userId - user id must be string (guid)
   * @return user information
   */
  getUser(userId: string): Observable<IUser> {
    // --  umgestellt von REST auf GraphQL --
    const query = gql`
      query ($id: ID) {
        account {
          user(id: $id) {
            id
            capabilities
            effectiveCapabilities
            isAdministrator
            isPasswordChangeRequired
            isLockedOut
            isIntern
            isArchived
            failedLoginCount
            name
            email
            connectedLogins
            confidentialLevels {
              confidentialId
            }
            substituteUsers {
              id
            }
            substituteRoles {
              id
            }
            roles {
              id
              name
            }
            profile {
              title
              forename
              surname
              company
              department
              jobTitle
              location
              picture {
                lastUpdate
                url
                originalFileName
              }
            }
          }
        }
      }
    `;

    return this.apollo
      .query<GraphqlData>({
        query,
        variables: {
          id: userId
        }
      })
      .pipe(map(({ data }) => data.account.user));
  }

  getAvatarUrl(user: IUser, force = false): string {
    let userAvatar = '';
    if (user?.id) {
      userAvatar = `${this.#apiBaseUrl}/users/${user.id}/profile/picture`;
    } else if (user?.profile?.picture?.url) {
      userAvatar = `${this.#apiBaseUrl}/../..${user.profile.picture.url}`;
    } else {
      userAvatar = `${this.#baseUrl}/images/$default/DefaultUserImage-01.png`;
    }

    const lastUpdate = user?.profile?.picture?.lastUpdate;
    if (userAvatar && lastUpdate) {
      const milliseconds = new Date(lastUpdate).getTime();
      userAvatar = `${userAvatar}?time=${milliseconds}`;
    }
    if (userAvatar && force) {
      const milliseconds = new Date().getTime();
      userAvatar = `${userAvatar}?time=${milliseconds}`;
    }
    return userAvatar;
  }

  uploadAvatar(user: IUser, picture: File): Observable<void> {
    if (!picture) {
      return EMPTY;
    }

    const formData: FormData = new FormData();
    formData.append('image', picture);
    return this.http.post<void>(`${this.#apiBaseUrl}/users/${user.id}/profile/picture`, formData).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Speichern des Userbildes: {@error}', error);
        }
      })
    );
  }

  removeAvatar(userId: string): Observable<void> {
    return this.http.delete<void>(`${this.#apiBaseUrl}/users/${userId}/profile/picture`).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Entfernen des Userbildes: {@error}', error);
        }
      })
    );
  }

  getUsersWithCapabilities(): Observable<IAccount> {
    const query = gql`
      {
        account {
          users {
            id
            name
            profile {
              forename
              surname
            }
            effectiveCapabilities
            roles {
              name
            }
          }
          roles {
            id
            name
            capabilities
          }
        }
      }
    `;

    return this.apollo
      .query<GraphqlData>({
        query
      })
      .pipe(map(({ data }) => data.account));
  }

  getInfo(logError = true): Observable<IUser> {
    const headers = new HttpHeaders({
      // eslint-disable-next-line
      'content-Type': 'application/x-www-form-urlencoded',
      accept: 'text/json'
    });
    return this.http.get<IUser>(`${this.#apiBaseUrl}/info`, { headers }).pipe(
      tap({
        error: error => {
          if (logError) {
            this.logger.error('Fehler beim get User Info: {@error}', error);
          }
        }
      })
    );
  }

  getSubstitutes(user: IUser): Observable<IPermissionTarget[]> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });
    const params = new HttpParams().set('user-id', user.id);
    return this.http.get<IPermissionTarget[]>(`${this.#apiBaseUrl}/substitutes/`, { headers, params }).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Laden der Vertreten des Benutzers: {@error}', error);
        }
      })
    );
  }

  getPasswordRequirements(): Observable<IPasswordRequirements> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });
    return this.http.get<IPasswordRequirements>(`${this.#apiBaseUrl}/password-requirements/`, { headers }).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Laden der Kennwortanforderungen', error);
        }
      })
    );
  }

  getImpersonationCandidates(): Observable<IUser[]> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });
    return this.http.get<IUser[]>(`${this.#apiBaseUrl}/impersonation/candidates`, { headers }).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Laden der Kandidaten des Benutzers: {@error}', error);
        }
      })
    );
  }

  login(userName: string, password: string, isPersistent: boolean): Observable<IUser> {
    const body = new HttpParams({ encoder: new CustomEncoder() })
      .set('username', userName)
      .set('password', password)
      .set('is-persistent', JSON.stringify(isPersistent));

    const headers = new HttpHeaders({
      // eslint-disable-next-line
      'content-Type': 'application/x-www-form-urlencoded',
      accept: 'application/json'
    });

    return this.http
      .post<IUser>(`${this.#apiBaseUrl}/login`, body, { headers })
      .pipe(switchMap(user => this.getUser(user.id)));
  }

  loginImpersonation(userId: string): Observable<IUser> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });
    return this.http.post<IUser>(`${this.#apiBaseUrl}/impersonation/as/${userId}`, { headers }).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Vertreten eines Benutzers: {@error}', error);
        }
      })
    );
  }

  logOut(): Observable<void> {
    const headers = new HttpHeaders({
      // eslint-disable-next-line
      'content-Type': 'application/x-www-form-urlencoded',
      accept: 'text/json'
    });
    return this.http.post<void>(`${this.#apiBaseUrl}/logout`, null, { headers }).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Abmelden: {@error}', error);
        }
      })
    );
  }

  setSubstitutes(userId: string, substitutes: IPermissionTarget[]): Observable<void> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });
    const params = new HttpParams().set('user-id', userId);
    return this.http.post<void>(`${this.#apiBaseUrl}/substitutes/`, substitutes, { headers, params }).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim beiarbeiten der Vertreten des Benutzers: {@error}', error);
        }
      })
    );
  }

  stopImpersonation(): Observable<IUser> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });
    return this.http.post<IUser>(`${this.#apiBaseUrl}/impersonation/stop`, { headers }).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim abmeldung der Vertretung: {@error}', error);
        }
      })
    );
  }

  searchUsers(data: ISearchUser): Observable<ISearchUserRersponse[]> {
    let params = new HttpParams().set('query', data.query);
    if (data?.properties?.length) {
      data.properties.forEach(p => {
        params = params.append('p', p);
      });
    }
    if (data?.mode) {
      params = params.set('m', data.mode);
    }
    return this.http.get<ISearchUserRersponse[]>(`${this.#apiBaseUrl}/users`, { params }).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Suchen der Benutzer: {@error}', error);
        }
      })
    );
  }

  windowsAuth(): Observable<IUser> {
    return this.http.get<IUser>(`${this.#baseUrl}/account/sso/windows-authentication`);
  }
}
