<!-- ----------------------------------------------------------------------------------------------- -->
<!-- Liste der Tagesordnungspunkte  -->
<!-- ----------------------------------------------------------------------------------------------- -->
<div class="agenda-items">
  <!-- Tabellenüberschriften -->
  <div *ngIf="agendaItems && agendaItems.length > 0" class="agenda-items-header">
    <div i18n class="agenda-column agenda-column__index">TOP</div>
    <div i18n class="agenda-column agenda-column__type">Typ</div>
    <div class="agenda-column agenda-column__attachment"></div>
    <div i18n class="agenda-column agenda-column__subject">Thema</div>
    <div i18n class="agenda-column agenda-column__speakers">Referent</div>
    <div i18n class="agenda-column agenda-column__time">Geplante Zeit</div>
    <div class="agenda-column agenda-column__protocol">
      <i class="material-icons protocol-icon">list_alt</i>
      <dx-tooltip target=".protocol-icon" showEvent="dxhoverstart" hideEvent="dxhoverend">
        <div *dxTemplate="let data = data; of: 'content'">Protokoll erstellt</div>
      </dx-tooltip>
    </div>
    <div class="agenda-column agenda-column__approval">
      <i class="material-icons approval-icon">bookmark</i>
      <dx-tooltip target=".approval-icon" showEvent="dxhoverstart" hideEvent="dxhoverend">
        <div *dxTemplate="let data = data; of: 'content'">Beschluss erstellt</div>
      </dx-tooltip>
    </div>
    <div class="agenda-column agenda-column__tasks">
      <i class="material-icons task-icon">alarm_on</i>
      <dx-tooltip target=".task-icon" showEvent="dxhoverstart" hideEvent="dxhoverend">
        <div *dxTemplate="let data = data; of: 'content'">Aufgabe erstellt</div>
      </dx-tooltip>
    </div>
    <div
      class="agenda-column agenda-column__actions"
      *ngIf="editMode === 'Edit' && hasUpdatePermission && workflowStatus !== 'abgeschlossen'"></div>
  </div>

  <!-- Agendapunkte -->
  <ng-template *ngIf="agendaItems && agendaItems.length > 0; then dataBlockContainer"></ng-template>

  <ng-template #dataBlockContainer>
    <ng-container *ngTemplateOutlet="dataBlock; context: { $implicit: agendaItems, effect: 'move' }"></ng-container>
  </ng-template>

  <ng-template #dataBlock let-items>
    <div
      class="agenda-items-entries"
      *ngFor="let aItem of items; index as index; let last = last"
      [dndDraggable]="aItem"
      [dndEffectAllowed]="'move'"
      [dndDisableIf]="disableDraggeble || !(editMode === 'Edit' && hasUpdatePermission)"
      (dndStart)="onDragStart($event)"
      (dndLinked)="onDragged(aItem, items, 'link')"
      (dndMoved)="onDragged(aItem, items, 'move')"
      (dndCanceled)="onDragged(aItem, items, 'none')"
      (dndEnd)="onDragEnd()">
      <div
        id="AgendaItem"
        class="agenda-item"
        [ngClass]="{
          submission: aItem.document
            ? !(aItem.document | findTag : 'app:document-type:top') &&
              !(aItem.document | findTag : 'app:document-type:pause')
            : false,
          selected: selectedIndex === index,
          'not-selectable': editMode === 'readOnly',
          'agenda-item--last': last,
          'agenda-item--pause': aItem.document | isPause
        }">
        <div class="agenda-item__parent" (click)="selectAgendaItem(aItem, index)">
          <!-- index  -->
          <div class="agenda-column agenda-column__index">
            <div class="agenda__index" [class.agenda__index--parent]="aItem.parentIndex">
              <span *ngIf="aItem.parentIndex && !(aItem.document | isPause)">{{ aItem.parentIndex }}.</span>
              <span *ngIf="!(aItem.document | isPause)">{{ aItem.index }}</span>
              <i *ngIf="aItem.document | isPause" class="dx-icon-coffee"></i>
            </div>
          </div>
          <!-- Type  -->
          <div class="agenda-column agenda-column__type">
            <div *ngIf="aItem.document && !(aItem.document | isPause)" (click)="$event.stopPropagation()">
              <dx-select-box
                [dataSource]="topTypes"
                [(value)]="aItem.type"
                [disabled]="!(editMode === 'Edit' && hasUpdatePermission)"
                displayExpr="value"
                valueExpr="id"
                itemTemplate="itemSelectBox">
                <div *dxTemplate="let data of 'itemSelectBox'">
                  <div class="custom-item">{{ data.name }}</div>
                </div>
                <div *dxTemplate="let data of 'dropDownButton'">
                  <img class="custom-icon" alt="" src="" />
                </div>
              </dx-select-box>
            </div>
          </div>
          <!-- Attachment  -->
          <div class="agenda-column agenda-column__attachment">
            <span *ngIf="aItem.document | hasAttachment">
              <i class="material-icons agenda-column__speakers-icon state-hover">attach_file</i></span
            >
          </div>
          <!-- subject -->
          <div class="agenda-column agenda-column__subject">
            <!-- icon for submission -->
            <i
              *ngIf="
                aItem &&
                aItem.document &&
                !(aItem.document | findTag : 'app:document-type:top') &&
                !(aItem.document | findTag : 'app:document-type:pause')
              "
              class="material-icons submission__icon"
              >{{ aItem.document.tags | getTag : 'icon' }}</i
            >
            <span
              *ngIf="aItem.document && aItem.document.fields['subject']; else placeholder"
              [style.font-weight]="aItem.parentIndex ? 'normal' : '600'">
              {{ aItem.document.fields['subject'].value }}</span
            >
          </div>
          <!-- speakers  -->
          <div class="agenda-column agenda-column__speakers" (click)="selectAItem($event, aItem)">
            <span
              (click)="setSpeakers(aItem)"
              *ngIf="aItem.document && editMode === 'Edit' && hasUpdatePermission && !(aItem.document | isPause)">
              <i class="material-icons agenda-column__speakers-icon state-hover">person_add</i></span
            >
            <span (click)="setSpeakers(aItem)" *ngIf="aItem.document && aItem.speakers">
              {{ aItem.speakers | mapAuthorName | async }}</span
            >
          </div>
          <!-- time -->
          <div class="agenda-column agenda-column__time" (click)="selectAItem($event, aItem)">
            <dx-date-box [disabled]="true" [value]="aItem.startTime" type="time" width="80" [visible]="false">
            </dx-date-box>
            <dx-date-box
              [disabled]="true"
              type="time"
              width="80"
              [visible]="false"
              [value]="aItem.endTime || agendaStartDate">
            </dx-date-box>
            <!-- time value -->
            <dx-number-box
              #timeBox
              width="50"
              format="###0"
              [disabled]="!(editMode === 'Edit' && hasUpdatePermission) || aItem.children?.length"
              [min]="timeSliderSettings[0].min"
              [max]="timeSliderSettings[0].max"
              [step]="timeSliderSettings[0].step"
              (onValueChanged)="setAgendaTime(aItem)"
              (onFocusIn)="disableDragItem()"
              (onFocusOut)="enableDragItem()"
              [(value)]="aItem.timeInterval">
            </dx-number-box>
            <span
              [class.agenda-column__time__link]="aItem.document && editMode === 'Edit' && hasUpdatePermission"
              (click)="
                aItem.document && editMode === 'Edit' && hasUpdatePermission ? showTimeSlider($event, aItem) : false
              "
              >Min.</span
            >
            <span class="agenda-column__time__absolut"
              >({{ aItem.startTime | date : 'HH:mm' }} &ndash; {{ aItem.endTime | date : 'HH:mm' }})</span
            >
          </div>
          <!-- protocol -->
          <div class="agenda-column agenda-column__protocol">
            <i
              *ngIf="aItem.document && aItem.protocolId; else placeholder"
              (click)="editAgendaDocument(aItem.document, ['app:document-type:protocol']); $event.stopPropagation()"
              class="material-icons"
              >done</i
            >
          </div>
          <!-- approval -->
          <div class="agenda-column agenda-column__approval">
            <i
              *ngIf="aItem.document && aItem.approved; else placeholder"
              (click)="approveSelectedItem($event, aItem)"
              class="material-icons"
              >done</i
            >
          </div>
          <!-- tasks -->
          <div class="agenda-column agenda-column__tasks">
            <span *ngIf="aItem.document && aItem.taskCount; else placeholder"> {{ aItem.taskCount }}</span>
          </div>
          <!-- actions -->
          <div id="agendaItemActionsList" class="agenda-column agenda-column__actions">
            <i
              (click)="showAgendaItemActionsList($event, aItem, index)"
              class="material-icons state-hover"
              *ngIf="
                !(aItem.document | isPause) &&
                  aItem.document &&
                  editMode === 'Edit' &&
                  hasUpdatePermission &&
                  workflowStatus !== 'abgeschlossen';
                else placeholder
              ">
              <span>more_horiz</span>
            </i>

            <!-- delete -->
            <div
              id="AgendaItemRemove"
              class="agenda-column__actions__delete"
              *ngIf="editMode === 'Edit' && hasUpdatePermission && workflowStatus !== 'abgeschlossen'">
              <i (click)="removeAgendaItem($event, aItem)" class="material-icons state-hover">delete</i>
            </div>
          </div>
        </div>

        <div
          class="agenda-item__children"
          *ngIf="!aItem.parentDocId?.length"
          dndDropzone
          (dndDrop)="onDrop2InsideTop($event, items, index + 1, aItem)">
          <div *ngIf="aItem && aItem.children && aItem.children.length">
            <ng-container *ngTemplateOutlet="dataBlock; context: { $implicit: aItem.children, effect: 'move' }">
            </ng-container>
          </div>
        </div>

        <!-- Add Top & Submission buttons -->
        <div class="agenda-add" *ngIf="editMode === 'Edit' && hasUpdatePermission && !last">
          <div class="agenda-add-buttons">
            <button i18n type="submit" class="btn" (click)="createAgendaItem(index)">
              <i class="dx-icon-plus"></i>Neuen TOP erstellen
            </button>
            <button i18n type="submit" class="btn" (click)="showSubmissionsList(index)">
              <i class="dx-icon-plus"></i>Vorlage hinzufügen
            </button>
            <button i18n type="submit" class="btn" (click)="createBreakItem(index)">
              <i class="dx-icon-coffee"></i>Pause hinzufügen
            </button>
            <button i18n type="submit" class="btn" (click)="showFreeTopsList(index)">
              <i class="dx-icon-plus"></i>Freien TOP hinzufügen
            </button>
            <button i18n type="submit" class="btn" (click)="showOldTopsList(index)">
              <i class="dx-icon-plus"></i>TOP aus Sitzung übernehmen
            </button>
          </div>
        </div>
      </div>

      <!-- Placeholder for agenda items -->
      <ng-template #placeholder>
        <div class="agenda-column agenda-column__approval">
          <i class="material-icons">&nbsp;</i>
        </div>
      </ng-template>

      <div dndDropzone class="dropzone-outside" (dndDrop)="onDrop2OutsideTop($event, items, index + 1)"></div>
    </div>
  </ng-template>

  <!-- Agenda  Fazit-->
  <div *ngIf="agendaItems && agendaItems.length > 0" class="agenda-items-footer">
    <div class="agenda-column agenda-column__index"></div>
    <div class="agenda-column agenda-column__subject"></div>
    <div class="agenda-column agenda-column__time">
      Sitzungsdauer: {{ agendaTotalTime }} - voraussichtliches Sitzungsende: {{ agendaEndTime }} Uhr
    </div>
  </div>

  <div class="add-agenda-items" *ngIf="editMode === 'Edit' && hasUpdatePermission">
    <dx-drop-down-button
      [text]="addTopButtonText"
      icon="plus"
      keyExpr="id"
      displayExpr="name"
      [splitButton]="true"
      [items]="addButtons"
      [width]="260"
      (onButtonClick)="createAgendaItem()"
      (onItemClick)="onAddItemClick($event)">
    </dx-drop-down-button>
  </div>
</div>
<dx-action-sheet
  title="Choose action"
  [usePopover]="true"
  [items]="timeSliderSettings"
  [showTitle]="false"
  [width]="200"
  [height]="80"
  [target]="actionSheetTarget"
  [showCancelButton]="false"
  [(visible)]="timeSliderVisible"
  itemTemplate="link">
  <div *dxTemplate="let item of 'link'">
    <dx-slider
      [min]="timeSliderSettings[0].min"
      [max]="timeSliderSettings[0].max"
      [step]="timeSliderSettings[0].step"
      [(value)]="timeSliderSettings[0].value"
      valueChangeMode="onHandleRelease"
      (onValueChanged)="timeSliderValueChanged($event)">
      <dxo-tooltip [enabled]="true" position="bottom" [format]="formatSliderTime"></dxo-tooltip>
      <dxo-label [visible]="true" position="bottom" [format]="formatSliderTime"></dxo-label>
    </dx-slider>
  </div>
</dx-action-sheet>
<dx-action-sheet
  id="AgendaItemActionSheet"
  [showTitle]="true"
  title="TOP-Aktionen"
  [usePopover]="true"
  [target]="agendaItemActionTarget"
  [width]="200"
  [dataSource]="agendaItemActionsList"
  [(visible)]="agendaItemActionsVisible"
  (onItemClick)="selectAgendaItemAction($event.itemData)"
  (onItemRendered)="addClassToPopupActions($event)">
</dx-action-sheet>
<!-- ----------------------------------------------------------------------------------------------- -->
<!-- Popup für AgendaItems: Lesen oder Bearbeiten -->
<!-- ----------------------------------------------------------------------------------------------- -->
<app-document-editor [parentDocument]="agendaDocument" (dialogResult)="documentEditorResult($event)">
</app-document-editor>

<!-- ----------------------------------------------------------------------------------------------- -->
<!-- Popup für Vorlagenliste -->
<!-- ----------------------------------------------------------------------------------------------- -->
<dx-popup
  [(visible)]="popupSubmissionsListVisible"
  width="75vw"
  [height]="600"
  [showTitle]="true"
  [title]="popupSubmissionsListTitle"
  [showCloseButton]="false"
  [fullScreen]="false"
  [dragEnabled]="true"
  [hideOnOutsideClick]="false"
  [shading]="true"
  shadingColor="rgba(0, 0, 50, 0.5)"
  [toolbarItems]="[
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { icon: 'add', text: 'Übernehmen', visible: 'true' },
      onClick: popupSubmissionsListOk
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { type: 'normal', icon: 'close', text: 'Abbrechen' },
      onClick: popupSubmissionsListCancel
    }
  ]">
  <div *dxTemplate="let data of 'content'">
    <app-component-notification></app-component-notification>
    <div>
      <dx-data-grid
        #submissionSelectionGrid
        id="submissionSelectionGrid"
        [dataSource]="submissionsList"
        [height]="460"
        [showBorders]="true"
        [showColumnLines]="true"
        [allowColumnResizing]="true"
        [hoverStateEnabled]="false"
        [rowAlternationEnabled]="true"
        [selectedRowKeys]="[]"
        [columnAutoWidth]="false"
        [filterValue]="filterSubmissionValue"
        (onToolbarPreparing)="onSubmissionListToolbarPreparing($event)"
        (onCellHoverChanged)="onShowToolTipColumn($event)">
        <!-- Grid Editing settings -->
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxo-scrolling mode="virtual"></dxo-scrolling>
        <dxo-column-chooser [enabled]="true" mode="select" height="300"></dxo-column-chooser>
        <!-- columns -->
        <dxi-column
          caption="Typ"
          dataField="type"
          dataType="string"
          groupCellTemplate="categoryGroup"
          [allowFiltering]="false"
          [allowHeaderFiltering]="true">
        </dxi-column>

        <dxi-column
          caption="Erstellt am"
          dataField="document.creation.timestamp"
          dataType="date"
          [allowFiltering]="false"
          [format]="{ type: 'dd.MM.yyyy' }"
          [visible]="false"></dxi-column>

        <dxi-column
          caption="Erstellt durch"
          dataType="string"
          dataField="creatordata"
          [allowHeaderFiltering]="true"
          [allowSorting]="true"
          [width]="'auto'"
          [encodeHtml]="false">
        </dxi-column>

        <dxi-column
          caption="Abteilung"
          dataType="string"
          dataField="department"
          [allowFiltering]="false"
          [allowHeaderFiltering]="true"
          [visible]="false"></dxi-column>

        <dxi-column
          caption="Thema"
          dataType="string"
          dataField="document.fields.subject.value"
          [allowSorting]="false"
          [allowFiltering]="true"
          [encodeHtml]="false"
          cellTemplate="subjectWithAttachment"
          [allowHeaderFiltering]="false"></dxi-column>
        <div *dxTemplate="let cell of 'subjectWithAttachment'">
          <i *ngIf="cell.data.document | hasAttachment" class="material-icons md-16">attach_file</i>
          <span *ngIf="cell.data.document.fields.subject">{{ cell.data.document.fields.subject.value }}</span>
        </div>
        <dxi-column
          caption="Übergeordnetes Dokument"
          dataField="parentDocuments"
          [encodeHtml]="false"
          [allowFiltering]="false"
          [allowHeaderFiltering]="false"
          cellTemplate="parentDocumentsTemplate"
          [visible]="true">
        </dxi-column>
        <div *dxTemplate="let cell of 'parentDocumentsTemplate'">
          <div *ngFor="let parent of cell.value">
            <div>
              <i class="material-icons md-12">{{ parent.parentDocumentIcon }}</i>
              {{ parent.parentDocumentType }}: {{ parent.parentDocumentSubject }}
            </div>
          </div>
        </div>
        <dxi-column
          caption="Status"
          dataType="string"
          dataField="state"
          [allowFiltering]="false"
          [allowHeaderFiltering]="true"></dxi-column>
        <dxi-column
          caption="Referent"
          dataType="string"
          dataField="speakers"
          [allowHeaderFiltering]="true"
          [allowSorting]="true"
          [visible]="false"
          [encodeHtml]="false">
        </dxi-column>
        <dxi-column
          [visible]="false"
          caption="Wunschtermin"
          dataType="date"
          [format]="{ type: 'dd.MM.yyyy' }"
          dataField="document.fields.desireddate.value"
          [allowFiltering]="false"
          [allowHeaderFiltering]="false"></dxi-column>

        <!-- Templates -->
        <div *dxTemplate="let cell of 'cellUser'">
          <div [innerHtml]="(cell.data.creation.user | getNameData | async).avatarName | safeHtml"></div>
        </div>

        <div *dxTemplate="let cell of 'categoryGroup'">
          <div i18n class="list-categories">
            {{ cell.text ? cell.text : '(nicht kategorisiert)' }}
          </div>
        </div>
      </dx-data-grid>
    </div>
  </div>
</dx-popup>

<!-- ----------------------------------------------------------------------------------------------- -->
<!-- Popup für FreieTopsliste -->
<!-- ----------------------------------------------------------------------------------------------- -->
<dx-popup
  width="75vw"
  shadingColor="rgba(0, 0, 50, 0.5)"
  [(visible)]="popupFreeTopsListVisible"
  [height]="600"
  [showTitle]="true"
  [title]="popupFreeTopsListTitle"
  [showCloseButton]="false"
  [fullScreen]="false"
  [dragEnabled]="true"
  [hideOnOutsideClick]="false"
  [shading]="true"
  [toolbarItems]="[
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { icon: 'add', text: 'Kopie auf Agenda nehmen', visible: 'true' },
      onClick: popupFreeTopsListOk
    },
    {
      name: 'Delete',
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { icon: 'clear', text: 'Freien TOP löschen', hint: 'Freien TOP löschen' },
      onClick: popupFreeTopDelete
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { type: 'normal', icon: 'close', text: 'Abbrechen' },
      onClick: popupFreeTopsListCancel
    }
  ]">
  <div *dxTemplate="let data of 'content'">
    <app-component-notification></app-component-notification>
    <div>
      <dx-data-grid
        #freeTopSelectionGrid
        id="freeTopSelectionGrid"
        [dataSource]="freeTopsList"
        [height]="460"
        [showBorders]="true"
        [showColumnLines]="true"
        [allowColumnResizing]="true"
        [hoverStateEnabled]="false"
        [selectedRowKeys]="[]"
        [columnAutoWidth]="true"
        (onCellHoverChanged)="onShowToolTipColumn($event)">
        <!-- Grid Editing settings -->
        <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxo-scrolling mode="standard"></dxo-scrolling>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-filter-row [visible]="true"></dxo-filter-row>

        <!-- columns -->
        <dxi-column cellTemplate="subjectWithAttachment" [allowHeaderFiltering]="false" [width]="30" alignment="center">
        </dxi-column>
        <dxi-column caption="Thema" dataField="subject" sortOrder="asc" [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Erstellt am"
          dataField="document.creation.timestamp"
          dataType="date"
          [width]="100"
          [format]="{ type: 'dd.MM.yyyy' }"
          [allowFiltering]="false"></dxi-column>

        <dxi-column
          caption="Erstellt durch"
          dataField="creatordata"
          [allowSorting]="true"
          [allowHeaderFiltering]="true"
          [allowFiltering]="false"
          [encodeHtml]="false"
          [width]="250">
        </dxi-column>

        <!-- Templates -->
        <div *dxTemplate="let cell of 'cellUser'">
          <div [innerHtml]="(cell.data.creation.user | getNameData | async).avatarName | safeHtml"></div>
        </div>

        <div *dxTemplate="let cell of 'subjectWithAttachment'">
          <i *ngIf="cell.data.document | hasAttachment" class="material-icons md-16">attach_file</i>
        </div>
      </dx-data-grid>
    </div>
  </div>
</dx-popup>
<!-- ----------------------------------------------------------------------------------------------- -->
<!-- Popup für ChildrenTopsliste -->
<!-- ----------------------------------------------------------------------------------------------- -->
<dx-popup
  width="75vw"
  shadingColor="rgba(0, 0, 50, 0.5)"
  [(visible)]="popupchildrenTopsListVisible"
  [height]="600"
  [showTitle]="true"
  [title]="popupChildrenTopsListTitle"
  [showCloseButton]="false"
  [fullScreen]="false"
  [dragEnabled]="true"
  [hideOnOutsideClick]="false"
  [shading]="true"
  [toolbarItems]="[
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { icon: 'add', text: 'Auf Agenda nehmen', visible: 'true' },
      onClick: popupChildrenTopsListOk
    },
    {
      name: 'Delete',
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { icon: 'clear', text: 'Verknüpfung lösen' },
      onClick: popupChildrenTopDeleteReference
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { type: 'normal', icon: 'close', text: 'Abbrechen' },
      onClick: popupChildrenTopsListCancel
    }
  ]">
  <div *dxTemplate="let data of 'content'">
    <app-component-notification></app-component-notification>
    <div>
      <dx-data-grid
        #childrenTopSelectionGrid
        id="childrenTopSelectionGrid"
        [dataSource]="childrenTopsList"
        [height]="460"
        [showBorders]="true"
        [showColumnLines]="true"
        [allowColumnResizing]="true"
        [hoverStateEnabled]="false"
        [selectedRowKeys]="[]"
        [columnAutoWidth]="true"
        (onCellHoverChanged)="onShowToolTipColumn($event)">
        <!-- Grid Editing settings -->
        <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxo-scrolling mode="standard"></dxo-scrolling>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-filter-row [visible]="true"></dxo-filter-row>

        <!-- columns -->
        <dxi-column cellTemplate="subjectWithAttachment" [allowHeaderFiltering]="false" [width]="30" alignment="center">
        </dxi-column>
        <dxi-column caption="Thema" dataField="subject" sortOrder="asc" [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Erstellt am"
          dataField="document.creation.timestamp"
          dataType="date"
          [width]="100"
          [format]="{ type: 'dd.MM.yyyy' }"
          [allowFiltering]="false"></dxi-column>

        <dxi-column
          caption="Erstellt durch"
          dataField="creatordata"
          [allowSorting]="true"
          [allowHeaderFiltering]="true"
          [allowFiltering]="false"
          [encodeHtml]="false"
          [width]="250">
        </dxi-column>

        <!-- Templates -->
        <div *dxTemplate="let cell of 'cellUser'">
          <div [innerHtml]="(cell.data.creation.user | getNameData | async).avatarName | safeHtml"></div>
        </div>

        <div *dxTemplate="let cell of 'subjectWithAttachment'">
          <i *ngIf="cell.data.document | hasAttachment" class="material-icons md-16">attach_file</i>
        </div>
      </dx-data-grid>
    </div>
  </div>
</dx-popup>

<!-- ----------------------------------------------------------------------------------------------- -->
<!-- Popup für AlteTopsliste -->
<!-- ----------------------------------------------------------------------------------------------- -->
<dx-popup
  width="75vw"
  shadingColor="rgba(0, 0, 50, 0.5)"
  [(visible)]="popupOldTopsListVisible"
  [height]="600"
  [showTitle]="true"
  [title]="popupFreeTopsListTitle"
  [showCloseButton]="false"
  [fullScreen]="false"
  [dragEnabled]="true"
  [hideOnOutsideClick]="false"
  [shading]="true"
  [toolbarItems]="[
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { icon: 'add', text: 'Kopie auf Agenda nehmen', visible: 'true' },
      onClick: popupOldTopsListOk
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { type: 'normal', icon: 'close', text: 'Abbrechen' },
      onClick: popupOldTopsListCancel
    }
  ]">
  <div *dxTemplate="let data of 'content'">
    <app-component-notification></app-component-notification>
    <div>
      <dx-data-grid
        #freeTopSelectionGrid
        [dataSource]="oldTopsList"
        [height]="460"
        [showBorders]="true"
        [showColumnLines]="true"
        [allowColumnResizing]="true"
        [hoverStateEnabled]="false"
        [selectedRowKeys]="[]"
        [columnAutoWidth]="true"
        (onContentReady)="onContentReadyOldTops($event)"
        (onToolbarPreparing)="onToolbarPreparingOldTops($event)"
        (onCellHoverChanged)="onShowToolTipColumn($event)">
        <!-- Grid Editing settings -->
        <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxo-scrolling mode="standard"></dxo-scrolling>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-group-panel [visible]="true"></dxo-group-panel>
        <dxo-grouping [autoExpandAll]="expanded" expandMode="buttonClick"></dxo-grouping>

        <!-- columns -->
        <dxi-column
          dataField="agendaType"
          sortOrder="asc"
          groupCellTemplate="categoryGroup"
          caption="Sitzungstyp"
          [width]="'auto'"
          [groupIndex]="0"
          [sortIndex]="0"></dxi-column>
        <dxi-column
          dataField="agendaSubject"
          sortOrder="asc"
          groupCellTemplate="categoryGroup"
          caption="Sitzungsthema"
          [width]="'auto'"
          [groupIndex]="1"></dxi-column>

        <dxi-column
          cellTemplate="subjectWithAttachment"
          [allowHeaderFiltering]="false"
          [width]="30"
          alignment="center"
          [allowHeaderFiltering]="false"></dxi-column>
        <dxi-column caption="Thema" dataField="subject" [allowHeaderFiltering]="false"></dxi-column>

        <dxi-column
          caption="Erstellt am"
          dataField="document.creation.timestamp"
          dataType="date"
          [width]="100"
          [format]="{ type: 'dd.MM.yyyy' }"
          [allowFiltering]="false"></dxi-column>

        <dxi-column
          caption="Erstellt durch"
          dataField="creatordata"
          [allowSorting]="true"
          [allowHeaderFiltering]="true"
          [allowFiltering]="false"
          [encodeHtml]="false"
          [width]="250">
        </dxi-column>

        <!-- Templates -->
        <div *dxTemplate="let cell of 'cellUser'">
          <div [innerHtml]="(cell.data.creation.user | getNameData | async).avatarName | safeHtml"></div>
        </div>

        <div *dxTemplate="let cell of 'subjectWithAttachment'">
          <i *ngIf="cell.data.document | hasAttachment" class="material-icons md-16">attach_file</i>
        </div>
        <div *dxTemplate="let cell of 'categoryGroup'">
          <div i18n class="list-categories">
            {{ cell.text ? cell.text : '(nicht kategorisiert)' }}
          </div>
        </div>
      </dx-data-grid>
    </div>
  </div>
</dx-popup>
<dx-popover #popOverCreationColumn id="popOverCreationColumn" [(target)]="popoverTarget" [width]="200">
  <div *dxTemplate="let data of 'content'">
    <span [innerHtml]="popOverText | safeHtml"></span>
  </div>
</dx-popover>

<!-- ----------------------------------------------------------------------------------------------- -->
<!-- Popup für Beschlussdialog -->
<!-- ----------------------------------------------------------------------------------------------- -->
<app-approval-dialog
  [currentDocument]="selectedAgendaItemDocument"
  (dialogHidden)="approvalDialogClose()"
  (dialogResult)="approvalDialogResult($event)">
</app-approval-dialog>

<!-- ----------------------------------------------------------------------------------------------- -->
<!-- Popup für Userauswahldialog -->
<!-- ----------------------------------------------------------------------------------------------- -->
<app-name-selector (dialogResult)="nameSelectorResult($event)" [requiredSelection]="false"></app-name-selector>
<app-confirm-popup
  (dialogHidden)="confirmPopupDeleteClose()"
  (dialogResult)="confirmPopupDeleteResult()"
  (dialogResultExtra)="confirmPopupDeleteResultExtra()">
</app-confirm-popup>
