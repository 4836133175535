import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, share, tap } from 'rxjs/operators';
import { TemplateClient } from '@interfaces/templateClient';
import {
  IConfidentialPermission,
  IPermissionTarget,
  ISelectableNames,
  TEffectivePermission,
  TTag
} from '@interfaces/siam';
import { ITemplateServer } from '@interfaces/ITemplateServer';
import { Apollo, gql } from 'apollo-angular';
import { LoggerService } from './logger.service';

interface GraphqlData {
  templates?: ITemplateServer[];
  template?: ITemplateServer;
}

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  private apiBaseUrl: string;
  private SelectableNamesCache = new Map<string, IPermissionTarget[]>(); // In-memory cache

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private apollo: Apollo,
    @Inject('BASE_URL') private baseUrl: string
  ) {
    this.apiBaseUrl = `${this.baseUrl}api`;
    logger.info('Template Service');
  }

  /**
   * Only for header container for Dropdown menu to create new Document
   */
  getExecutableTemplates(permission: string, tags?: TTag[]): Observable<ITemplateServer[]> {
    const query = gql`
      query ($permission: String!, $tags: [String!]) {
        templates(permission: $permission, tags: $tags) {
          id
          caption
          description
          isDisabled
          tags
        }
      }
    `;

    return this.apollo
      .query<GraphqlData>({
        query,
        variables: {
          permission,
          tags
        }
      })
      .pipe(map(({ data }) => data.templates.filter(template => !template.isDisabled)));
  }

  getTemplates(permission: TEffectivePermission, tags?: TTag[]): Observable<ITemplateServer[]> {
    let params = new HttpParams();
    params = params.set('permission', permission);
    if (tags) {
      tags.forEach(tag => {
        params = params.append('tags', tag);
      });
    }
    return this.http.get<ITemplateServer[]>(`${this.apiBaseUrl}/templates`, { params }).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Erhalten des Templates: {@error}', error);
        }
      })
    );
  }

  getActiveTemplates(permission: TEffectivePermission, tags?: TTag[]): Observable<ITemplateServer[]> {
    return this.getTemplates(permission, tags).pipe(
      map(templates => templates.filter(temp => !temp.isDisabled && !temp.name.toLowerCase().includes('migration')))
    );
  }

  getTemplate(guid: string): Observable<ITemplateServer> {
    return this.http.get<ITemplateServer>(`${this.apiBaseUrl}/templates/${guid}`).pipe(
      tap({
        error: error => {
          this.logger.error('Fehler beim Erhalten des Templates: {@error}', error);
        }
      })
    );
  }

  saveTemplate(serv: ITemplateServer): Observable<ITemplateServer> {
    const headers = new HttpHeaders({
      accept: 'text/json'
    });
    return this.http.post<ITemplateServer>(`${this.apiBaseUrl}/templates`, serv, { headers }).pipe(
      tap({
        error: error => {
          this.logger.error(`saveTemplate: Fehler beim Speichern des Templates: {@error}`, error);
        }
      })
    );
  }

  deleteTemplate(guid: string): Observable<TemplateClient> {
    return this.http.delete<TemplateClient>(`${this.apiBaseUrl}/templates/${guid}`).pipe(
      tap({
        next: document => {
          this.logger.info('deleteTemplate: Template löschen = {@document}', document);
        },
        error: error => {
          this.logger.error(`deleteTemplate: Fehler beim Löschen des Templates: {@error}`, error);
        }
      })
    );
  }

  /**
   * getting the template permission for current login user
   *
   * @param guid documentid
   */
  templatePermissions(guid: string): Observable<TEffectivePermission[]> {
    return this.http.options<TEffectivePermission[]>(`${this.apiBaseUrl}/templates/${guid}`).pipe(
      tap({
        error: error => {
          this.logger.error(`templatePermissions: Fehler beim Erhalten des Templatepermissions: {@error}`, error);
        }
      })
    );
  }

  getTemplateConfidential(templateId: string): Observable<IConfidentialPermission[]> {
    const query = gql`{
      template(id: "${templateId}") {
        confidentialAllowed(forUser: true) {
          allowHardening
          allowWeakening
          confidentialId
          confidential {
            name
            parentIds
          }
          ...cnfDefault
        }
      }
    }
    fragment cnfDefault on ConfidentialReference {
      isDefault
    }`;

    return this.apollo
      .query<GraphqlData>({
        query
      })
      .pipe(
        map(({ data }) => data.template?.confidentialAllowed),
        share()
      );
  }

  getSelectableNames(data: ISelectableNames): Observable<IPermissionTarget[]> {
    const cacheKey = this.generateCacheKey(data);

    if (this.SelectableNamesCache.has(cacheKey)) {
      return of(this.SelectableNamesCache.get(cacheKey));
    }

    const headers = new HttpHeaders({
      accept: 'text/json'
    });
    return this.http.post<IPermissionTarget[]>(`${this.apiBaseUrl}/info/selectable-names`, data, { headers }).pipe(
      tap({
        next: response => {
          this.SelectableNamesCache.set(cacheKey, response);
        },
        error: error => {
          this.logger.error(`getSelectableNames: Fehler beim Holen von SelectableNames: {@error}`, error);
        }
      })
    );
  }
  private generateCacheKey(data: ISelectableNames): string {
    const includesKey = this.generateArrayKey(data.includes);
    const excludesKey = this.generateArrayKey(data.excludes);

    return `includes:${includesKey}|excludes:${excludesKey}|selection:${data.selection}`;
  }

  private generateArrayKey(array: IPermissionTarget[]): string {
    return array
      .map(target => `${target.type}:${target.targetId}`)
      .sort()
      .join(',');
  }
}
