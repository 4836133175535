<nav class="navbar navbar-expand-md">
  <!-- linke  Seite -->
  <div class="navbar-brand">
    <a [routerLink]="['', 'application', 'home']">
      <div *ngIf="svgLogo" class="app-logo" id="agilicisionLogo" [innerHTML]="svgLogo"></div>
      <img *ngIf="!svgLogo" class="app-logo" [src]="pngLogo" alt="AgiliCision Logo" id="agilicisionLogo" />
      <dx-tooltip target=".app-logo" position="right" showEvent="dxhoverstart" hideEvent="dxhoverend">
        <div *dxTemplate="let data of 'content'">Eine Anwendung der ventuno GmbH</div>
      </dx-tooltip>
    </a>
    <!-- Firmen Name -->
    <div class="company-name">
      <span>{{ sharedData.appTitle$ | async }}</span>
    </div>
  </div>

  <i *ngIf="buildMode !== 'Produktion'" class="material-icons md-16 application-mode">
    developer_mode
    <dx-tooltip target=".application-mode" position="right" showEvent="dxhoverstart" hideEvent="dxhoverend">
      <div *dxTemplate="let data = data; of: 'content'">Es handelt sich um ein {{ buildMode }}-Build</div>
    </dx-tooltip>
  </i>

  <button
    class="navbar-toggler collapsed"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarsExampleDefault"
    aria-expanded="false"
    aria-label="Toggle navigation">
    <i class="material-icons">more_vert</i>
  </button>

  <!-- Iconbereich in der Kopfzeile rechte Seite -->
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav">
      <!-- Hinweis, wenn im Konfigurationsbereich -->
      <div *ngIf="applicationMode === 'administration'" class="mode-name">Konfigurationsbereich</div>
      <!-- Hinweis, wenn im Vertretung -->
      <div *ngIf="isImpersonated" class="mode-name">Vertretung von {{ impersonatedUser?.fullName }}</div>

      <li
        class="nav-item"
        *ngIf="applicationMode !== 'administration' && (homePageSetting ? homePageSetting.showSearchButton : true)">
        <a class="nav-link" [routerLink]="['', 'application', 'search']" id="searchmenu">
          <i class="material-icons">search</i>
        </a>
        <dx-tooltip target="#searchmenu" position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend">
          <div *dxTemplate="let data of 'content'">Suche</div>
        </dx-tooltip>
      </li>

      <li
        class="nav-item"
        *ngIf="applicationMode !== 'administration' && (homePageSetting ? homePageSetting.showSearchButton : true)">
        <a class="nav-link notification" (click)="showNotifications()" id="notification">
          <i class="material-icons">notifications</i>
          <span *ngIf="notificationsCount > 0" class="badge">{{ notificationsCount }}</span>
        </a>
        <dx-tooltip target="#notification" position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend">
          <div *dxTemplate="let data of 'content'">Benachrichtigungen</div>
        </dx-tooltip>
      </li>

      <li
        class="nav-item"
        *ngIf="applicationMode !== 'administration' && (homePageSetting ? homePageSetting.showFeedbackButton : true)">
        <a class="nav-link" (click)="showFeedback()" id="feedbackmenu">
          <i class="material-icons">feedback</i>
        </a>
        <dx-tooltip target="#feedbackmenu" position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend">
          <div *dxTemplate="let data of 'content'">Feedback</div>
        </dx-tooltip>
      </li>

      <li
        class="nav-item"
        *ngIf="applicationMode !== 'administration' && (homePageSetting ? homePageSetting.showHelpButton : true)">
        <a class="nav-link" (click)="showHelp()" id="helpmenu">
          <i class="material-icons">question_mark</i>
        </a>
        <dx-tooltip target="#helpmenu" position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend">
          <div *dxTemplate="let data of 'content'">Hilfe</div>
        </dx-tooltip>
      </li>

      <li class="nav-item" *ngIf="isAdminPanelAllowed">
        <a class="nav-link" [routerLink]="['', 'administration', adminLink]" id="configmenu1">
          <i class="material-icons">settings</i>
        </a>
        <dx-tooltip target="#configmenu1" position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend">
          <div *dxTemplate="let data of 'content'">Konfiguration</div>
        </dx-tooltip>
      </li>

      <li class="nav-item" *ngIf="applicationMode === 'administration'">
        <a class="nav-link" [routerLink]="['', 'application', 'home']" id="configmenu2">
          <i class="material-icons">home</i>
        </a>
        <dx-tooltip target="#configmenu2" position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend">
          <div *dxTemplate="let data of 'content'">zurück in den Anwenderbereich</div>
        </dx-tooltip>
      </li>

      <li class="nav-item dropdown">
        <a
          *ngIf="logonUser"
          class="nav-link"
          id="usermenu"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          [innerHtml]="(logonUser | getNameData | async)?.avatar | safeHtml">
        </a>
        <dx-tooltip target="#usermenu" position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend">
          <div *dxTemplate="let data of 'content'">{{ (logonUser | getNameData | async)?.name }}</div>
        </dx-tooltip>

        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="usermenu">
          <h6 class="dropdown-header" [innerHtml]="(logonUser | getNameData | async)?.avatarNameBig | safeHtml"></h6>

          <div class="dropdown-divider"></div>

          <a i18n class="dropdown-item" (click)="showUserProfile = !showUserProfile">
            <i class="material-icons mr-2">folder_shared</i>Profil
          </a>
          <a i18n *ngIf="hasCandidates && applicationMode !== 'administration'" class="dropdown-item" (click)="showSubstitution()">
            <i class="material-icons mr-2">supervisor_account</i>Vertretung
          </a>

          <a i18n *ngIf="!isThemeGlobalOnly || !isCustomBackground" class="dropdown-item" (click)="showUserSettings()">
            <i class="material-icons mr-2">palette</i>Einstellungen
          </a>

          <div class="dropdown-divider"></div>

          <a i18n class="dropdown-item" (click)="logOut()"> <i class="material-icons mr-2">exit_to_app</i>Abmelden </a>
        </div>
      </li>
    </ul>
  </div>
</nav>

<dx-popup
  [(visible)]="showUserProfile"
  [showTitle]="false"
  [showCloseButton]="false"
  [fullScreen]="false"
  height="'auto'"
  [toolbarItems]="[
    {
      widget: 'dxButton',
      location: 'before',
      options: { type: 'normal', icon: 'material-icons md-24 folder_shared', elementAttr: { class: 'button-icon' } }
    },
    { text: 'Benutzerdaten bearbeiten', location: 'before', toolbar: 'top' },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'top',
      visible: true,
      options: { type: 'normal', icon: 'close', hint: 'Schließen' },
      onClick: cancelUserProfile
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { text: 'Kennwort ändern', icon: 'material-icons verified_user', visible: !ldapUser },
      onClick: changePassword
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { text: 'Speichern', icon: 'check' },
      onClick: saveUserProfile
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { text: 'Abbrechen', icon: 'close' },
      onClick: cancelUserProfile
    }
  ]">
  <div *dxTemplate="let data of 'content'">
    <app-user-profile [currentUser]="logonUser" [user]="logonUser" [isMyProfile]="true"></app-user-profile>
  </div>
</dx-popup>

<dx-popup
  #popupGame
  [(visible)]="showGame"
  [showTitle]="false"
  height="'auto'"
  width="'auto'"
  shadingColor="rgba(0, 0, 0, 0.9)"
  [wrapperAttr]="{ class: 'game-popup' }"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  (onShown)="setFocus($event)">
  <div *dxTemplate="let data of 'content'">
    <iframe width="550px" height="630" frameBorder="0" [src]="gameUrl" id="gameFrame"></iframe>
  </div>
</dx-popup>

<app-user-settings></app-user-settings>
<app-feedback></app-feedback>
<app-substitutes [currentUser]="currentUser"></app-substitutes>
<app-help></app-help>
<app-notifications-list></app-notifications-list>
