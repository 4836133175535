import { Component, EventEmitter, forwardRef, NgZone, Output, ViewChild } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular';
import { IDocument, IEventEmitter } from '@interfaces/siam';
import { LoggerService } from '@services/logger.service';
import { ComponentNotificationComponent } from '@components/notify/component-notification/component-notification.component';
import { DocumentIncludeComponent } from '../../../application/components/forms/subforms/document-include/document-include.component';
import { DocumentService } from '@services/document.service';
import { confirm } from 'devextreme/ui/dialog';
import { NotifyService } from '@services/notify.service';
import { isHasProperty } from '@factories/helpers';

export class WorkFlowDialogSettings {
  title? = 'WorkFlow Dialog';
  fullScreen? = false;
  readonly? = false;
  createDocumentInputName?: string = null;
  createDecisionInputName?: string = null;
  updateDecisionInputName?: string = null;
  parentDocumentId?: string = null;
  cloneFields? = false;
  showEditor? = true;
}

@Component({
  selector: 'app-workflow-dialog',
  templateUrl: './workflow-dialog.component.html',
  styleUrls: ['./workflow-dialog.component.scss']
})
export class WorkflowDialogComponent {
  @ViewChild(forwardRef(() => DocumentIncludeComponent)) documentInclude: DocumentIncludeComponent;
  @ViewChild(forwardRef(() => ComponentNotificationComponent)) notifComponent: ComponentNotificationComponent;
  @ViewChild('workflowDialog', { static: true }) dialogEditor: DxPopupComponent;

  @Output() dialogResult = new EventEmitter<IEventEmitter<IDocument>>();
  @Output() dialogHidden = new EventEmitter<boolean>();

  dialogDocument: IDocument = null;
  parentDocumentId: string = null;
  dialogTitle: string;
  dialogFullscreen: boolean;
  dialogReadOnly: boolean;
  dialogVisible = false;
  createDocumentInputName: string;
  createDecisionInputName: string;
  updateDecisionInputName: string;

  constructor(private logger: LoggerService, private documentService: DocumentService, private zone: NgZone) {}

  public show = (document: IDocument, parent: IDocument, settings?: WorkFlowDialogSettings): void => {
    if (!isHasProperty(settings, 'showEditor') && !isHasProperty(settings, 'cloneFields')) {
      this.open(document, settings);
      return;
    }
    if (settings.cloneFields) {
      if (parent && this.documentService.checkMatchingFields(document, parent)) {
        void this.zone.run(async () => {
          try {
            this.dialogDocument = await this.documentService.createFromParent(document, parent);
            this.open(document, settings);
          } catch (error) {
            this.logger.error('document-editor.createDocument():createFromParent(): {@error}:', error);

            NotifyService.global.error(
              `Das Übernehmen der Inhalte ist fehlgeschlagen, die Erstellung des Vorgangs wurde abgebrochen. Möglicherweise liegt ein Konfigurationsfehler im entsprechenden Dokumenttyp vor!
            <br> ${(error as TypeError).message}`
            );
          }
        });
      } else {
        this.open(document, settings);
      }
    } else {
      this.open(document, settings);
    }
  };
  public open = (document: IDocument, settings?: WorkFlowDialogSettings): void => {
    this.logger.debug('workflow-dialog.show() aufgerufen.');
    if (!document) {
      this.logger.error('Es wurde kein Formulardokument übergeben.');
      return;
    }
    this.dialogDocument = null;
    setTimeout(() => {
      this.dialogDocument = document;
    }, 100);
    this.logger.debug('übergebenes Dokument {@0}', this.dialogDocument);
    if (settings) {
      this.dialogReadOnly = settings.fullScreen || false;
      this.dialogTitle = settings.title ? settings.title : document.template.caption;
      this.dialogFullscreen = settings.fullScreen ? settings.fullScreen : false;
      this.createDocumentInputName = settings.createDocumentInputName ? settings.createDocumentInputName : null;
      this.createDecisionInputName = settings.createDecisionInputName ? settings.createDecisionInputName : null;
      this.updateDecisionInputName = settings.updateDecisionInputName ? settings.updateDecisionInputName : null;
      this.parentDocumentId = settings.parentDocumentId ? settings.parentDocumentId : null;
    }
    if (!isHasProperty(settings, 'showEditor')) {
      this.dialogVisible = true;
      return;
    }
    if (settings.showEditor) {
      this.dialogVisible = true;
    } else {
      this.emitResult(document);
    }
  };

  dialogOk = (): void => {
    this.documentInclude.prepareForSave$().subscribe(doc => {
      this.emitResult(doc);
    });
  };

  emitResult = (doc: IDocument): void => {
    this.dialogResult.emit({
      command: 'save',
      object: doc,
      variableCreateDocumentName: this.createDocumentInputName,
      variableCreateDecisionName: this.createDecisionInputName,
      variableUpdateDecisionName: this.updateDecisionInputName
    });
    this.onDialogHidden();
  };

  dialogCancel = (): void => {
    this.onDialogHidden();
    this.dialogHidden.emit(false);
  };
  onDialogHidden = (): void => {
    this.logger.debug('ondialogHidden() called, clearing popup-data');
    this.notifComponent.closeComponentNotifications();
    this.dialogVisible = false;
    this.dialogDocument = null;
  };
  confirmDialogBox(): Promise<boolean> {
    return confirm(`Sollen Inhalte des Vorgangs übernommen werden?`, `Erstellung der ${this.dialogTitle}`);
  }
}
