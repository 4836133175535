import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ILoadPanelSettings } from '@interfaces/siam';
import { LoadPanelService } from '@services/load-panel.service';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-loading-panel',
  templateUrl: './loading-panel.component.html',
  styleUrls: ['./loading-panel.component.scss']
})
export class LoadingPanelComponent implements OnDestroy, AfterViewChecked {
  readonly panel: Observable<ILoadPanelSettings>;

  #destroyable$ = new Subject<void>();

  constructor(private readonly changeDetectorRef: ChangeDetectorRef, private loadPanelService: LoadPanelService) {
    this.panel = this.loadPanelService.loadPanelState$;
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.#destroyable$.next();
    this.#destroyable$.complete();
  }
}
