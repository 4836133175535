import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { confirm } from 'devextreme/ui/dialog';
import { ComponentNotificationComponent } from 'src/app/_core/components/notify/component-notification/component-notification.component';
import { DocumentService } from '@services/document.service';
import { ToolbarItem } from '@services/popup-helper.service';
import { FieldClient } from '@interfaces/fieldClient';
import { IApprovalForm, IDocument, IEventVisibleCategorie, TEditMode } from '@interfaces/siam';
import { OldDecisionIncludeComponent } from '../../forms/subforms/old-decision-include/old-decision-include.component';

@Component({
  selector: 'app-approval-dialog',
  templateUrl: './approval-dialog.component.html',
  styleUrls: ['./approval-dialog.component.scss']
})
export class ApprovalDialogComponent implements OnInit, OnDestroy {
  @ViewChild(OldDecisionIncludeComponent, { static: false }) approvalComponent: OldDecisionIncludeComponent;
  @ViewChild(ComponentNotificationComponent) notificationComponent: ComponentNotificationComponent;

  @Input() currentDocument: IDocument;
  @Input() editMode: TEditMode = 'ReadOnly';

  @Output() dialogHidden = new EventEmitter<boolean>();
  @Output() dialogResult = new EventEmitter<IApprovalForm>();

  categorieExist = true;
  approvalDocument: IDocument = null;
  dialogOpen = false;
  isFullscreen = false;
  approvalFormItems: FieldClient[] = [];
  toolbarItems: ToolbarItem[];
  private destroyed$ = new Subject<void>();

  constructor(private documentService: DocumentService) {}

  ngOnInit(): void {
    this.toolbarItems = [];
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
  approve(doc: IDocument, editMode?: TEditMode): void {
    this.approvalDocument = doc;
    this.dialogOpen = true;
    this.editMode = editMode ? editMode : 'Edit';
    this.updateToolbar();
  }

  updateToolbar(): void {
    this.toolbarItems = [
      {
        name: 'Approve',
        widget: 'dxButton',
        location: 'before',
        toolbar: 'top',
        visible: true,
        options: {
          type: 'normal',
          icon: 'material-icons md-24 bookmark',
          elementAttr: {
            class: 'button-icon'
          }
        }
      },
      {
        name: 'Title',
        text:
          'Beschlussmarkierung' +
          (this.approvalDocument.template ? ' für ' + this.approvalDocument.template.caption : ''),
        location: 'before',
        toolbar: 'top'
      },
      {
        name: 'Unselect',
        widget: 'dxButton',
        location: 'after',
        toolbar: 'top',
        visible: true,
        options: {
          type: 'normal',
          icon: 'fullscreen',
          hint: 'Vollbildmodus umschalten'
        },
        onClick: this.toogleFullscreen
      },
      {
        name: 'Delete',
        widget: 'dxButton',
        location: 'after',
        toolbar: 'bottom',
        options: {
          text: 'Zurücksetzen',
          type: 'normal',
          icon: 'clear'
        },
        visible: this.documentService.isDecision(this.approvalDocument) && this.editMode === 'Edit',
        onClick: this.deleteApproval
      },
      {
        name: 'Ok',
        widget: 'dxButton',
        location: 'after',
        toolbar: 'bottom',
        options: {
          text: 'Ok',
          icon: 'check'
        },
        visible: this.categorieExist && this.editMode === 'Edit',
        onClick: this.saveApproval
      },
      {
        name: 'Cancel',
        widget: 'dxButton',
        location: 'after',
        toolbar: 'bottom',
        options: {
          text: this.editMode === 'Edit' ? 'Abbrechen' : 'Schließen',
          icon: 'close'
        },
        onClick: this.cancelApproval
      }
    ];
  }

  toogleFullscreen = (): void => {
    this.isFullscreen = !this.isFullscreen;
  };

  eventvisibleResult(event: IEventVisibleCategorie): void {
    this.categorieExist = event.visible;
    this.updateToolbar();
  }

  cancelApproval = (): void => {
    this.dialogOpen = false;
    this.dialogHidden.emit(false);
    this.approvalDocument = null;
    if (this.editMode === 'Edit') {
      this.notificationComponent.closeComponentNotifications();
    }
  };

  saveApproval = (): void => {
    const result = this.approvalComponent.approvalForm.instance.validate();
    if (!result.isValid) {
      return;
    }

    this.approvalDocument = null;
    this.dialogResult.emit(this.approvalComponent.approvalFormData);
    this.dialogOpen = false;
  };

  deleteApproval = (): void => {
    void confirm(
      'Möchten Sie diese Beschlussmarkierung wirklich zurücksetzen?',
      'Beschlussmarkierung zurücksetzen'
    ).then(dialogResult => {
      if (dialogResult) {
        this.dialogOpen = false;
        this.approvalDocument = null;
        this.dialogResult.emit(null);
      }
    });
  };
}
