import { Component, OnDestroy } from '@angular/core';
import { of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplateService } from '@services/template.service';
import { LoadPanelService } from '@services/load-panel.service';

@Component({
  selector: 'app-dynamic-view',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnDestroy {

  notFound = false;

  #documentName: string;
  #destroyable = new Subject<void>();
  #templateId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private loadPanelService: LoadPanelService,
    private router: Router,
    private templateService: TemplateService,
  ) {
    this.loadPanelService.show();
    this.activatedRoute.paramMap
      .pipe(
        switchMap(paramMap => {
          this.#documentName = paramMap.get('name');
          return this.templateService.getTemplates('list');
        }),
        switchMap(templates => {
          if (this.#documentName) {
            const name = this.#documentName.toLowerCase();
            const template = templates.find(template => template.name.toLowerCase() === name);
            if (template) {
              this.#templateId = template.id;
              return this.templateService.templatePermissions(template.id);
            }
          }
          return of([]);
        }),
        map(permissions => !!permissions.find(x => x === 'execute')),
        takeUntil(this.#destroyable)
      )
      .subscribe({
        next: isAllowed => {
          this.loadPanelService.hide();
          if (isAllowed) {
            void this.router.navigate(['application', 'template', this.#templateId]);
            return;
          }
          this.notFound = true;
        },
        error: () => {
          this.loadPanelService.hide();
        }
      });
  }

  ngOnDestroy(): void {
    this.#destroyable.next();
    this.#destroyable.complete();
  }
}
