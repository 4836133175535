import { RoleAdapter, RoleState } from '../entities/role.entity';
import { RoleActions, RoleActionTypes } from '../actions';

export const initialState: RoleState = RoleAdapter.getInitialState({
});

export const reducer = (state = initialState, action: RoleActions): RoleState => {

  switch (action.type) {

    case RoleActionTypes.CreateRole:
      return RoleAdapter.addOne(action.payload.role, state);

    case RoleActionTypes.DeleteAllRoles:
      return RoleAdapter.removeAll(state);

    case RoleActionTypes.DeleteRole:
      return RoleAdapter.removeOne(action.payload.role.id, state);

    case RoleActionTypes.UpdateRole:
      return RoleAdapter.updateOne({id: action.payload.role.id, changes: action.payload.role}, state);

    case RoleActionTypes.SetRoles:
      return RoleAdapter.upsertMany(action.payload.roles, state);

    default:
      return state;
  }
}
