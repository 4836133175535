<dx-tab-panel
  *ngIf="decisionDataSource?.length"
  id="decisionsTabPanel"
  [dataSource]="decisionDataSource"
  [selectedIndex]="decisionDataSource[0]"
  [animationEnabled]="false">
  <div *dxTemplate="let tab of 'title'">
    <span class="label" [ngStyle]="{ 'background-color': tab.color, opacity: tab.isActive ? '1' : '0.5' }">
      <i class="material-icons md-18">bookmark</i>
      <span>{{ tab.title }}</span>
    </span>
    <div class="tab-caption">{{ tab.caption }}</div>
  </div>

  <div *dxTemplate="let tab of 'item'">
    <app-document-include [currentDocument]="tab.document" [editMode]="'ReadOnly'" [childElement]="true">
    </app-document-include>
  </div>
</dx-tab-panel>
