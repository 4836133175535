import { Component, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DxPopupComponent, DxSchedulerComponent } from 'devextreme-angular';
import { DocumentService } from '@services/document.service';
import { map } from 'rxjs/operators';
import { DocumentHelperService } from '@services/document-helper.service';
import { IDocument, IDocumentsSearch, IDynamicCard, SiamListItem } from '@interfaces/siam';
import { forkJoin, lastValueFrom, Observable, of, Subscription } from 'rxjs';
import { LoggerService } from '@services/logger.service';
import CustomStore from 'devextreme/data/custom_store';
import { clone, combineQueries, getDate, isHasProperty, queryAdapter } from '@factories/helpers';
import DevExpress from 'devextreme';
import { AppointmentDblClickEvent, AppointmentFormOpeningEvent } from 'devextreme/ui/scheduler';
import { ToolbarItem } from '@services/popup-helper.service';
import { NotifyService } from '@services/notify.service';
import LoadOptions = DevExpress.data.LoadOptions;
import { SiamList } from '@interfaces/siamList';

interface IScheduler {
  type: string;
  typeLabel: string;
  allDay: boolean;
  disabled: boolean;
  startDate: Date;
  endDate: Date;
  text: string;
  color?: string;
  description: string;
  visible: boolean;
  priority?: string;
  document: IDocument;
  group?: number;
}

interface ISchedulerType {
  text: string;
  id: number;
  color: string;
}

interface AggregatedData {
  totalCountSubmissions: number;
  totalCountTasks: number;
  totalCountAgendas: number;
}

interface ILoadOptions {
  endDate: string;
  startDate: string;
}

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss']
})
export class SchedulerComponent implements OnInit, OnDestroy {
  @ViewChild('scheduler', { static: false }) scheduler: DxSchedulerComponent;
  @ViewChild('popupScheduler', { static: true }) popupScheduler: DxPopupComponent;

  @Input() card: IDynamicCard;
  @Input() events: Observable<void>;

  @Output() aggregatedData: AggregatedData;

  cardPopup: IDynamicCard;
  infoPopupToolbarItems: ToolbarItem[];
  showInfoPopup = false;

  currentDocument: IDocument;
  currentDate = new Date();
  dataSource: CustomStore;
  schedulerResource: ISchedulerType[] = [];
  #eventsSubscription: Subscription;

  constructor(
    private documentService: DocumentService,
    private documentHelperService: DocumentHelperService,
    private logger: LoggerService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.#eventsSubscription = this.events.subscribe(() => {
      setTimeout(() => {
        this.scheduler?.instance?.repaint();
      }, 100);
    });
  }

  ngOnDestroy(): void {
    this.#eventsSubscription.unsubscribe();
  }

  getData(): void {
    if (this.card) {
      this.schedulerResource = [];
      for (let index = 0; index < this.card.schedulerSettings.selectors.length; index++) {
        const selector = this.card.schedulerSettings.selectors[index];
        this.schedulerResource.push({ id: index, text: `Gruppe ${index}`, color: selector.color });
      }
      this.dataSource = new CustomStore({
        key: 'document',
        load: (options: LoadOptions<ILoadOptions>): Promise<unknown> => {
          const adapter = queryAdapter(this.getGroupField);
          const documentFields = [
            'tags',
            'template.caption',
            'fields.subject',
            'fields.priority',
            'creation.user.profile.department',
            'documentWorkflowDocument.currentStatusLabel'
          ];
          const allStream$: Observable<IScheduler[]>[] = [];
          if (!this.card?.schedulerSettings?.selectors?.length) {
            return new Promise(resolve => resolve([]));
          }
          for (let index = 0; index < this.card.schedulerSettings.selectors.length; index++) {
            const selector = this.card.schedulerSettings.selectors[index];
            const startDate = selector.startDate || 'creation.timestamp';
            const endDate = selector.endDate || 'creation.timestamp';
            documentFields.push(startDate);
            documentFields.push(endDate);
            if (selector.subjectField && selector.subjectField?.startsWith('fields')) {
              documentFields.push(selector.subjectField);
            }
            if (selector.bodyField && selector.bodyField?.startsWith('fields')) {
              documentFields.push(selector.bodyField);
            }
            const properties: IDocumentsSearch = {
              tag: null,
              documentFields,
              isIncludeLists: true
            };
            const oStartDate = getDate(options.startDate.toString());
            const oEndDate = getDate(options.endDate.toString());
            properties.query = combineQueries(
              adapter(selector.selector),
              `(${startDate}:[${oStartDate} TO ${oEndDate}] || ${endDate}:[${oStartDate} TO ${oEndDate}])`,
              'and'
            );

            const stream$ = this.documentService.documentsSearch(properties).pipe(
              map(data => {
                return data.documents
                  .map(document => {
                    // Terminobjekte erzeugen, falls startDate vorhanden
                    const appointment: IScheduler = {
                      type: this.documentService.getTypeName(document),
                      typeLabel: this.documentService.getTypeLabel(document),
                      allDay: false,
                      disabled: false,
                      startDate: null,
                      endDate: null,
                      text: '{unbekanntes Thema}',
                      description: null,
                      visible: true,
                      priority: document.fields?.priority?.value as string,
                      document,
                      group: index,
                      color: this.schedulerResource[index]?.color
                    };

                    const subjectField = selector.subjectField;

                    if (subjectField) {
                      const subjectFieldValue = this.extractFieldValue(subjectField, document, data.lists) as string;
                      if (subjectFieldValue) {
                        appointment.text = subjectFieldValue;
                      }
                    }

                    const bodyField = selector.bodyField;
                    if (bodyField) {
                      const bodyFieldValue = this.extractFieldValue(bodyField, document, data.lists) as string;
                      if (bodyFieldValue) {
                        appointment.description = bodyFieldValue;
                      }
                    }
                    const startDateValue = this.extractFieldValue(startDate, document, data.lists) as string;
                    const endDateValue = this.extractFieldValue(endDate, document, data.lists) as string;
                    if (startDateValue === endDateValue) {
                      appointment.allDay = true;
                    }
                    if (startDateValue && endDateValue) {
                      appointment.startDate = new Date(startDateValue);
                      appointment.endDate = new Date(endDateValue);
                    } else if (startDateValue && !endDateValue) {
                      appointment.startDate = new Date(startDateValue);
                      appointment.endDate = appointment.startDate;
                    } else if (!startDateValue && endDateValue) {
                      appointment.endDate = new Date(endDateValue);
                      appointment.startDate = appointment.endDate;
                    } else {
                      appointment.startDate = new Date(document.creation.timestamp);
                      appointment.endDate = new Date(document.creation.timestamp);
                    }

                    if (!appointment.startDate) {
                      this.logger.warn('ungültiges Appointment: {@0}', appointment);
                      return null;
                    }
                    return appointment;
                  })
                  .filter(entry => entry !== null);
              })
            );
            allStream$.push(stream$);
          }

          return lastValueFrom(forkJoin(allStream$).pipe(map(d => d.flat())));
        },

        errorHandler: (error: string) => {
          this.logger.error(`Fehler in Dynamische DataGrid:\n {@error}`, JSON.stringify(error));
          this.handleError(error);
        }
      });
    }
  }

  openAppointment(event: AppointmentDblClickEvent): void {
    event.cancel = true;
    this.logger.debug('klickt on: {@0}', event);
    const data = event.appointmentData as IScheduler;
    void this.openDocumentById(data?.document?.id);
  }

  onAppointmentFormOpening(event: AppointmentFormOpeningEvent): void {
    // close default Form
    event.cancel = true;
    const data = event.appointmentData as IScheduler;
    void this.openDocumentById(data?.document?.id);
  }

  openChart(): void {
    this.infoPopupToolbarItems = [
      {
        name: 'TitleIcon',
        widget: 'dxButton',
        location: 'before',
        toolbar: 'top',
        options: {
          type: 'normal',
          icon: 'material-icons calendar_month',
          elementAttr: {
            class: 'button-icon'
          }
        }
      },
      {
        name: 'Title',
        text: this.card.label,
        location: 'before',
        toolbar: 'top',
        visible: true
      },
      {
        name: 'Close',
        widget: 'dxButton',
        location: 'after',
        toolbar: 'top',
        options: { type: 'normal', icon: 'close', hint: 'Schließen' },
        onClick: this.closeInfoPopup
      }
    ];
    this.cardPopup = clone(this.card);
    this.showInfoPopup = true;
  }

  closeInfoPopup = (): void => {
    this.showInfoPopup = false;
    this.cardPopup = null;
  };

  private async openDocumentById(documentId: string): Promise<void> {
    if (!documentId) {
      return lastValueFrom(of(null));
    }
    const document = await lastValueFrom(this.documentService.getDocumentById(documentId));
    if (document) {
      this.documentHelperService.openDocument(document);
    }
  }

  private getGroupField = (sortSelector: string): string => {
    switch (sortSelector) {
      case 'creatorNameWithAvatar':
        return 'creation.userId';

      case 'creation.user.surname':
      case 'creation.user.forename':
        return sortSelector;

      case 'modificationNameWithAvatar':
        return 'last_write.userId';

      case 'currentAssignee':
        return 'documentWorkflowDocument.currentAssignee';

      case 'creatorDepartment':
        return 'creation.user.profile.department';

      case 'creationDate':
        return 'creation.timestamp';

      case 'modificationDate':
        return 'modification.timestamp';

      case 'status':
        return 'documentWorkflowDocument.currentStatusLabel';

      case 'labels':
      case 'configuration-tag':
      case 'configuration-label':
        return 'tags';

      case 'configuration-confidential':
        return 'confidentialId';

      case 'configuration-parent-tag':
        return 'references.parent.tags';
      case 'configuration-child-tag':
        return 'references.child.tags';
        
      case 'configuration-template':
        return 'template.id';

      default: {
        if (sortSelector?.startsWith('document.')) {
          const source = sortSelector.split('.');
          source.shift();
          if (sortSelector.endsWith('.value')) {
            source.pop();
          }
          return source.join('.');
        }
      }
    }
    return null;
  };

  private extractFieldValue = (fieldName: string, document: IDocument, lists?: Record<string, SiamList>): unknown => {
    if (!fieldName || !document) {
      return null;
    }
    const parts = fieldName.split('.');
    let temporaryValue: unknown = document;
    for (const part of parts) {
      if (isHasProperty(temporaryValue, part)) {
        temporaryValue = (temporaryValue as Record<string, unknown>)[part];
      } else {
        temporaryValue = null;
        break;
      }
    }
    if (typeof temporaryValue === 'object' && isHasProperty(temporaryValue, 'value')) {
      if (isHasProperty(temporaryValue, 'listName') && lists) {
        const value = temporaryValue as Record<string, string>;
        return this.extractValueFromList(lists[value.listName]?.entries, value.value) || value.value;
      }
      return (temporaryValue as Record<string, unknown>)?.value;
    }
    return temporaryValue;
  };
  private extractValueFromList(list: SiamListItem[], value: string | string[]): string {
    if (!list) {
      return null;
    }

    if (Array.isArray(value)) {
      const result: string[] = [];
      for (const val of value) {
        const item = list?.find(i => i.value === val);
        result.push(item?.label);
      }
      return result.filter(item => !!item).join(', ');
    }
    return list.find(item => item.value === value)?.label;
  }

  private handleError = (message: string): void => {
    NotifyService.global.error(message);
  };
}
