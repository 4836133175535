import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DndModule } from 'ngx-drag-drop';
import { DevExtremeSharedModule } from './dev-extreme.module';

import { GlobalNotificationComponent } from '@components/notify/global-notification/global-notification.component';
import {
  ComponentNotificationComponent
} from '@components/notify/component-notification/component-notification.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { HistoryApiComponent } from '@components/history-api/history-api.component';
import { DebugInfoComponent } from '@components/debug-info/debug-info.component';
import { PageNotFoundComponent } from '@components/page-not-found/page-not-found.component';
import { JsonviewerComponent } from '@components/jsonviewer/jsonviewer.component';
import { JsonListComponent } from '@components/json-list/json-list.component';
import { UserSettingsComponent } from '@components/user-settings/user-settings.component';
import { UserProfileComponent } from '@components/user-profile/user-profile.component';
import { NavigationPaneComponent } from '@components/navigation-pane/navigation-pane.component';
import { HeaderContainerComponent } from '@components/header-container/header-container.component';
import { FeedbackComponent } from '@components/feedback/feedback.component';
import { HelpComponent } from '@components/help/help.component';
import { SubstitutesComponent } from '@components/substitutes/substitutes.component';
import { LoadingPanelComponent } from '@components/loading-panel/loading-panel.component';

import { ConsolePipe } from '@pipes/console.pipe';
import { FindPipe } from '@pipes/find.pipe';
import { HasPermissionPipe } from '@pipes/has-permission.pipe';
import { SerializePipe } from '@pipes/serialize.pipe';
import { GetNameDataPipe } from '@pipes/get-namedata.pipe';
import { FileDownloadLinkPipe } from '@pipes/file-download-link.pipe';
import { IsPausePipe } from '@pipes/is-pause.pipe';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { HiddenPipe } from '@pipes/hidden.pipe';
import { GetUserAvatarPipe } from '@pipes/get-user-avatar.pipe';
import { SafeHtmlPipe } from '@pipes/safe-html.pipe';
import { IsWorkflowFormularPipe } from '@pipes/is-workflow-formular.pipe';
import { IsAssigneeFormularPipe } from '@pipes/is-assignee-formular.pipe';
import { ScrollPositionDirective } from './directives/scroll-position.directive';
import { FileExtensionsPipe } from '@pipes/file-extensions.pipe';
import { HasAttachmentPipe } from '@pipes/has-attachment.pipe';
import { GetAttachmentsPipe } from '@pipes/get-attachments.pipe';
import { FieldHasPermissionPipe } from '@pipes/field-has-permission.pipe';
import {
  NotificationsListComponent
} from '../application/components/lists/notifications-list/notifications-list.component';
import { GetVoteIconsPipe } from '@pipes/get-vote-icons.pipe';
import { GetProtocolPipe } from '@pipes/get-protocol.pipe';
import { GetDecisionTypePipe } from '@pipes/get-decision-type.pipe';
import { GetTasksPipe } from '@pipes/get-tasks.pipe';
import { GetDocumentPipe } from '@pipes/get-document.pipe';
import { GetTypePipe } from '@pipes/get-type.pipe';
import { GetWorkflowstatusPipe } from '@pipes/get-workflowstatus.pipe';
import { FindTagPipe } from '@pipes/find-tag.pipe';
import { GetTagPipe } from '@pipes/get-tag.pipe';
import { MapArrayPipe } from '@pipes/map-array.pipe';
import { ReferenceDetailsPipe } from '@pipes/reference-details.pipe';
import { ParentInfoPipe } from '@pipes/parent-info.pipe';
import { GetLabelPipe } from '@pipes/get-label.pipe';
import { TemplatePermissionPipe } from '@pipes/template-permission.pipe';
import { MapAuthorNamePipe } from '@pipes/map-author-name.pipe';
import { GetRoomPipe } from '@pipes/get-room.pipe';
import { WorkflowInfoComponent } from '@dialogs/workflow-info/workflow-info.component';
import { WorkflowDiagramComponent } from '@dialogs/workflow-info/workflow-diagram/workflow-diagram.component';
import { WorkflowDialogComponent } from '@dialogs/workflow-dialog/workflow-dialog.component';
import { ConfirmPopupComponent } from '@dialogs/confirm-popup/confirm-popup.component';
import { NameSelectorComponent } from '@dialogs/name-selector/name-selector.component';
import { ApprovalDialogComponent } from '../application/components/dialogs/approval-dialog/approval-dialog.component';
import {
  DocumentIncludeComponent
} from '../application/components/forms/subforms/document-include/document-include.component';
import { DynamicDiagrammComponent } from '../application/components/lists/dynamic-diagramm/dynamic-diagramm.component';
import { DynamicListComponent } from '../application/components/lists/dynamic-list/dynamic-list.component';
import { DynamicViewComponent } from '../application/components/lists/dynamic-view/dynamic-view.component';
import {
  BallotDataIncludeComponent
} from '../application/components/forms/subforms/ballot-data-include/ballot-data-include.component';
import {
  SignatureIncludeComponent
} from '../application/components/forms/subforms/signature-include/signature-include.component';
import {
  SignSignatureComponent
} from '../application/components/forms/subforms/sign-signature-include/sign-signature-include.component';
import {
  DiagramIncludeComponent
} from '../application/components/forms/subforms/diagram-include/diagram-include.component';
import { SchedulerComponent } from '../application/components/scheduler/scheduler.component';
import { ChartComponent } from '../application/components/chart/chart.component';
import { DocumentEditorComponent } from '../application/components/dialogs/document-editor/document-editor.component';
import { DocumentBaseComponent } from '../application/components/forms/document-base/document-base.component';
import {
  AgendaIncludeComponent
} from '../application/components/forms/subforms/agenda-include/agenda-include.component';
import {
  ProtocolIncludeComponent
} from '../application/components/forms/subforms/protocol-include/protocol-include.component';
import {
  ProjectIncludeComponent
} from '../application/components/forms/subforms/project-include/project-include.component';
import { ChildrenListComponent } from '../application/components/lists/children-list/children-list.component';
import {
  DecisionIncludeComponent
} from '../application/components/forms/subforms/decision-include/decision-include.component';
import { BallotInfoComponent } from '../application/components/dialogs/ballot-info/ballot-info.component';
import { TasksFormComponent } from '../feature_modules/tasks-board/tasks-form/tasks-form.component';
import { TasksEntryComponent } from '../feature_modules/tasks-board/tasks-entry/tasks-entry.component';
import {
  TasksKanbanBoardComponent
} from '../feature_modules/tasks-board/tasks-kanban-board/tasks-kanban-board.component';
import { TasksBoardComponent } from '../feature_modules/tasks-board/tasks-board.component';
import {
  LabelsIncludeComponent
} from '../application/components/forms/subforms/labels-include/labels-include.component';
import { TopIncludeComponent } from '../application/components/forms/subforms/top-include/top-include.component';
import { WorkflowExecuteComponent } from '@dialogs/workflow-execute/workflow-execute.component';
import { ApplyPipe } from '@pipes/apply.pipe';
import { FormTextboxComponent } from '../application/components/utils/form-textbox/form-textbox.component';
import { FormSelectboxComponent } from '../application/components/utils/form-selectbox/form-selectbox.component';
import { FormTagboxComponent } from '../application/components/utils/form-tagbox/form-tagbox.component';
import { FormNumberboxComponent } from '../application/components/utils/form-numberbox/form-numberbox.component';
import { FormDateboxComponent } from '../application/components/utils/form-datebox/form-datebox.component';
import { FormSliderComponent } from '../application/components/utils/form-slider/form-slider.component';
import { AudioRecordComponent } from '@components/audio-record/audio-record.component';
import { CreateComponent } from '../application/components/create/create.component';
import { OldDecisionIncludeComponent } from '../application/components/forms/subforms/old-decision-include/old-decision-include.component';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    HistoryApiComponent,
    DebugInfoComponent,
    JsonviewerComponent,
    JsonListComponent,
    GlobalNotificationComponent,
    ComponentNotificationComponent,
    LoadingPanelComponent,
    BreadcrumbComponent,
    UserProfileComponent,
    UserSettingsComponent,
    NavigationPaneComponent,
    AudioRecordComponent,

    WorkflowInfoComponent,
    WorkflowDiagramComponent,
    WorkflowDialogComponent,
    ConfirmPopupComponent,
    NameSelectorComponent,
    WorkflowExecuteComponent,

    FormTextboxComponent,
    FormSelectboxComponent,
    FormTagboxComponent,
    FormNumberboxComponent,
    FormDateboxComponent,
    FormSliderComponent,

    AgendaIncludeComponent,
    ApprovalDialogComponent,
    BallotDataIncludeComponent,
    BallotInfoComponent,
    ChartComponent,
    ChildrenListComponent,
    ConfirmPopupComponent,
    CreateComponent,
    DecisionIncludeComponent,
    OldDecisionIncludeComponent,
    DiagramIncludeComponent,
    DocumentBaseComponent,
    DocumentEditorComponent,
    DocumentIncludeComponent,
    DynamicDiagrammComponent,
    DynamicListComponent,
    DynamicViewComponent,
    FeedbackComponent,
    HeaderContainerComponent,
    HelpComponent,
    LabelsIncludeComponent,
    NameSelectorComponent,
    NotificationsListComponent,
    ProjectIncludeComponent,
    ProtocolIncludeComponent,
    SignatureIncludeComponent,
    SignSignatureComponent,
    SchedulerComponent,
    ChartComponent,
    DocumentEditorComponent,
    DocumentBaseComponent,
    AgendaIncludeComponent,
    TopIncludeComponent,
    ProtocolIncludeComponent,
    ProjectIncludeComponent,
    ChildrenListComponent,
    DecisionIncludeComponent,
    OldDecisionIncludeComponent,
    BallotInfoComponent,
    TasksFormComponent,
    SubstitutesComponent,
    TasksBoardComponent,
    TasksEntryComponent,
    TasksFormComponent,
    TasksKanbanBoardComponent,
    WorkflowDiagramComponent,
    WorkflowDialogComponent,
    WorkflowInfoComponent,

    SerializePipe,
    ConsolePipe,
    ApplyPipe,
    HasPermissionPipe,
    HasAttachmentPipe,
    FieldHasPermissionPipe,
    GetAttachmentsPipe,
    FindPipe,
    GetNameDataPipe,
    FileDownloadLinkPipe,
    FileExtensionsPipe,
    IsPausePipe,
    GetUserAvatarPipe,
    HiddenPipe,
    GetVoteIconsPipe,
    GetProtocolPipe,
    GetDecisionTypePipe,
    GetDocumentPipe,
    GetTypePipe,
    GetWorkflowstatusPipe,
    FindTagPipe,
    GetTagPipe,
    GetRoomPipe,
    MapArrayPipe,
    GetTasksPipe,
    ReferenceDetailsPipe,
    ParentInfoPipe,
    GetLabelPipe,
    TemplatePermissionPipe,
    SafeHtmlPipe,
    IsWorkflowFormularPipe,
    IsAssigneeFormularPipe,
    MapAuthorNamePipe,
    ScrollPositionDirective
  ],

  imports: [
    CommonModule,
    DragDropModule,
    ClipboardModule,
    DndModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxGraphModule,

    DevExtremeSharedModule
  ],

  exports: [
    DragDropModule,
    DndModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxGraphModule,
    DevExtremeSharedModule,
    PageNotFoundComponent,
    HistoryApiComponent,
    DebugInfoComponent,
    JsonviewerComponent,
    JsonListComponent,
    GlobalNotificationComponent,
    ComponentNotificationComponent,
    LoadingPanelComponent,
    BreadcrumbComponent,
    UserProfileComponent,
    UserSettingsComponent,
    AudioRecordComponent,

    ConfirmPopupComponent,
    NameSelectorComponent,
    WorkflowExecuteComponent,
    WorkflowDiagramComponent,
    WorkflowDialogComponent,
    WorkflowInfoComponent,

    FormTextboxComponent,
    FormSelectboxComponent,
    FormTagboxComponent,
    FormNumberboxComponent,
    FormDateboxComponent,
    FormSliderComponent,

    ApprovalDialogComponent,
    CreateComponent,
    DocumentIncludeComponent,
    DynamicDiagrammComponent,
    DynamicListComponent,
    DynamicViewComponent,
    BallotDataIncludeComponent,
    SignatureIncludeComponent,
    SignSignatureComponent,
    DiagramIncludeComponent,
    SchedulerComponent,
    ChartComponent,
    DocumentEditorComponent,
    DocumentBaseComponent,
    AgendaIncludeComponent,
    TopIncludeComponent,
    ProtocolIncludeComponent,
    ProjectIncludeComponent,
    ChildrenListComponent,
    DecisionIncludeComponent,
    OldDecisionIncludeComponent,
    BallotInfoComponent,
    TasksFormComponent,
    TasksEntryComponent,
    TasksKanbanBoardComponent,
    TasksBoardComponent,
    LabelsIncludeComponent,

    SerializePipe,
    FindPipe,
    ConsolePipe,
    ApplyPipe,
    HasPermissionPipe,
    HasAttachmentPipe,
    FieldHasPermissionPipe,
    GetAttachmentsPipe,
    IsPausePipe,
    GetNameDataPipe,
    FileDownloadLinkPipe,
    FileExtensionsPipe,
    HiddenPipe,
    NavigationPaneComponent,
    HeaderContainerComponent,
    SafeHtmlPipe,
    IsWorkflowFormularPipe,
    IsAssigneeFormularPipe,
    MapAuthorNamePipe,
    GetDecisionTypePipe,
    GetDocumentPipe,
    GetLabelPipe,
    GetProtocolPipe,
    GetRoomPipe,
    GetTagPipe,
    GetTasksPipe,
    GetTypePipe,
    GetUserAvatarPipe,
    GetVoteIconsPipe,
    GetWorkflowstatusPipe,
    FindTagPipe,
    MapArrayPipe,
    ReferenceDetailsPipe,
    ParentInfoPipe,
    TemplatePermissionPipe,
    ScrollPositionDirective
  ],

  providers: [
    GetNameDataPipe,
    MapAuthorNamePipe,
    FileDownloadLinkPipe,
    SafeHtmlPipe,
    GetRoomPipe,
    GetWorkflowstatusPipe,
    GetTagPipe,
    GetTypePipe
  ]
})
export class CoreModule {
}
