import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { SiamList } from '@interfaces/siamList';
import { siamConst } from '@interfaces/siamConst';
import { ICategory, IDynamicCard, IDynamicList, ILabel, ITag, SiamListItem } from '@interfaces/siam';
import * as uuid from 'uuid';
import { ListsApi } from '../api/lists.api';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store';
import { clone, isNotEmptyObject } from '@factories/helpers';

interface IList {
  name: string;
  description?: string;
  color: string;
  deactivated?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ListsService {
  private readonly lists: SiamListItem[] = [
    {
      label: 'Vorlagen',
      value: 'app:document-type:submission',
      properties: {
        name: 'submission',
        description: 'Alle Vorlagearten in der Anwendung',
        icon: 'calendar_today',
        visibleCreation: true,
        visibleNavigation: true,
        navigationName: 'Vorlagen',
        selector: [
          ['configuration-tag', '=', 'app:document-type:submission'],
          'and',
          ['configuration-tag', '<>', 'app:document-type:decision']
        ],
        settings: {
          excludeTags: ['app:document-type:decision'],
          pageSize: 25,
          summary: false,
          isEditMode: true,
          toolbar: {
            isShowPersonalMode: true,
            isShowPreviewSwitch: true,
            isShowSearch: true,
            isShowCurrent: true,
            isShowGrouping: true,
            isShowDateFilter: true,
            dateField: null
          },
          columns: [
            {
              isTemplate: true,
              templateName: 'icon',
              isAutoWidth: true
            },
            {
              caption: 'Typ',
              isTemplate: true,
              templateName: 'type',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              allowGrouping: true,
              allowSorting: true,
              isAutoWidth: true
            },
            {
              caption: 'Thema',
              dataField: 'fields.subject',
              dataType: 'string',
              allowSorting: false
            },
            {
              caption: 'Erstellt am',
              isTemplate: true,
              templateName: 'creationDate',
              allowResizing: false,
              allowHeaderFiltering: false,
              allowHiding: false,
              allowSorting: true,
              sortOrder: 'desc',
              isAutoWidth: true
            },
            {
              caption: 'Erstellt durch',
              isTemplate: true,
              templateName: 'creatorName',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              allowSorting: false,
              allowGrouping: false,
              isAutoWidth: true
            },
            {
              caption: 'Abteilung',
              isTemplate: true,
              templateName: 'creatorDepartment',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: true,
              allowSorting: true,
              isAutoWidth: true,
              allowGrouping: true
            },
            {
              caption: 'Status',
              isTemplate: true,
              templateName: 'workflowStatus',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              isAutoWidth: true,
              allowSorting: true,
              allowGrouping: true
            },
            {
              caption: 'Labels',
              isTemplate: true,
              templateName: 'labels',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: true,
              allowSorting: false,
              allowGrouping: false,
              isHidden: true,
              isAutoWidth: true
            }
          ]
        }
      }
    },
    {
      label: 'Sitzungen',
      value: 'app:document-type:agenda',
      properties: {
        name: 'agenda',
        description: '',
        icon: 'gavel',
        visibleCreation: true,
        visibleNavigation: true,
        visibleHomeCard: true,
        navigationName: 'Sitzungen',
        selector: ['configuration-tag', '=', 'app:document-type:agenda'],
        settings: {
          pageSize: 25,
          isEditMode: true,
          toolbar: {
            isShowDateFilter: true,
            dateField: 'fields.startdate',
            userField: 'fields.attendees',
            isShowExportExcel: false,
            isShowGrouping: true,
            isShowPersonalMode: true,
            isShowPreviewSwitch: false,
            isShowSearch: true
          },
          columns: [
            {
              caption: 'Sitzungstyp',
              isTemplate: true,
              templateName: 'type',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              allowSorting: false,
              sortOrder: 'asc',
              isGrouping: false,
              allowGrouping: true,
              isAutoWidth: true
            },
            {
              caption: 'Sitzungsdatum',
              allowResizing: false,
              allowHeaderFiltering: false,
              allowHiding: false,
              allowSorting: true,
              allowGrouping: false,
              sortOrder: 'desc',
              dataField: 'fields.startdate',
              dataType: 'datetime',
              format: 'dd.MM.yyyy',
              isAutoWidth: true
            },
            {
              caption: 'Ort',
              dataField: 'fields.place',
              dataType: 'string',
              allowHiding: true,
              isHidden: true,
              allowHeaderFiltering: true
            },
            {
              caption: 'Raum',
              dataField: 'fields.room',
              dataType: 'string',
              allowHeaderFiltering: true,
              allowHiding: true
            },
            {
              caption: 'Thema',
              dataField: 'fields.subject',
              dataType: 'string',
              allowGrouping: false
            },
            {
              caption: 'Status',
              isTemplate: true,
              templateName: 'workflowStatus',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              allowSorting: false,
              sortOrder: 'asc',
              isAutoWidth: true
            },
            {
              caption: 'Erstellt durch',
              isTemplate: true,
              templateName: 'creatorName',
              allowResizing: false,
              allowHeaderFiltering: false,
              allowHiding: true,
              allowSorting: false,
              allowGrouping: false,
              sortOrder: 'asc',
              isAutoWidth: true
            }
          ]
        }
      }
    },
    {
      label: 'Sitzungsprotokolle',
      value: 'app:document-type:meetingMinutes',
      properties: {
        name: 'meetingMinutes',
        description: '',
        icon: 'book',
        visibleCreation: false,
        visibleNavigation: true,
        navigationName: 'Sitzungsprotokolle',
        selector: ['configuration-tag', '=', 'app:document-type:meetingMinutes'],

        settings: {
          pageSize: 25,
          isEditMode: true,
          toolbar: {
            isShowDateFilter: true,
            dateField: 'fields.startdate',
            isShowExportExcel: false,
            isShowGrouping: true,
            isShowPersonalMode: false,
            isShowPreviewSwitch: false,
            isShowSearch: true
          },
          columns: [
            {
              isTemplate: true,
              templateName: 'icon',
              isAutoWidth: true
            },
            {
              caption: 'Sitzung',
              isTemplate: true,
              templateName: 'type',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              allowSorting: false,
              sortOrder: 'asc',
              isAutoWidth: true
            },
            {
              caption: 'Sitzungsdatum',
              allowResizing: false,
              allowHeaderFiltering: false,
              allowHiding: false,
              allowSorting: true,
              allowGrouping: false,
              sortOrder: 'desc',
              dataField: 'fields.startdate',
              dataType: 'datetime',
              format: 'dd.MM.yyyy',
              isAutoWidth: true
            },
            {
              caption: 'Thema der Tagesordnung',
              dataField: 'fields.subject',
              allowHiding: true,
              allowGrouping: false
            },
            {
              caption: 'Status',
              isTemplate: true,
              templateName: 'workflowStatus',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              allowSorting: false,
              sortOrder: 'asc',
              isAutoWidth: true
            },
            {
              caption: 'Erstellt am',
              isTemplate: true,
              templateName: 'creationDate',
              allowResizing: false,
              allowHeaderFiltering: false,
              allowHiding: true,
              allowSorting: true,
              allowGrouping: false,
              sortOrder: 'desc',
              isAutoWidth: true
            },
            {
              caption: 'Erstellt durch',
              isTemplate: true,
              templateName: 'creatorName',
              allowResizing: false,
              allowHeaderFiltering: false,
              allowHiding: true,
              allowSorting: false,
              allowGrouping: false,
              sortOrder: 'asc',
              isAutoWidth: true
            }
          ]
        }
      }
    },
    {
      label: 'Beschlüsse',
      value: 'app:document-type:decision',
      properties: {
        name: 'decision',
        description: 'Alle Beschlüsse in der Anwendung',
        icon: 'assignment_turned_in',
        visibleCreation: true,
        visibleNavigation: true,
        navigationName: 'Beschlussbuch',
        selector: ['configuration-tag', '=', 'app:document-type:decision'],

        settings: {
          pageSize: 25,
          summary: true,
          isEditMode: true,
          toolbar: {
            isShowPersonalMode: true,
            isShowPreviewSwitch: true,
            isShowSearch: true,
            isShowCurrent: true,
            isShowGrouping: true,
            isShowDateFilter: true,
            dateField: 'fields.--approval_date'
          },
          columns: [
            {
              caption: 'Datum',
              allowResizing: false,
              allowHeaderFiltering: false,
              allowHiding: false,
              allowSorting: true,
              allowGrouping: false,
              sortOrder: 'desc',
              dataField: 'fields.--approval_date',
              dataType: 'datetime',
              format: 'dd.MM.yyyy',
              isAutoWidth: true
            },
            {
              caption: 'Kategorie',
              dataField: 'fields.--approval_category',
              dataType: 'string',
              allowResizing: false,
              allowHeaderFiltering: false,
              allowHiding: false,
              allowSorting: true,
              allowGrouping: true,
              isAutoWidth: true
            },
            {
              caption: 'Nr.',
              dataField: 'fields.--approval_number',
              dataType: 'string',
              allowResizing: false,
              allowHeaderFiltering: false,
              allowHiding: false,
              allowSorting: true,
              allowGrouping: false,
              isAutoWidth: true
            },
            {
              caption: 'Thema',
              dataField: 'fields.subject',
              dataType: 'string',
              allowResizing: false,
              allowHeaderFiltering: false,
              allowHiding: false,
              allowSorting: true,
              allowGrouping: true
            },
            {
              caption: 'Vermerk',
              dataField: 'fields.--approval_type',
              dataType: 'string',
              allowResizing: false,
              allowHeaderFiltering: false,
              allowHiding: false,
              allowSorting: true,
              allowGrouping: true
            },
            {
              caption: 'Beschlusssache',
              isTemplate: true,
              templateName: 'type',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              allowGrouping: true,
              allowSorting: true
            },
            {
              caption: 'Abteilung',
              isTemplate: true,
              templateName: 'creatorDepartment',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: true,
              allowSorting: true,
              isAutoWidth: true,
              allowGrouping: true
            },
            {
              caption: 'Status',
              isTemplate: true,
              templateName: 'workflowStatus',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              isAutoWidth: true,
              allowSorting: true,
              allowGrouping: true
            },
            {
              caption: 'Labels',
              isTemplate: true,
              templateName: 'labels',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: true,
              allowSorting: false,
              allowGrouping: false,
              isHidden: true,
              isAutoWidth: true
            }
          ]
        }
      }
    },
    {
      label: 'Projekte',
      value: 'app:document-type:project',
      properties: {
        name: 'project',
        description: 'Für Projekte aller Art',
        icon: 'extension',
        visibleCreation: true,
        visibleNavigation: true,
        navigationName: 'Projekte',
        selector: ['configuration-tag', '=', 'app:document-type:project'],

        settings: {
          pageSize: 25,
          isEditMode: true,
          toolbar: {
            isShowPersonalMode: true,
            isShowPreviewSwitch: true,
            isShowSearch: true,
            isShowCurrent: false,
            isShowGrouping: true
          },
          columns: [
            {
              caption: 'Labels',
              isTemplate: true,
              templateName: 'labels',
              allowGrouping: false,
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: true,
              allowSorting: false,
              sortOrder: 'asc',
              isAutoWidth: true
            },
            {
              caption: 'Projektname',
              allowResizing: false,
              allowHeaderFiltering: false,
              allowHiding: false,
              allowSorting: false,
              sortOrder: 'asc',
              dataField: 'fields.subject',
              dataType: 'string'
            },
            {
              caption: 'Projektbeginn',
              dataField: 'fields.startdate',
              dataType: 'datetime',
              isAutoWidth: true,
              allowSorting: true,
              sortOrder: 'desc',
              allowHiding: true
            },
            {
              caption: 'geplantes Projektende',
              dataField: 'fields.enddate',
              dataType: 'datetime',
              isAutoWidth: true,
              allowSorting: true,
              allowHiding: true
            },
            {
              caption: 'Status',
              isTemplate: true,
              templateName: 'workflowStatus',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              allowSorting: false,
              sortOrder: 'asc',
              isAutoWidth: true
            },
            {
              caption: 'Fortschritt',
              isTemplate: true,
              templateName: 'progressBar',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: true,
              allowSorting: false,
              sortOrder: 'asc',
              isAutoWidth: true
            }
          ]
        }
      }
    },
    {
      label: 'Postbuch',
      value: 'app:document-type:postbook',
      properties: {
        name: 'postbook',
        description: '',
        icon: 'local_post_office',
        visibleCreation: true,
        visibleNavigation: true,
        navigationName: 'Postbuch',
        selector: ['configuration-tag', '=', 'app:document-type:postbook'],

        settings: {
          pageSize: 25,
          isEditMode: true,
          toolbar: {
            isShowDateFilter: false,
            dateField: '',
            isShowExportExcel: true,
            isShowGrouping: true,
            isShowPersonalMode: false,
            isShowPreviewSwitch: true,
            isShowSearch: true
          },
          columns: [
            {
              isTemplate: true,
              templateName: 'icon'
            },
            {
              caption: 'Medium',
              dataField: 'fields.medium',
              dataType: 'string',
              allowHeaderFiltering: true,
              isAutoWidth: true,
              allowSorting: true,
              allowResizing: true
            },
            {
              caption: 'Betreff',
              dataField: 'fields.subject',
              dataType: 'string',
              showAttachment: true,
              allowSorting: true,
              allowResizing: true
            },
            {
              caption: 'Prio',
              dataField: 'fields.prioritaet',
              dataType: 'string',
              isAutoWidth: true,
              allowSorting: true,
              allowHiding: false,
              allowResizing: true,
              allowHeaderFiltering: true
            },
            {
              caption: 'Absender',
              dataField: 'fields.absender',
              dataType: 'string',
              isAutoWidth: true,
              allowSorting: true,
              allowHeaderFiltering: true
            },
            {
              caption: 'Empfänger',
              dataField: 'fields.empfaenger',
              dataType: 'string',
              isAutoWidth: true,
              allowSorting: true,
              allowHeaderFiltering: true
            },
            {
              caption: 'Erstellt am',
              isTemplate: true,
              templateName: 'creationDate',
              allowResizing: false,
              allowHeaderFiltering: false,
              allowHiding: false,
              allowSorting: false,
              sortOrder: 'asc'
            },
            {
              caption: 'Erstellt durch',
              isTemplate: true,
              templateName: 'creatorName',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              allowSorting: false,
              sortOrder: 'asc'
            },
            {
              caption: 'Status',
              isTemplate: true,
              templateName: 'workflowStatus',
              allowResizing: false,
              allowHeaderFiltering: false,
              allowHiding: false,
              allowSorting: false,
              sortOrder: 'asc'
            },
            {
              caption: 'Labels',
              isTemplate: true,
              templateName: 'labels',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: true,
              allowSorting: false,
              sortOrder: 'asc',
              isAutoWidth: true,
              isHidden: true
            }
          ]
        }
      }
    },
    {
      label: 'Aufgaben',
      value: 'app:document-type:task',
      properties: {
        name: 'task',
        description: 'Aufgaben',
        icon: 'alarm_on',
        visibleCreation: false,
        visibleNavigation: true,
        visibleHomeCard: true,
        navigationName: 'Aufgaben',
        selector: ['configuration-tag', '=', 'app:document-type:task'],

        settings: {
          pageSize: 25,
          isEditMode: true,
          toolbar: {
            isShowPersonalMode: true,
            isShowPreviewSwitch: false,
            isShowSearch: true,
            isShowCurrent: false,
            isShowGrouping: true,
            isShowDateFilter: true,
            isShowExportExcel: true,
            isShowKanban: true,
            isShowList: true,
            dateField: 'fields.startdate'
          },
          columns: [
            {
              caption: 'Termin',
              dataField: 'fields.enddate',
              format: 'dd.MM.yyyy',
              dataType: 'datetime',
              allowSorting: true,
              sortOrder: 'desc'
            },
            {
              caption: 'Labels',
              isTemplate: true,
              templateName: 'labels',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: true,
              allowSorting: false,
              allowGrouping: true,
              sortOrder: 'asc',
              isHidden: true
            },
            {
              caption: 'Betrifft',
              dataField: 'fields.subject'
            },
            {
              caption: 'Priorität',
              dataField: 'fields.priority',
              allowHeaderFiltering: true,
              allowHiding: true,
              allowSorting: true,
              allowGrouping: true
            },
            {
              caption: 'Status',
              isTemplate: true,
              templateName: 'workflowStatus',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              allowSorting: true,
              allowGrouping: true,
              sortOrder: 'asc'
            },
            {
              caption: 'Fortschritt',
              dataField: 'fields.progress',
              allowHiding: true,
              allowSorting: true,
              columnType: 'dxSlider'
            },
            {
              caption: 'Verantwortlich',
              dataField: 'fields.chair',
              allowHeaderFiltering: true,
              allowSorting: true
            },
            {
              caption: 'Erstellt durch',
              isTemplate: true,
              templateName: 'creatorName',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: true,
              isHidden: true,
              allowSorting: false,
              allowGrouping: false
            },
            {
              caption: 'Abteilung',
              isTemplate: true,
              templateName: 'creatorDepartment',
              allowResizing: false,
              allowHeaderFiltering: false,
              allowHiding: true,
              allowSorting: false,
              isHidden: true,
              allowGrouping: false
            },
            {
              caption: 'Übergeordnetes Dokument',
              isTemplate: true,
              templateName: 'parentDocuments',
              allowResizing: false,
              allowHeaderFiltering: false,
              allowHiding: true,
              allowSorting: false,
              isHidden: true,
              allowGrouping: false
            }
          ]
        }
      }
    },
    {
      label: 'Tagesordnungspunkte',
      value: 'app:document-type:top',
      properties: {
        name: 'top',
        description: 'Tagesordnungspunkte',
        icon: 'event',
        visibleCreation: false,
        visibleNavigation: false,
        navigationName: 'Tagesordnungspunkte',
        selector: ['configuration-tag', '=', 'app:document-type:top'],

        settings: {
          pageSize: 50,
          summary: true,
          isEditMode: true,
          toolbar: {
            dateField: '',
            isShowDateFilter: false,
            isShowExportExcel: false,
            isShowGrouping: true,
            isShowPersonalMode: false,
            isShowPreviewSwitch: false,
            isShowSearch: true,
            userField: ''
          },
          columns: [
            {
              isTemplate: true,
              templateName: 'icon',
              isAutoWidth: true
            },
            {
              caption: 'Typ',
              isTemplate: true,
              templateName: 'type',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              allowGrouping: true,
              allowSorting: true,
              isAutoWidth: true
            },
            {
              caption: 'Thema',
              dataField: 'fields.subject',
              columnType: 'dxTextBox',
              dataType: 'string'
            },
            {
              caption: 'Übergeordnetes Dokument',
              isTemplate: true,
              templateName: 'parentDocuments',
              allowResizing: false,
              allowHeaderFiltering: false,
              allowHiding: false,
              allowSorting: false,
              isGrouping: false,
              showGroupingOption: true,
              showSortingOption: false,
              showFilterOption: true
            },
            {
              caption: 'Erstellt am',
              isTemplate: true,
              templateName: 'creationDate',
              allowResizing: false,
              allowHeaderFiltering: false,
              allowHiding: false,
              allowSorting: true,
              sortOrder: 'desc',
              isAutoWidth: true
            },
            {
              caption: 'Erstellt durch',
              isTemplate: true,
              templateName: 'creatorName',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              allowSorting: false,
              allowGrouping: false,
              isAutoWidth: true
            },
            {
              caption: 'Status',
              isTemplate: true,
              templateName: 'workflowStatus',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              isAutoWidth: true,
              allowSorting: true,
              allowGrouping: true
            }
          ]
        }
      }
    },
    {
      label: 'TOP-Protokoll',
      value: 'app:document-type:protocol',
      properties: {
        name: 'protocol',
        description: 'TOP-Protokolle',
        icon: 'info',
        visibleCreation: false,
        visibleNavigation: true,
        navigationName: 'TOP-Protokolle',
        selector: ['configuration-tag', '=', 'app:document-type:protocol'],

        settings: {
          pageSize: 50,
          summary: true,
          isEditMode: true,
          toolbar: {
            dateField: '',
            isShowDateFilter: false,
            isShowExportExcel: false,
            isShowGrouping: true,
            isShowPersonalMode: false,
            isShowPreviewSwitch: false,
            isShowSearch: true,
            userField: ''
          },
          columns: [
            {
              isTemplate: true,
              templateName: 'icon',
              isAutoWidth: true
            },
            {
              caption: 'Typ',
              isTemplate: true,
              templateName: 'type',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              allowGrouping: true,
              allowSorting: true,
              isAutoWidth: true
            },
            {
              caption: 'Thema',
              dataField: 'fields.subject',
              columnType: 'dxTextBox',
              dataType: 'string'
            },
            {
              caption: 'Erstellt am',
              isTemplate: true,
              templateName: 'creationDate',
              allowResizing: false,
              allowHeaderFiltering: false,
              allowHiding: false,
              allowSorting: true,
              sortOrder: 'desc',
              isAutoWidth: true
            },
            {
              caption: 'Erstellt durch',
              isTemplate: true,
              templateName: 'creatorName',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              allowSorting: false,
              allowGrouping: false,
              isAutoWidth: true
            },
            {
              caption: 'Status',
              isTemplate: true,
              templateName: 'workflowStatus',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              isAutoWidth: true,
              allowSorting: true,
              allowGrouping: true
            }
          ]
        }
      }
    },
    {
      label: 'TOP-Vorlage',
      value: 'agenda-container-vorlage',
      properties: {
        name: 'agenda-container-vorlage',
        description: 'Bitte wählen Sie die gewünschte Vorlage',
        icon: 'info',
        groupName: null,
        isVisible: false,
        isGroup: false,
        isClosed: false,
        settings: {
          pageSize: 50,
          summary: true,
          toolbar: {
            dateField: '',
            isShowDateFilter: false,
            isShowExportExcel: false,
            isShowGrouping: true,
            isShowPersonalMode: false,
            isShowPreviewSwitch: false,
            isShowSearch: true,
            userField: ''
          },
          columns: [
            {
              isTemplate: true,
              templateName: 'icon',
              isAutoWidth: true
            },
            {
              caption: 'Typ',
              isTemplate: true,
              templateName: 'type',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              allowGrouping: true,
              allowSorting: true,
              isAutoWidth: true
            },
            {
              caption: 'Thema',
              dataField: 'fields.subject',
              columnType: 'dxTextBox',
              dataType: 'string'
            },
            {
              caption: '',
              isTemplate: true,
              templateName: 'type',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              allowGrouping: true,
              allowSorting: true,
              isAutoWidth: true
            },
            {
              caption: 'Erstellt am',
              isTemplate: true,
              templateName: 'creationDate',
              allowResizing: false,
              allowHeaderFiltering: false,
              allowHiding: false,
              allowSorting: true,
              sortOrder: 'desc',
              isAutoWidth: true
            },
            {
              caption: 'Erstellt durch',
              isTemplate: true,
              templateName: 'creatorName',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              allowSorting: false,
              allowGrouping: false,
              isAutoWidth: true
            },
            {
              caption: 'Status',
              isTemplate: true,
              templateName: 'workflowStatus',
              allowResizing: false,
              allowHeaderFiltering: true,
              allowHiding: false,
              isAutoWidth: true,
              allowSorting: true,
              allowGrouping: true
            }
          ]
        }
      } as IDynamicList
    }
  ];

  constructor(private api: ListsApi, private store: Store<fromStore.AppState>) {}

  public static createId(): string {
    return uuid.v4();
  }

  public static createValue(id: string): string {
    return `app:document-label:${siamConst.globalLabelsListName}:${id}`;
  }

  public static correctLabelValue(value: string, id: string): string {
    const correctId = value.split(':')?.pop();
    return correctId ? `app:document-label:${siamConst.globalLabelsListName}:${correctId}` : this.createValue(id);
  }

  getList(name: string, force = false): Observable<SiamList> {
    let stream$: Observable<SiamList>;
    let isUpdateCache = false;
    if (force) {
      isUpdateCache = true;
      stream$ = this.api.getList(name);
    } else {
      stream$ = this.store.select(fromStore.getListByName(name)).pipe(take(1));
    }

    return stream$.pipe(
      switchMap(entry => {
        if (!entry) {
          isUpdateCache = true;
          return this.api.getList(name);
        }
        return of(entry);
      }),
      tap(entry => {
        if (entry && isUpdateCache) {
          this.store.dispatch(new fromStore.CreateList({ list: entry }));
          isUpdateCache = false;
        }
      }),
      map(entry => {
        if (entry) {
          return clone(entry);
        }
        return null;
      })
    );
  }

  getListItemByType(type: string): Observable<SiamListItem> {
    return this.getList(siamConst.globalTagsListName).pipe(
      map(lists => {
        let entry = lists?.entries?.find(list => (list.properties as ICategory).name === type);
        if (!entry) {
          return null;
        }
        if (!(entry?.properties as ICategory)?.settings?.columns?.length) {
          entry = this.lists.find(list => (list.properties as ICategory).name === type);
        }
        return entry;
      })
    );
  }

  getDynamicListByType(type: string): Observable<SiamListItem> {
    return this.getList(siamConst.dynamicListsListName).pipe(
      map(lists => {
        let entry = lists?.entries?.find(list => (list.properties as ICategory).name === type);
        const defaultList = this.lists.find(list => (list.properties as ICategory).name === type);
        if (!entry && defaultList) {
          return defaultList;
        }
        if (!entry && !defaultList) {
          return null;
        }
        if (!(entry?.properties as ICategory)?.settings?.columns?.length) {
          entry = this.lists.find(list => (list.properties as ICategory).name === type);
        }
        return entry;
      })
    );
  }

  /**
   *  Get Lists  by graphQL
   */
  getListsWithEntries(): Observable<SiamList[]> {
    return this.api.getListsWithEntries();
  }

  /**
   * returns a single lists info by id
   *
   * @param name lists ID
   */
  getListEntries(name: string): Observable<SiamListItem[]> {
    return this.api.getListEntries(name);
  }
  /**
   * returns a single lists info by id
   *
   * @param name lists ID
   */
  getListbyIds(ids: string[]): Observable<SiamList[]> {
    return this.api.getListsByIds(ids);
  }
  /**
   * returns a single lists info by id
   *
   * @param id lists ID
   */

  getListItems(id: string, force = false): Observable<SiamList> {
    let stream$: Observable<SiamList>;
    let isUpdateCache = false;
    if (force) {
      isUpdateCache = true;
      stream$ = this.api.getListItems(id);
    } else {
      stream$ = this.store.select(fromStore.getListById(id)).pipe(take(1));
    }

    return stream$.pipe(
      switchMap(entry => {
        if (!entry) {
          isUpdateCache = true;
          return this.api.getListItems(id);
        }
        return of(entry);
      }),
      tap(entry => {
        if (entry && isUpdateCache) {
          this.store.dispatch(new fromStore.CreateList({ list: entry }));
          isUpdateCache = false;
        }
      }),
      map(entry => {
        if (entry) {
          return clone(entry);
        }
        return null;
      })
    );
  }

  /**
   * returns a single lists info by id
   *
   * @param name lists ID
   */
  deleteList(name: string): Observable<unknown> {
    return this.api.deleteList(name).pipe(
      tap({
        next: () => {
          this.cleanCache();
        }
      })
    );
  }

  /**
   * save or update the list
   *
   * @param list
   */
  saveList(list: SiamList): Observable<SiamList> {
    return this.api.saveList(list).pipe(
      tap({
        next: () => {
          this.cleanCache();
        }
      })
    );
  }

  getListEntryData(entry: SiamListItem): ILabel {
    const prop = entry.properties as IList;
    const id = entry.id || ListsService.createId();
    return {
      id,
      name: entry.label,
      description: prop.description,
      color: prop.color,
      value: (entry.value as string) || ListsService.createValue(id),
      deactivated: prop.deactivated || false
    };
  }

  getCategoryEntryData(entry: SiamListItem): ICategory {
    const prop = entry.properties as ICategory;
    return {
      name: prop.name,
      label: entry.label,
      description: prop.description,
      tag: entry.value as string,
      icon: prop.icon,
      visibleCreation: prop.visibleCreation,
      visibleNavigation: prop.visibleNavigation,
      visibleHomeCard: prop.visibleHomeCard,
      navigationName: prop.navigationName,
      settings: prop.settings,
      access: prop.access || { users: [], roles: [] }
    };
  }

  getDynamicListEntryData(entry: SiamListItem): IDynamicList {
    const prop = entry.properties as IDynamicList;
    const find = this.lists.find(list => (list.properties as ICategory).name === entry.value);

    const settings = isNotEmptyObject(prop.settings as Record<string, string>)
      ? prop.settings
      : find
      ? (find?.properties as ICategory)?.settings
      : {};
    return {
      name: prop.name,
      label: entry.label,
      groupName: prop.groupName,
      isGroup: prop.isGroup,
      isClosed: prop.isClosed,
      isVisible: typeof prop.isVisible === 'boolean' ? prop.isVisible : true,
      description: prop.description,
      selector: prop.selector,
      selectorConfig: prop.selectorConfig,
      icon: prop.icon,
      settings,
      access: prop.access || { users: [], roles: [] }
    };
  }

  getDynamicCardEntryData(entry: SiamListItem): IDynamicCard {
    const prop = entry.properties as IDynamicCard;
    return {
      name: prop.name,
      type: prop.type,
      label: entry.label,
      isVisible: typeof prop.isVisible === 'boolean' ? prop.isVisible : true,
      description: prop.description,
      selector: prop.selector,
      settings: prop.settings,
      chartSettings: prop.chartSettings,
      schedulerSettings: prop.schedulerSettings,
      access: prop.access || { users: [], roles: [] }
    };
  }

  getTagEntryData(entry: SiamListItem): ITag {
    return {
      key: entry.value as string,
      value: entry.label
    };
  }

  getGlobalLabels(): Observable<ILabel[]> {
    return this.getList(siamConst.globalLabelsListName).pipe(
      map(list => list.entries.map(entry => this.getListEntryData(entry)))
    );
  }

  getDefaultLists(): SiamListItem[] {
    return this.lists;
  }

  cleanCache(): void {
    this.store.dispatch(new fromStore.Delete());
  }
}
