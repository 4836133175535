import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { IDynamicGridSettingsToolbar, IDynamicList, TDisplayMode } from '@interfaces/siam';
import { ListsService } from '@services/lists.service';
import { IDxEditorToolbarItem } from '@interfaces/devextreme';
import { ActivatedRoute } from '@angular/router';

interface IButtonOptions {
  elementAttr: Record<string, string>;
  icon: string;
  text: string;
  hint: string;
  height: string;
  stylingMode: string;
  onClick: () => void;
}

@Component({
  selector: 'app-dynamic-view',
  templateUrl: './dynamic-view.component.html',
  styleUrls: ['./dynamic-view.component.scss']
})
export class DynamicViewComponent implements OnDestroy {
  displayMode: TDisplayMode = 'Liste';
  documentType: Observable<string>;
  dynamicListButtons: IDxEditorToolbarItem[] = [];
  dynamicDiagrammButtons: IDxEditorToolbarItem[] = [];

  readonly #buttons: Record<string, IButtonOptions>;
  #documentType = new BehaviorSubject<string>(null);
  #destroyable = new Subject<void>();

  constructor(
    private listsService: ListsService,
    private route: ActivatedRoute
  ) {
    this.documentType = this.#documentType.asObservable();

    this.#buttons = {
      diagramm: {
        elementAttr: { id: 'diagrammBtn' },
        icon: 'chart',
        text: 'Diagrammansicht',
        hint: 'Diagrammansicht',
        height: '35px',
        stylingMode: 'outlined',
        onClick: this.changeModeToDiagramm
      },
      kanban: {
        elementAttr: { id: 'kanbanBtn' },
        icon: 'columnfield',
        text: 'Kanbanansicht',
        hint: 'Kanbanansicht',
        height: '35px',
        stylingMode: 'outlined',
        onClick: this.changeModeToKanban
      },
      list: {
        elementAttr: { id: 'listBtn' },
        icon: 'detailslayout',
        text: 'Tabellenansicht',
        hint: 'Tabellenansicht',
        height: '35px',
        stylingMode: 'outlined',
        onClick: this.changeModeToList
      }
    };

    this.route.paramMap
      .pipe(
        switchMap(paramMap => {
          const type = paramMap.get('type');
          let currentDocumentType = this.#documentType.getValue();
          if (type && (!currentDocumentType || currentDocumentType !== type)) {
            currentDocumentType = type;
            this.dynamicListButtons = [];
            this.dynamicDiagrammButtons = [];
            this.#documentType.next(type);
          }
          if (currentDocumentType) {
            return this.listsService.getDynamicListByType(currentDocumentType);
          }
          return of(null);
        }),
        takeUntil(this.#destroyable)
      )
      .subscribe(entry => {
        if (entry) {
          const info = entry.properties as IDynamicList;
          let toolbarSettings: IDynamicGridSettingsToolbar = null;
          if (info?.settings) {
            toolbarSettings = info.settings.toolbar;
          }

          const isShowDiagramm = !!toolbarSettings?.isShowDiagramm;
          const isShowKanban = !!toolbarSettings?.isShowKanban;

          if (this.displayMode === 'DX-Kanban' && !isShowKanban) {
            this.displayMode = 'Liste';
          } else if (this.displayMode === 'Diagramm' && !isShowDiagramm) {
            this.displayMode = 'Liste';
          }

          if (isShowDiagramm) {
            this.dynamicListButtons.push({
              options: this.#buttons.diagramm
            });

            this.dynamicDiagrammButtons.push({
              options: this.#buttons.list
            });
          }

          if (isShowKanban) {
            this.dynamicListButtons.push({
              options: this.#buttons.kanban
            });

            if (isShowDiagramm) {
              this.dynamicDiagrammButtons.push({
                options: this.#buttons.kanban
              });
            }
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.#destroyable.next();
    this.#destroyable.complete();
  }

  changeModeToDiagramm = (): void => {
    this.displayMode = 'Diagramm';
  }

  changeModeToKanban = (): void => {
    this.displayMode = 'DX-Kanban';
  }

  changeModeToList = (): void => {
    this.displayMode = 'Liste';
  }
}
