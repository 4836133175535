import { ListState } from '../entities/list.entity';
import { ListActions, ListActionTypes } from '../actions';
import { ListAdapter } from '../entities/list.entity';

export const initialState: ListState = ListAdapter.getInitialState({});

export function reducer(state = initialState, action: ListActions): ListState {
  switch (action.type) {
    case ListActionTypes.CreateList: {
      const entry = action.payload.list;
      if (
        typeof entry === 'string' ||
        Object.prototype.hasOwnProperty.call(state, entry.name) ||
        Object.prototype.hasOwnProperty.call(state, entry.id)
      ) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [entry.name]: entry,
          [entry.id]: entry
        }
      };
    }

    case ListActionTypes.Delete:
      return initialState;

    default:
      return state;
  }
}
