import { UserState } from '../entities/user.entity';
import { UserActions, UserActionTypes } from '../actions';
import { UserAdapter } from '../entities/user.entity';

export const initialState: UserState = UserAdapter.getInitialState({
});

export function reducer(state = initialState, action: UserActions): UserState {

  switch (action.type) {
    case UserActionTypes.CreateUser:
      return UserAdapter.addOne(action.payload.user, state);

    case UserActionTypes.DeleteAllUsers:
      return UserAdapter.removeAll(state);

    case UserActionTypes.DeleteUser:
      return UserAdapter.removeOne(action.payload.user.id, state);

    case UserActionTypes.UpdateUser:
      return UserAdapter.updateOne({id: action.payload.user.id, changes: action.payload.user}, state);

    case UserActionTypes.SetUsers:
      return UserAdapter.upsertMany(action.payload.users, state);

    default:
      return state;
  }
}
