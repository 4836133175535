<dx-popup
  #dialogEditor
  width="90vw"
  maxHeight="90vh"
  shadingColor="rgba(0, 0, 50, 0.5)"
  [hidden]="!dialogDocument"
  [fullScreen]="dialogFullscreen"
  [showTitle]="false"
  [showCloseButton]="false"
  [dragEnabled]="true"
  [hideOnOutsideClick]="false"
  [shading]="true">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view height="100%" showScrollbar="onHover" useNative="false">
      <app-document-include
        *ngIf="dialogDocument"
        [currentDocument]="dialogDocument"
        [editMode]="dialogEditmode"
        [parentDocumentId]="parentDocument?.id"
        [ballotDocuments]="ballotDocuments">
      </app-document-include>
    </dx-scroll-view>
  </div>
</dx-popup>
