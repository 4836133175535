import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { Observable, tap, throwError } from 'rxjs';
import { IAgendaDecision, IagendaDecisionData } from '@interfaces/siam';
import { NotifyService } from './notify.service';

@Injectable({
  providedIn: 'root'
})
export class AgendaDocumentService {
  private apiBaseUrl: string;

  constructor(private http: HttpClient, private logger: LoggerService, @Inject('BASE_URL') private baseUrl: string) {
    this.apiBaseUrl = `${this.baseUrl}api/documents/agenda`;
    this.logger.info('Confidential Service started');
  }

  createAgendaDecision(decision: IAgendaDecision, data: IagendaDecisionData): Observable<unknown> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });

    if (!data?.agendaId || !data.contentId || !data.decisionId || !data.decisionId) {
      NotifyService.global.error(`Fehler beim Erstellen eines Beschlusses`);
      this.logger.error(`Fehler beim Erstellen eines Beschlusses: ${JSON.stringify(data)}`);
      return throwError(() => `Fehler beim Erstellen eines Beschlusses`);
    }
    return this.http
      .put<IAgendaDecision>(
        `${this.apiBaseUrl}/${data.agendaId}/top/${data.topId}/content/${data.contentId}/decision/${data.decisionId}`,
        decision,
        { headers }
      )
      .pipe(
        tap({
          next: () => {
            this.logger.info('Beschluss erstellt: {@decision}', decision);
          },
          error: error => {
            this.logger.error('Fehler beim Erstellen des Beschlusses: {@error}', error);
          }
        })
      );
  }

  updateAgendaDecision(decision: IAgendaDecision, data: IagendaDecisionData): Observable<unknown> {
    const headers = new HttpHeaders({
      accept: 'application/json'
    });
    if (!data?.agendaId || !data.topId || !data.contentId || !data.decisionName) {
      NotifyService.global.error(`Fehler beim Bearbeiten eines Beschlusses`);
      this.logger.error(`Fehler beim Bearbeiten eines Beschlusses: ${JSON.stringify(data)}`);
      return throwError(() => `Fehler beim Bearbeiten eines Beschlusses: ${data?.decisionName}`);
    }
    return this.http
      .post<IAgendaDecision>(
        `${this.apiBaseUrl}/${data.agendaId}/top/${data.topId}/content/${data.contentId}/decision/${data.decisionName}`,
        decision,
        { headers }
      )
      .pipe(
        tap({
          next: () => {
            this.logger.info('Beschluss ändern: {@decision}', decision);
          },
          error: error => {
            this.logger.error('Fehler beim Ändern des Beschlusses: {@error}', error);
          }
        })
      );
  }

  deleteAgendaDecision(data: IagendaDecisionData): Observable<unknown> {
    if (!data?.agendaId || !data.topId || !data.contentId || !data.decisionName) {
      NotifyService.global.error(`Fehler beim Löschen eines Beschlusses`);
      this.logger.error(`Fehler beim Löschen eines Beschlusses: ${JSON.stringify(data)}`);
      return throwError(() => `Fehler beim Löschen eines Beschlusses: ${data?.decisionName}`);
    }
    return this.http
      .delete<IAgendaDecision>(
        `${this.apiBaseUrl}/${data.agendaId}/top/${data.topId}/content/${data.contentId}/decision/${data.decisionName}`
      )
      .pipe(
        tap({
          next: () => {
            this.logger.info('Beschluss ändern: {@decision}', data);
          },
          error: error => {
            this.logger.error('Fehler beim Ändern des Beschlusses: {@error}', error);
          }
        })
      );
  }
}
