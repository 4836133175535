import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ListsService } from '@services/lists.service';
import { IDocument, IDocumentDecision, TEditMode } from '@interfaces/siam';
import { TemplateService } from '@services/template.service';
import { DocumentService } from '@services/document.service';

@Component({
  selector: 'app-decision-include',
  templateUrl: './decision-include.component.html',
  styleUrls: ['./decision-include.component.scss']
})
export class DecisionIncludeComponent implements OnInit, OnDestroy {
  @Input() currentDocument: IDocument;
  @Input() editMode: TEditMode = 'ReadOnly';

  decisionDataSource: IDocumentDecision[] = [];

  private unlistener: () => void;
  private destroyed$ = new Subject<void>();

  constructor(
    private listsService: ListsService,
    private templateService: TemplateService,
    private documentService: DocumentService
  ) {}

  ngOnInit(): void {
    void this.getDecisionData();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    if (typeof this.unlistener === 'function') {
      this.unlistener();
    }
  }
  async getDecisionData(): Promise<void> {
    this.decisionDataSource = await this.documentService.getDecisionsFromDocument(this.currentDocument);
  }
}
