<dx-popup
  [fullScreen]="fullScreen"
  [dragEnabled]="true"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  [visible]="dialogOpen"
  width="70vw"
  [minWidth]="750"
  [maxWidth]="1200"
  shadingColor="rgba(0, 0, 50, 0.5)"
  (onHidden)="onDialogHidden()"
  [position]="{ my: 'top', at: 'top', of: '#main-view' }"
  [toolbarItems]="[
    {
      widget: 'dxButton',
      location: 'before',
      toolbar: 'top',
      visible: true,
      options: { type: 'normal', icon: 'material-icons md-24 people', elementAttr: { class: 'button-icon' } }
    },
    { text: title, location: 'before', toolbar: 'top' },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'top',
      visible: true,
      options: { type: 'normal', icon: 'close', hint: 'Schließen' },
      onClick: dialogCancel
    },
    {
      widget: 'dxTextBox',
      location: 'befor',
      toolbar: 'bottom',
      cssClass: 'min-selection-message',
      options: { text: minSelectionText, width: 'auto', readOnly: 'true', stylingMode: 'underlined' }
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { icon: 'check', hint: 'tttt', text: 'Übernehmen', disabled: isOkButtonDisabled, onClick: dialogOk }
    },
    {
      widget: 'dxButton',
      location: 'after',
      toolbar: 'bottom',
      options: { icon: 'close', text: 'Abbrechen' },
      onClick: dialogCancel
    }
  ]">
  <dxo-animation [hide]="hideAnimation" [show]="showAnimation"></dxo-animation>
  <div style="height: 100%">
    <app-component-notification></app-component-notification>
    <div class="name-selector-container">
      <div class="selected-data">
        @switch (selectType) { @case ("Rollen") {
        <span class="caption">Ausgewählte Rollen:</span>
        } @case ("Beide") {
        <span class="caption">Ausgewählte Benutzer/Rollen:</span>
        } @default {
        <span class="caption">Ausgewählte Benutzer:</span>
        }}
        <span>
          {{
            nameSelectorGrid.selectedRowKeys && nameSelectorGrid.selectedRowKeys.length
              ? (nameSelectorGrid.instance.getSelectedRowsData() | mapArray : 'id' | mapAuthorName | async)
              : 'Es wurde niemand ausgewählt'
          }}
        </span>
      </div>
      <dx-data-grid
        #nameSelectorGrid
        id="nameSelectorGrid"
        class="nameSelectorGrid"
        height="0"
        [dataSource]="datasource"
        [selectedRowKeys]="selectedRecords"
        [hoverStateEnabled]="true"
        [allowColumnReordering]="true"
        [allowColumnResizing]="true"
        [showBorders]="true"
        [showColumnLines]="true"
        [showRowLines]="false"
        [columnAutoWidth]="true"
        (onRowClick)="onSelect($event)"
        (onSelectionChanged)="onSelectionChanged($event)"
        (onCellPrepared)="onCellPrepared($event)"
        (onContentReady)="onContentReady()">
        <!-- Grid Editiing settings -->
        <dxo-scrolling mode="standard"></dxo-scrolling>
        <dxo-filter-row [visible]="filteringEnabled"></dxo-filter-row>
        <dxo-header-filter [visible]="filteringEnabled"></dxo-header-filter>
        <dxo-sorting mode="single"></dxo-sorting>
        <dxo-selection [mode]="selectionMode" [showCheckBoxesMode]="checkBoxesMode"></dxo-selection>
        <dxo-load-panel [enabled]="false"> </dxo-load-panel>

        <!-- columns -->
        <dxi-column
          dataField="type"
          cellTemplate="typeTemp2"
          alignment="center"
          caption="Typ"
          [width]="80"
          [allowSearch]="false"
          [allowSorting]="true"
          [allowHeaderFiltering]="true"
          [allowFiltering]="false">
        </dxi-column>
        <div *dxTemplate="let type of 'typeTemp2'" [ngSwitch]="type.value">
          <i class="material-icons" *ngSwitchCase="'user'">person</i>
          <i class="material-icons" *ngSwitchCase="'role'">group</i>
          <span *ngSwitchDefault>{{ type }}</span>
        </div>

        <dxi-column
          caption="Name"
          cellTemplate="NameTemplate"
          [calculateCellValue]="calculateCellNameValue"
          [allowSearch]="true"
          [allowSorting]="true"
          [allowHeaderFiltering]="false"
          [calculateFilterExpression]="calculateFilterExpression"
          [allowFiltering]="true">
        </dxi-column>

        <div *dxTemplate="let cell of 'NameTemplate'">
          <div *ngIf="cell.data.type === 'user'">
            <img
              class="user-avatar-name"
              src="{{ cell.data.data.profile.picture.url }}"
              *ngIf="cell.data.data.profile && cell.data.data.profile.picture && cell.data.data.profile.picture.url"
              alt="" />
            <span [innerHtml]="cell.data.data.displayName"></span>
            <span class="user-job-title">({{ cell.data.data.name }})</span>
          </div>
          <div *ngIf="cell.data.type === 'role'">
            <span>{{ cell.data.data.name }}</span>
          </div>
        </div>

        <dxi-column
          [visible]="selectType !== 'Rollen'"
          [minWidth]="100"
          caption="Abteilung"
          dataField="data.profile.department"
          cellTemplate="AbteilungTemplate"
          [width]="'auto'"
          [allowSearch]="false"
          [allowSorting]="true"
          [allowHeaderFiltering]="true"
          [allowFiltering]="false">
        </dxi-column>

        <div *dxTemplate="let cell of 'AbteilungTemplate'">
          {{ cell.data.type === 'user' ? cell.data.data.profile.department : '' }}
        </div>
        <dxi-column
          [visible]="selectType !== 'Rollen'"
          [minWidth]="200"
          caption="Funktion"
          dataField="data.profile.jobTitle"
          cellTemplate="FunktionTemplate"
          [width]="'auto'"
          [allowSearch]="true"
          [allowSorting]="true"
          [allowHeaderFiltering]="false"
          [allowFiltering]="true">
        </dxi-column>

        <div *dxTemplate="let cell of 'FunktionTemplate'">
          {{ cell.data.type === 'user' ? cell.data.data.profile.jobTitle : '' }}
        </div>
      </dx-data-grid>
    </div>
  </div>
</dx-popup>
