import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { SharedataService } from '@services/sharedata.service';
import { environment } from '@env/environment';
import { ThemeService } from '@services/theme.service';
import { IPrintPageLayout, ISiamInfo, IStartPageSetting, TPageSetting } from '@interfaces/siam';
import { ConfigurationService } from '@services/configuration.service';
import { LoggerService } from '@services/logger.service';
import { SiamInfoService } from '@services/siam-info.service';
import { confirm } from 'devextreme/ui/dialog';
import { Observable, Subject, filter, map, switchMap, takeUntil, tap, zip } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { WINDOW } from './tokens/window.token';
import { siamConst } from '@interfaces/siamConst';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

interface IEvent {
  returnValue: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  debugWindow = false; // debugfenster anzeigen
  developmentMode: boolean;
  testingMode: boolean;
  colorMode: 'light' | 'dark';
  backgroundImg: string;

  private backgroundImgStart: string;
  private backgroundImgTheme: string;
  private destroyed$ = new Subject<void>();

  constructor(
    private logger: LoggerService,
    private sharedData: SharedataService,
    private themeService: ThemeService,
    private configurationService: ConfigurationService,
    private siamInfoService: SiamInfoService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    @Inject('SIAM_CLIENT_VERSION') private currentClientVersion: string,
    @Inject(WINDOW) private _window: Window
  ) {
    this.setTitle();
    _window.document.documentElement.lang = localStorage.getItem('language') || 'de';
    this.developmentMode = !environment.production;
    this.testingMode = environment.testing;
    this.getData();

    this.sharedData.getStartPageSetting$
      .pipe(
        switchMap(setting => {
          this.backgroundImgStart = setting?.background?.backgroundSrc || '/assets/img/background-pic/space_office.jpg';

          return this.sharedData.getBackground$;
        }),
        switchMap(url => {
          this.backgroundImgTheme = url || this.backgroundImgStart;
          return this.sharedData.getstartpageClass;
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe({
        next: isClass => {
          let bgImage: string;
          if (isClass) {
            bgImage = this.backgroundImgStart;
          } else {
            bgImage = this.backgroundImgTheme;
          }
          this.backgroundImg = bgImage;
          const allBg = this.themeService.getAllBackgrounds();
          const currentBg = allBg?.find(bg => bg.backgroundSrc === this.backgroundImg);
          if (currentBg) {
            this.colorMode = currentBg.colorMode;
          }
        }
      });

    this.sharedData.getTheme.pipe(takeUntil(this.destroyed$)).subscribe({
      next: theme => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.themeService.applyTheme(theme);
      }
    });
  }

  // Add Listener to prevent Browser to close Tab, or open another URL in AgiliCision-Tab!
  // most browsers do not allow to change the dialog-message displayed, so there is none!
  @HostListener('window:beforeunload', ['$event']) beforeUnloadHandler($event: IEvent): void {
    const canLeave = environment.production;
    this.logger.debug('User versucht, Seite zu verlassen, canLeave = ', canLeave);
    $event.returnValue = canLeave;
  }

  ngOnInit(): void {
    this.initCheckVersion();
    this.configurationService
      .getAccentColor()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(color => {
        this.sharedData.setAccentColor(color);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  initCheckVersion(): void {
    if (this.currentClientVersion !== '$SIAM_CLIENT_VERSION$') {
      this.checkVersion();
    }
  }

  checkVersion(): void {
    this.siamInfoService
      .webAppVersion()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (webAppVersion: ISiamInfo) => {
          const versionChanged = this.hasVersionChanged(webAppVersion.version, this.currentClientVersion);
          if (versionChanged) {
            const title = 'AgiliCision-Update';
            const message = `Der Anwendungserver wurde auf eine neue Programmversion (${webAppVersion.version}) aktualisiert. <br> Um Probleme im weiteren Betrieb zu vermeiden, laden Sie die Web-Seite bitte neu.<br> <br> Möchten Sie die Web-Seite jetzt neu laden?`;
            void confirm(message, title).then(result => {
              if (result) {
                this._window.location.reload();
                localStorage.clear();
              } else {
                setTimeout(() => {
                  this.checkVersion();
                }, 1000 * 60 * 30);
              }
            });
          }
        },
        error: () => {
          setTimeout(() => {
            this.checkVersion();
          }, 1000 * 60 * 30);
        }
      });
  }

  hasVersionChanged(currentVersion: string, lastVersion: string): boolean {
    if (!lastVersion || lastVersion === currentVersion) {
      return false;
    }
    return currentVersion !== lastVersion;
  }

  getData(): void {
    zip(this.getAppTitle(), this.getStartPageSetting(), this.logger.getLogLevel(), this.getPrintPageLayout())
      .pipe(takeUntil(this.destroyed$))
      .subscribe();
  }

  setTitle(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data['breadcrumbLabel']) {
              return child.snapshot.data['breadcrumbLabel'] as string;
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((data: string) => {
        if (data) {
          this.titleService.setTitle(`${data} |  ${siamConst.appTitle}`);
        }
      });
  }

  getModeClass(): string {
    if (this.testingMode) {
      return 'development-mode';
    }
    return this.colorMode ? `${this.colorMode}-mode` : 'light-mode';
  }

  private getStartPageSetting(): Observable<TPageSetting> {
    return this.configurationService.getJson(siamConst.startPageConfig, 'global').pipe(
      tap({
        next: data => {
          if (data) {
            this.sharedData.setStartPageSetting(data as IStartPageSetting);
          }
        }
      })
    );
  }

  private getAppTitle(): Observable<string> {
    return this.configurationService.getAppTitle().pipe(
      tap({
        next: title => {
          if (title) {
            this.sharedData.setAppTitle(title);
          } else {
            this.sharedData.setAppTitle('');
          }
        }
      })
    );
  }
  private getPrintPageLayout(): Observable<IPrintPageLayout> {
    return this.configurationService.getPrintPageLayout().pipe(
      tap({
        next: setting => {
          if (setting) {
            this.sharedData.setPrintPageLayout(setting);
          }
        }
      })
    );
  }
}
