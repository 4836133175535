import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET, Router } from '@angular/router';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';

interface IBreadcrumb {
  label: string;
  params: Params;
  url: string | string[];
  hasLink: boolean;
}

const breadcrumb = new Subject<IBreadcrumb[]>();
let subBreadcrumbs: IBreadcrumb[] = [];
const addons = new Subject<IBreadcrumb>();

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  breadcrumb: IBreadcrumb;
  breadcrumbs: IBreadcrumb[] = [];
  subBreadcrumbs: IBreadcrumb[] = [];

  #destroyable$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.breadcrumbs = [];
    breadcrumb
      .pipe(takeUntil(this.#destroyable$))
      .subscribe(breadcrumbs => {
        this.subBreadcrumbs = breadcrumbs;
      });
  }

  public static addBreadcrumb(breadcrumbLabel: string, url?: string[]): void {
    addons.next({
      label: breadcrumbLabel,
      url: url || '',
      hasLink: !!url,
      params: null
    });
  }

  public static addToSubBreadcrumb(breadcrumbLabel: string, url?: string[], position?: number): void {
    const item: IBreadcrumb = {
      label: breadcrumbLabel,
      url: url || '',
      hasLink: !!url,
      params: null
    };
    if (position > -1) {
      subBreadcrumbs.splice(position, 1, item);
      breadcrumb.next(subBreadcrumbs);
    } else {
      subBreadcrumbs.push(item);
      breadcrumb.next([item]);
    }
  }

  public static resetSubBreadcrumb(): void {
    return;
    subBreadcrumbs = [];
    breadcrumb.next(null);
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => event as NavigationEnd),
        switchMap(({ urlAfterRedirects }: NavigationEnd) => {
          this.subBreadcrumbs = null;
          this.breadcrumbs = null;
          if (urlAfterRedirects === '/application/home' || urlAfterRedirects === '/start') {
            return EMPTY;
          }
          const root: ActivatedRoute = this.activatedRoute.root;
          this.breadcrumbs = this.getBreadcrumbs(root);
          return addons;
        }),
        takeUntil(this.#destroyable$)
      )
      .subscribe(item => {
        this.breadcrumbs.push(item);
      });
  }

  ngOnDestroy(): void {
    this.#destroyable$.next();
    this.#destroyable$.complete();
  }

  private getBreadcrumbs(route: ActivatedRoute, url = '', iBreadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // iterate over each children
    for (const child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      const snapshot = child.snapshot;
      const label = snapshot.data.breadcrumbLabel as string;
      if (label) {
        // get the route's URL segment
        const childRouteURL: string = snapshot.url.map(segment => segment.path).join('/');

        if (!childRouteURL && iBreadcrumbs.length) {
          continue;
        }

        // append route URL to URL
        url += `${childRouteURL}/`;

        // add breadcrumb label
        iBreadcrumbs.push({
          label,
          url,
          params: snapshot.params,
          hasLink: !!snapshot.component
        });
      }
      // recursive
      this.getBreadcrumbs(child, url, iBreadcrumbs);
    }

    return iBreadcrumbs;
  }
}
